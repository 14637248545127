import React, { useEffect, useState } from 'react';
import NavBarAdmin from "../../Components/NavBarAdmin";
import AdminMenu from "../../Components/AdminMenu";
import ReportComponent from "../../Components/Report";

const Report = () => {
  return (
    <div className="page">
      <NavBarAdmin />
      <AdminMenu />
      <ReportComponent />      
    </div>
  );
};
export default Report;