import React, { useEffect, useState } from 'react';
// import {getReport} from '../redux/actions/siteAction'
// import {useDispatch, useSelector} from 'react-redux';
// import {types} from '../redux/actions/types';
import NavBar from "../Components/NavBar";
// import {siteList} from '../redux/actions/siteAdminAction'
import ReportComponent from "../Components/Report";
import { MdToday } from 'react-icons/md';

const Report = ({noHeaders, projectList, assignedSites}) => {

  useEffect(()=>{
    console.log("JJJJJx",projectList);
  },[])
  return (
    <div className={noHeaders ? null : "page"}>
       <div className="row m-0">
      <div className="project-page-head" style={{width: '90%', borderRight : '8px solid black', borderRadius: '20px', marginLeft: "5px", marginTop : '10px'}}>
            <div className="col-12 py-4">
              <div className="sub-head" style={{display : "flex", alignItems : 'center'}}>Daily Reports <MdToday color='#2b6cb0' style={{marginLeft : '7px'}}/></div>
              <h3 className="project-title fw-bold">Daily Reports</h3>
            </div>
          </div>
          </div>
      {!noHeaders && <NavBar isUserIcon={false} />}
      <ReportComponent  allProjects={projectList} newAssignedSites = {assignedSites}/>      
    </div>
  );
};
export default Report;