  import {put, call, takeLatest, all} from 'redux-saga/effects';
  import {types} from '../actions/types';
  import {doLoginAPI} from '../../store/apis';
  import { storeData,clearData,CONSTANTVALUE, getData } from '../../utils';
  import { apiGetCallWithPromise } from '../../store/axiosApicall';
  import { apiName } from '../../store/serverConfig';

  function* doLogin({payload, error}) {
    try {
      // yield put({type: types.SEND_REQUEST_USER_LOGIN});
      let loginObject = yield call(doLoginAPI,payload);
      // console.log('loginObject',loginObject);
      if(loginObject){
        storeData(CONSTANTVALUE.USEROBJECT,loginObject.user);
        storeData(CONSTANTVALUE.USERTOKEN,loginObject.token);
      }   
      if(loginObject){
        yield put({type: types.LOGIN_REQUEST_APPSETTINGS_SUCCESS, payload: loginObject});      
      } 
      else{
        yield put({type: types.LOGIN_REQUEST_APPSETTINGS_FAILURE, payload: {
          status: null,
          data: null,
          message: "Please enter valid login details!",
          token: null
        }});
      }
      // console.log('loginObject',loginObject);
      // console.log('userObject',userObject.status);
      // if(userObject.status=200)
      // yield put({type: types.LOGIN_REQUEST_APPSETTINGS_SUCCESS, payload: loginObject});
      // else
      //   yield put({type: types.SEND_REQUEST_USER_LOGIN_FAILURE, payload: userObject.message});
    } catch (err) {
      // console.log('appSettings',err);
      yield put({type: types.LOGIN_REQUEST_APPSETTINGS_FAILURE, payload: err});
      // console.log(err);
    }
  }

  const getSitesAPI = async () => {
    try {
      const userData = await getData(CONSTANTVALUE.USEROBJECT);
      const sites = await apiGetCallWithPromise(apiName.getAssignedSitesByCompanyIdAPI, `userId=${userData.id}`);
      return sites
    } catch (error) {
      console.log(error.message);
    }    
  }
  function* getSites() {
    try {
      // yield put({type: types.SEND_REQUEST_USER_LOGIN});
      const data = yield call(getSitesAPI); 
        yield put({type: types.ASSIGNED_SITES_REQUEST_SUCCESS, payload: data});      
      } catch (err) {
      // console.log('appSettings',err);
      yield put({type: types.LOGIN_REQUEST_APPSETTINGS_FAILURE, payload: err});
      // console.log(err);
    }

  }
  function* doClearData() {
    try {
      const loginObject = yield call(clearUserData,null);
      if(loginObject===undefined)
        yield put({type: types.CLEAR_LOGIN_REQUEST_APPSETTINGS_SUCCESS});
      else
        yield put({type: types.CLEAR_LOGIN_REQUEST_APPSETTINGS_FAILURE});
    } catch (err) {
      yield put({type: types.CLEAR_LOGIN_REQUEST_APPSETTINGS_FAILURE, payload: err});
    }
  }
  const clearUserData=async()=>{
    const userObject = await clearData();
    return userObject;
  }


  export default function* userSaga() {
    yield all([
      yield takeLatest(types.LOGIN_REQUEST_APPSETTINGS, doLogin),
      yield takeLatest(types.CLEAR_LOGIN_REQUEST_APPSETTINGS, doClearData),
      yield takeLatest(types.ASSIGNED_SITES_REQUEST, getSites)
      // LOAD_LOGGEDIN_USER(),
    ])
  }


  /*
    SAGA.JS
    import {takeEvery, call, put} from  'redux-saga/effects;
    import {GET_USER,  GET_USER_SUCCESS} from './actipns/'

    function usersFetch () {
      return fetch('api').then(resp)
    }
    function * workGetUsersFecth () {
      const users = yield call(usersFetch);
      yield put({type : GET_USER_SUCCESS}, users)
    }
    function * mySaga() {
      yield takeEvery(GET_USER, workGetUsersFetch);
    }
    
    export default mySaga;


    TYPES : 
    
    All three types 
    export const getUsersFetch() => ({
      type : GET_USERS_FETCH
    })


    reducer.js


    const myFirstReducer = (state = {}, action) => {
      switch (action.type) {
        case F
      }
    }
    */ 
