import React, { useEffect, useState } from 'react';
import { useNavigate,Link,useLocation } from "react-router-dom";
import {useDispatch, useSelector} from 'react-redux';
import PropTypes from "prop-types";
import {getCompany,deleteCompany} from '../../redux/actions/companyAction'
import {types} from '../../redux/actions/types';
import NavBarAdmin from "../../Components/NavBarAdmin";
import AdminMenu from "../../Components/AdminMenu";
import {getSiteExtraParameterAPI,updateSiteExtraParameterAPI} from '../../store/apis';
import {isEmpty, isLoggedInUser, CONSTANTVALUEEXTRAPARAM, sortArrayData, checkPermission} from '../../utils'
import { useToast } from '@chakra-ui/react';
// import { Alert } from 'react-bootstrap';
// import { Link } from "react-router-dom";
// import mainLogo from "../../assets/images/aqualogix-logo-b.png";
// import harGharJalLogo from "../../assets/images/hargharjal-logo-b.png";
// import ButtonWithSpinner from "../../Components/ButtonWithSpinner";

const Motorlist = ({moduleType}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [listSite, setListSite] = useState([]);
  const [listCompany, setListCompany] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(20);
  const [pumpList,setPumpList] = useState([]);
  const toast = useToast()


  /////////////////////////////////////////////////////////////////
  /////Parameters for getting permission value/////////////////////
  /////////////////////////////////////////////////////////////////
  const [isCreate, setIsCreate] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [isView, setIsView] = useState(false); 

  // const [selectedId, setSelectedId] = useState(0);
  // const [isLoading, setIsLoading] = useState(false);
  const companyReducer = useSelector(state => state.companyReducer);
  useEffect(()=>{
    // setIsLoading(true);
    console.log('LogIn-useEffect');    
    chkLogin(); 
    setPermission();    
  },[])
  // useEffect(()=>{
  //   console.log('Company-useEffect-location',location);    
  //   // console.log('ProjectDashboard-useEffect-location.state.projectId',location.state.projectId);    
  //   if(location.state){
  //     if(location.state.isReload){
  //       // window.location.reload(false);
  //       // chkLogin(); 
  //       // setPermission();
  //     }
  //   }
  // },[location])
  useEffect(()=>{
    // setIsLoading(true);
    // console.log('LogIn-useEffect');    
    if(isView)
      fetchExtraParams(moduleType);  
    // fetchExtraParams(CONSTANTVALUEEXTRAPARAM.PUMP);
      // fetchCompanyList(); 
  },[isView])
  useEffect(()=>{
    console.log('Company-companyReducer-useEffect',companyReducer);    
    if(companyReducer.action){
      if(companyReducer.action===types.GET_COMPANY_REQUEST_SUCCESS){
        console.log('Company-companyReducer-useEffect',companyReducer)      
        if(companyReducer.status==="success"){
          setListCompany(companyReducer.data.list);
          // storeData(CONSTANTVALUE.USEROBJECT,userReducer.data);
          // storeData(CONSTANTVALUE.USERTOKEN,userReducer.token);
          // navigate('/dashboard');
        }else {
          toast({
        title: companyReducer.message,
        status: 'warning',
        duration: 2000,
        isClosable: true,
        position: "top-right",
        positionOptions: {
          right: "0",
          top: "0",
        },
    });
        }
      }
      else if(companyReducer.action===types.DELETE_COMPANY_REQUEST_SUCCESS){
        console.log('Company-companyReducer-useEffect',companyReducer)      
        if(companyReducer.status==="success"){
          fetchCompanyList();
          // setListSite(companyReducer.data.list);
          // storeData(CONSTANTVALUE.USEROBJECT,userReducer.data);
          // storeData(CONSTANTVALUE.USERTOKEN,userReducer.token);
          // navigate('/dashboard');
        }else {
          toast({
        title: companyReducer.message,
        status: 'warning',
        duration: 2000,
        isClosable: true,
        position: "top-right",
        positionOptions: {
          right: "0",
          top: "0",
        },
    });
        }
      }
    }
  },[companyReducer.action])

  const chkLogin=async()=>{
    const isLoggedIn=await isLoggedInUser();
    // console.log("isLoggedInUser()",isLoggedIn);
    if(!isLoggedIn){
      navigate('/Admin');
    }
  }
  const setPermission=async()=>{
    // setIsCreate(await checkPermission('Company','create'));
    // setIsEdit(await checkPermission('Company','edit'));
    // setIsDelete(await checkPermission('Company','delete'));
    // setIsView(await checkPermission('Company','view'));
    setIsCreate(true);
    setIsEdit(true);
    setIsDelete(true);
    setIsView(true);
    // console.log('Company-User Permissions-isCreate='+isCreate+'-isEdit-'+isEdit+'-isDelete-'+isDelete+'-isView-'+isView);    
  }
  const fetchCompanyList=async()=>{
    // console.log("fetchSiteList");
    dispatch(getCompany("page="+page+"&limit="+limit+""));
    // const isLoggedIn=await isLoggedInUser();
    // console.log("isLoggedInUser()",isLoggedIn);
    // if(isLoggedIn){
    //   navigate('/Admin/SiteListing');
    // }
  } 
  const handleDeleteEvent=(item)=>{
    // Alert('Are you sure you want to remove?');
    // window.confirm("What Is Your Age?");
    // setSelectedId(item._id)
    // console.log("adsf!=item",item);
    if (window.confirm("Are you sure you want to delete?")) {
      // setTimeout(() => {
        deleteSelCompany(item._id);
      // }, 1000);
      console.log("You pressed OK!");
    } else {
      console.log("You pressed Cancel!");
    }
    // setSelectedId(item._id);
    // const request={id:item._id};
    // dispatch(deleteSite(item._id));
  }
  const handleEditEvent=(item)=>{
    // console.log('Company',item);
    navigate('/Admin/AddCompany',{state: {
      companyId: item._id,
      companyName: item.CompanyName,
    }});
    // navigate('/Admin/AddSite');
  }
  // const deleteItemClick=(item)=>{
  //   setNewsObject(item);
  //   console.log(item);
  // }
  const deleteSelCompany=async(selectedId)=>{   
    // console.log("deleteSelCompany-selectedId",selectedId); 
    dispatch(deleteCompany(selectedId));
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////// Function to make api call based on extra parameter ///////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  const fetchExtraParams=async(type)=>{
    console.log('type',type);
    if(type==CONSTANTVALUEEXTRAPARAM.PUMP){
      getSiteExtraParameterAPI('type='+CONSTANTVALUEEXTRAPARAM.PUMP).then((responseObject)=>{
        // console.log('pump-responseObject',responseObject);
        setPumpList(responseObject.data.list);
        // if(selPump=="Other" && pumpMake && pumpModel){
        //   setSelPump(responseObject.data.list[(responseObject.data.list.length-1)]._id);
        // }
      })
    }
    else if(type==CONSTANTVALUEEXTRAPARAM.MOTOR){
      getSiteExtraParameterAPI('type='+CONSTANTVALUEEXTRAPARAM.MOTOR).then((responseObject)=>{
        console.log('motor-responseObject',responseObject);
        setPumpList(responseObject.data.list);
        // if(selMotor=="Other" && motorType && motorMake){
        //   setSelMotor(responseObject.data.list[(responseObject.data.list.length-1)]._id);
        // }
      })
    }else if(type==CONSTANTVALUEEXTRAPARAM.FLOWMETER){
      getSiteExtraParameterAPI('type='+CONSTANTVALUEEXTRAPARAM.FLOWMETER).then((responseObject)=>{
        //console.log('motor-responseObject',responseObject);
        setPumpList(responseObject.data.list);
        // if(selFlowMeter=="Other" && flowMeterMake && flowMeterModel){
        //   setSelFlowMeter(responseObject.data.list[(responseObject.data.list.length-1)]._id);
        // }
      })
    }else if(type==CONSTANTVALUEEXTRAPARAM.PRESSUREMETER){
      getSiteExtraParameterAPI('type='+CONSTANTVALUEEXTRAPARAM.PRESSUREMETER).then((responseObject)=>{
        //console.log('motor-responseObject',responseObject);
        setPumpList(responseObject.data.list);
        // if(selPressureMeter=="Other" && pressureMeterMake && pressureMeterModel){
        //   setSelPressureMeter(responseObject.data.list[(responseObject.data.list.length-1)]._id);
        // }
      })
    }else if(type==CONSTANTVALUEEXTRAPARAM.MULTIFUNCTIONMETER){
      getSiteExtraParameterAPI('type='+CONSTANTVALUEEXTRAPARAM.MULTIFUNCTIONMETER).then((responseObject)=>{
        //console.log('motor-responseObject',responseObject);
        setPumpList(responseObject.data.list);
        // if(selMultiFuncMeter=="Other" && multiFuncMeterMake && multiFuncMeterModel){
        //   setSelMultiFuncMeter(responseObject.data.list[(responseObject.data.list.length-1)]._id);
        // }
      })
    }else if(type==CONSTANTVALUEEXTRAPARAM.PHSENSOR){
      getSiteExtraParameterAPI('type='+CONSTANTVALUEEXTRAPARAM.PHSENSOR).then((responseObject)=>{
        //console.log('motor-responseObject',responseObject);
        setPumpList(responseObject.data.list);
        // if(selPHSensorMeter=="Other" && pHSensorMake && pHSensorModel){
        //   setSelPHSensorMeter(responseObject.data.list[(responseObject.data.list.length-1)]._id);
        // }
      })
    }else if(type==CONSTANTVALUEEXTRAPARAM.LEVELSENSOR){
      getSiteExtraParameterAPI('type='+CONSTANTVALUEEXTRAPARAM.LEVELSENSOR).then((responseObject)=>{
        //console.log('motor-responseObject',responseObject);
        setPumpList(responseObject.data.list);
        // if(selLvlSensor=="Other" && lvlSensorMake && lvlSensorModel){
        //   setSelLvlSensor(responseObject.data.list[(responseObject.data.list.length-1)]._id);
        // }
      })
    }else if(type==CONSTANTVALUEEXTRAPARAM.TURBIDITYMONITOR){
      getSiteExtraParameterAPI('type='+CONSTANTVALUEEXTRAPARAM.TURBIDITYMONITOR).then((responseObject)=>{
        //console.log('motor-responseObject',responseObject);
        setPumpList(responseObject.data.list);
        // if(selTrbtMon=="Other" && trbtMonMake && trbtMonModel){
        //   setSelTrbtMon(responseObject.data.list[(responseObject.data.list.length-1)]._id);
        // }
      })
    }else if(type==CONSTANTVALUEEXTRAPARAM.CHLORINESENSOR){
      getSiteExtraParameterAPI('type='+CONSTANTVALUEEXTRAPARAM.CHLORINESENSOR).then((responseObject)=>{
        //console.log('motor-responseObject',responseObject);
        setPumpList(responseObject.data.list);
        // if(selClrnSensor=="Other" && clrnSensorMake && clrnSensorModel){
        //   setSelClrnSensor(responseObject.data.list[(responseObject.data.list.length-1)]._id);
        // }
      })
    }else if(type==CONSTANTVALUEEXTRAPARAM.ACTUATORSENSOR){
      getSiteExtraParameterAPI('type='+CONSTANTVALUEEXTRAPARAM.ACTUATORSENSOR).then((responseObject)=>{
        //console.log('motor-responseObject',responseObject);
        setPumpList(responseObject.data.list);
        // if(selActuator=="Other" && actuatorMake && actuatorModel){
        //   setSelActuator(responseObject.data.list[(responseObject.data.list.length-1)]._id);
        // }
      })
    }     
  }
  
  const bindData=()=>{
    return (
      <div class="table-responsive">
        <table class="table align-middle mb-0 bg-white table-hover">
          <thead class="thead-dark">
            <tr>
              <th scope="col">Make</th>
              <th scope="col">Type</th>
              {/* <th scope="col">Rating(KW)</th>
              <th scope="col">Head(m)</th>
              <th scope="col">Discharge(m3/hr)</th> */}
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            {pumpList.length>0?
            pumpList.map((item,index)=>(
              <tr key={index}>
                <td>{item.motorMake.toString()}</td>
                <td>{item.motorType.toString()}</td>
                {/* <td>{item.SupportUserAtDesire.toString()}</td>
                <td>{item.SupportAdmin.toString()}</td> */}
                <td><button type="button" className="btn btn-outline-primary" onClick={()=>handleEditEvent(item)}>{isEdit?"Update":"View"}</button>  {isDelete?<Link className="btn btn-outline-danger" onClick={()=>handleDeleteEvent(item)}>Delete</Link>:null}</td>
                {/* <button type="button" className="btn btn-outline-danger" onClick={()=>handleDeleteEvent(item)}>Delete</button> */}
              </tr>)): <tr><td>No Record Found</td></tr>
            }
          </tbody>
        </table>
      </div>
    )
  }
  const noViewPermission=()=>{
    return(<p class="text-center">You don't have permission to view the data. Please contact to administrator for more details</p>)
  }
  return (
    <div className="page">
      <NavBarAdmin />
      <AdminMenu selMenu="Company" />
      <div className="project-page-head">
        <div className="row m-0">
          <div className="col-12 py-4">
            <div className="sub-head">Motor</div>
            <h3 className="project-title fw-bold">Motor Listing</h3>
          </div>
        </div>
      </div>
      <section>      
        <div className="container">        
          <div className="row d-flex justify-content-center h-100 pt-5">
            {/* <div className="col-md-8 col-lg-8 col-xl-6"> */}
              <div className="card text-black" style={{ borderRadius: "10px" }}>
                <div className="card-body">
                  {isView?bindData():noViewPermission()}                  
                  <div className="d-flex justify-content-center mx-2 mb-2 mb-lg-2">
                    {isCreate?<Link className="btn btn-primary mt-3" to={"/Admin/AddCompany"}>Add Motor</Link>:null}
                  </div>
                </div>
              </div>              
            {/* </div> */}
          </div>
        </div>
      </section>
    </div>
  );
};
Motorlist.propTypes = {
  moduleType: PropTypes.string,
};
Motorlist.defaultProp = {
  moduleType: CONSTANTVALUEEXTRAPARAM.MOTOR,
};
export default Motorlist;
