import React, { useEffect, useState } from 'react';
// import { Link } from "react-router-dom";
// import PropTypes from "prop-types";
import { useNavigate,useLocation, Link } from "react-router-dom";
import {useDispatch, useSelector} from 'react-redux';
import Button from 'react-bootstrap/Button';
import NavBarAdmin from "../../../Components/NavBarAdmin";
import AdminMenu from "../../../Components/AdminMenu";
import ButtonWithSpinner from "../../../Components/ButtonWithSpinner";
import {isEmpty, isLoggedInUser, CONSTANTVALUEEXTRAPARAM, sortArrayData, checkPermission} from '../../../utils'
import {addCountryAPI,updateCountryAPI} from '../../../store/apis';
// import {addSite,updateSite,siteExtraParameter,updateSiteExtraParameter,clearSiteReducer} from '../../../redux/actions/siteAdminAction'
import { useToast } from '@chakra-ui/react';

const AddCountry = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const siteAdminReducer = useSelector(state => state.siteAdminReducer);
  const [countryName, setCountryName] = useState('');
  const [countryCode, setCountryCode] = useState('');  
  const [ISOCode, setISOCode] = useState('');
  const [localCountryId, setLocalCountryId] = useState(null); 
  const [isEdit, setIsEdit] = useState(false);
  const [loading, setLoading] = useState(false);  
  const location = useLocation();
  const toast = useToast()

  useEffect(()=>{
    chkLogin(); 
    setPermission();
    if(location){
      if(location.state){
        if(location.state.item){
          console.log('Country-item',location.state.item);
          setCountryName(location.state.item.name);
          setCountryCode(location.state.item.code);
          setISOCode(location.state.item.ISOCode);
          setLocalCountryId(location.state.item._id);
        }
      }
    }    
  },[])
  const chkLogin=async()=>{
    const isLoggedIn=await isLoggedInUser();
    // console.log("isLoggedInUser()",isLoggedIn);
    if(!isLoggedIn){
      navigate('/Admin');
    }
  }  
  const setPermission=async()=>{
    setIsEdit(await checkPermission('Country','edit'));
  }
//   useEffect(()=>{
//     console.log('AddSite-userReducer-useEffect',siteAdminReducer);    
//     if(siteAdminReducer.action){
//       if(siteAdminReducer.action===types.SITE_EXTRA_PARAMETER_SUCCESS){
//         if(siteAdminReducer.status==="success"){          
//           //navigate('/Admin/Pump');
//           dispatch(clearSiteReducer()); 
//           navigate('/Admin/Pump', {
//             moduleType: CONSTANTVALUEEXTRAPARAM.PUMP
//           });
//         }else {
//           toast({
//         title: siteAdminReducer.message,
//         status: 'warning',
//         duration: 2000,
//         isClosable: true,
//         position: "top-right",
//         positionOptions: {
//           right: "0",
//           top: "0",
//         },
//     });
//         }
//       }
//       if(siteAdminReducer.action===types.UPDATE_SITE_EXTRA_PARAMETER_SUCCESS){
//         if(siteAdminReducer.status==="success"){          
//           //navigate('/Admin/Pump');          
//           dispatch(clearSiteReducer());   
//           navigate('/Admin/Pump', {
//             moduleType: CONSTANTVALUEEXTRAPARAM.PUMP
//           });
//         }else {
//           toast({
//         title: siteAdminReducer.message,
//         status: 'warning',
//         duration: 2000,
//         isClosable: true,
//         position: "top-right",
//         positionOptions: {
//           right: "0",
//           top: "0",
//         },
//     });
//         }
//       }
//     }
//   },[siteAdminReducer.action])
  const handleSiteInput=(e)=>{    
    //////////////// Country input validation ////////////////////////////////////
    if(e.target.name==="txtName"){
      setCountryName(e.target.value);
    }
    if(e.target.name==="txtCode"){
      setCountryCode(e.target.value);
    }
    if(e.target.name==="txtISOcode"){
      setISOCode(e.target.value);
    }
    ////////////////////////////////////////////////////////////////
  }
  const handleSubmit=()=>{
    // console.log('localPumpId');
    if(isEmpty(countryName)){
      toast({
        title: 'please enter country name',
        status: 'warning',
        duration: 2000,
        isClosable: true,
        position: "top-right",
        positionOptions: {
          right: "0",
          top: "0",
        },
    })
      return;
    }else if(isEmpty(countryCode)){
      toast({
        title: 'please enter country code',
        status: 'warning',
        duration: 2000,
        isClosable: true,
        position: "top-right",
        positionOptions: {
          right: "0",
          top: "0",
        },
    })
      return;
    }else if(isEmpty(ISOCode)){
      toast({
        title: 'please enter ISO Code',
        status: 'warning',
        duration: 2000,
        isClosable: true,
        position: "top-right",
        positionOptions: {
          right: "0",
          top: "0",
        },
    })
      return;
    }
    else{
      if(localCountryId){
        const request={
          "id": localCountryId,
          "name":countryName,
          "code":countryCode,
          "ISOCode":ISOCode,
        };
        setLoading(true);
        updateCountryAPI(request).then((response)=>{
          setLoading(false);
            if(response)
                navigate('/Admin/Country');
            // setSelCountry(response.data[0]._id);
        });     
      }
      else{
        const request={
            "name":countryName,
            "code":countryCode,
            "ISOCode":ISOCode,
        };
        setLoading(true);
        addCountryAPI(request).then((response)=>{
          setLoading(false);
            if(response)
                navigate('/Admin/Country');
            // setSelCountry(response.data[0]._id);
        });
      }
      // handlePumpShow();
    }    
  }  
    return (
      <div className="page">
      <NavBarAdmin />
      <AdminMenu selMenu="AddCountry" />
      {/* <Header moduleType={moduleType} /> */}
      <section>     
        <div className="container"> 
          <div className="row d-flex justify-content-center pt-4 h-100">
            <div className="card text-black" style={{ borderRadius: "25px" }}>
              <div className="card-body pt-5">
                <div className="row justify-content-center">                  
                  <div className="col-12 order-2 order-lg-1"> 
                    <p className="h1 fw-bold mb-4 ">{localCountryId?"Update Country":"Add Country"}</p>
                    {/* <form className="mx-1 mx-md-2"> */}
                      <div className="d-flex flex-row mb-3">
                        <div className="form-outline flex-fill mb-0">
                          <label for="make" className="col-sm-4 col-form-label">Name</label>
                          <input type="text" name="txtName" id="txtName" value={countryName} onChange={(event) => handleSiteInput(event)} className="form-control" />
                        </div>
                      </div>
                      <div className="d-flex flex-row mb-3">
                        <div className="form-outline flex-fill mb-0">
                          <label for="pumpModel" className="col-sm-4 col-form-label">Code</label>
                          <input type="text" name="txtCode" id="txtCode" value={countryCode} onChange={(event) => handleSiteInput(event)} className="form-control" />
                        </div>
                      </div>
                      <div className="d-flex flex-row mb-3">
                        <div className="form-outline flex-fill mb-0">
                          <label for="rating" className="col-sm-4 col-form-label">ISOCode</label>
                          <input type="text" name="txtISOcode" id="txtISOcode" value={ISOCode} onChange={(event) => handleSiteInput(event)} className="form-control" />
                        </div>
                      </div>                      
                      <div className="d-flex justify-content-center mb-3">
                        {isEdit?<ButtonWithSpinner onClick={()=>handleSubmit()} isLoading={loading?true:false} label={'Save'} />:null}
                      </div>  
                    {/* </form>*/}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    )
};
export default AddCountry;
