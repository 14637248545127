import React, { useEffect, useState } from 'react';
// import { Link } from "react-router-dom";
// import PropTypes from "prop-types";
import { useNavigate,useLocation, Link } from "react-router-dom";
import {useDispatch, useSelector} from 'react-redux';
import Button from 'react-bootstrap/Button';
import NavBarAdmin from "../../../Components/NavBarAdmin";
import AdminMenu from "../../../Components/AdminMenu";
import {types} from '../../../redux/actions/types';
import {isEmpty, isLoggedInUser, CONSTANTVALUEEXTRAPARAM, sortArrayData, checkPermission} from '../../../utils'
import {addSite,updateSite,siteExtraParameter,updateSiteExtraParameter,clearSiteReducer} from '../../../redux/actions/siteAdminAction'
import { useToast } from '@chakra-ui/react';

const AddPump = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const siteAdminReducer = useSelector(state => state.siteAdminReducer);
  const [pumpMake, setPumpMake] = useState('');
  const [pumpModel, setPumpModel] = useState('');  
  const [pumpRating, setPumpRating] = useState('');
  const [pumpHead, setPumpHead] = useState('');
  const [pumpDischarge, setPumpDischarge] = useState('');
  const [pumpSerialNo, setPumpSerialNo] = useState('');  
  const [localPumpId, setLocalPumpId] = useState(null); 
  const [currentField, setCurrentField] = useState('sitedetails');
  const location = useLocation();
  const toast = useToast()

  useEffect(()=>{
    if(location){
      if(location.state){
        if(location.state.item){
          console.log('Pump-item',location.state.item);
          setPumpMake(location.state.item.make);
          setPumpModel(location.state.item.model);
          setPumpHead(location.state.item.headM);
          setPumpRating(location.state.item.ratingKW);
          setPumpDischarge(location.state.item.dischargeM3);
          setLocalPumpId(location.state.item._id)
        }
      }
    }    
  },[])
  useEffect(()=>{
    console.log('AddSite-userReducer-useEffect',siteAdminReducer);    
    if(siteAdminReducer.action){
      if(siteAdminReducer.action===types.SITE_EXTRA_PARAMETER_SUCCESS){
        if(siteAdminReducer.status==="success"){          
          //navigate('/Admin/Pump');
          dispatch(clearSiteReducer()); 
          navigate('/Admin/Pump', {
            moduleType: CONSTANTVALUEEXTRAPARAM.PUMP
          });
        }else {
          toast({
        title: siteAdminReducer.message,
        status: 'warning',
        duration: 2000,
        isClosable: true,
        position: "top-right",
        positionOptions: {
          right: "0",
          top: "0",
        },
    });
        }
      }
      if(siteAdminReducer.action===types.UPDATE_SITE_EXTRA_PARAMETER_SUCCESS){
        if(siteAdminReducer.status==="success"){          
          //navigate('/Admin/Pump');          
          dispatch(clearSiteReducer());   
          navigate('/Admin/Pump', {
            moduleType: CONSTANTVALUEEXTRAPARAM.PUMP
          });
        }else {
          toast({
        title: siteAdminReducer.message,
        status: 'warning',
        duration: 2000,
        isClosable: true,
        position: "top-right",
        positionOptions: {
          right: "0",
          top: "0",
        },
    });
        }
      }
    }
  },[siteAdminReducer.action])
  const handleSiteInput=(e)=>{    
    //////////////// Pump Popup ////////////////////////////////////
    if(e.target.name==="txtPumpMake"){
      setPumpMake(e.target.value);
    }
    if(e.target.name==="txtPumpModel"){
      setPumpModel(e.target.value);
    }
    if(e.target.name==="txtPumpRating"){
      setPumpRating(e.target.value);
    }
    if(e.target.name==="txtPumpHead"){
      setPumpHead(e.target.value);
    }
    if(e.target.name==="txtPumpDischarge"){
      setPumpDischarge(e.target.value);
    }
    if(e.target.name==="txtPumpSerialNo"){
      setPumpSerialNo(e.target.value);
    }    
    ////////////////////////////////////////////////////////////////
  }
  const handlePumpSubmit=()=>{
    console.log('localPumpId');
    if(isEmpty(pumpMake)){
      toast({
        title: 'please enter pump make',
        status: 'warning',
        duration: 2000,
        isClosable: true,
        position: "top-right",
        positionOptions: {
          right: "0",
          top: "0",
        },
    })
      return;
    }else if(isEmpty(pumpModel)){
      toast({
        title: 'please enter pump model',
        status: 'warning',
        duration: 2000,
        isClosable: true,
        position: "top-right",
        positionOptions: {
          right: "0",
          top: "0",
        },
    })
      return;
    }else if(isEmpty(pumpRating)){
      toast({
        title: 'please enter pump rating',
        status: 'warning',
        duration: 2000,
        isClosable: true,
        position: "top-right",
        positionOptions: {
          right: "0",
          top: "0",
        },
    })
      return;
    }else if(isEmpty(pumpHead)){
      toast({
        title: 'please enter pump head',
        status: 'warning',
        duration: 2000,
        isClosable: true,
        position: "top-right",
        positionOptions: {
          right: "0",
          top: "0",
        },
    })
      return;
    }else if(isEmpty(pumpDischarge)){
      toast({
        title: 'please enter pump discharge',
        status: 'warning',
        duration: 2000,
        isClosable: true,
        position: "top-right",
        positionOptions: {
          right: "0",
          top: "0",
        },
    })
      return;
    }
    else{
      if(localPumpId){
        const request={
          "id": localPumpId,
          "type": CONSTANTVALUEEXTRAPARAM.PUMP,
          "make":pumpMake,
          "model":pumpModel,
          "ratingKW":pumpRating,
          "headM":pumpHead,
          "dischargeM3":pumpDischarge,
        };
        setCurrentField(CONSTANTVALUEEXTRAPARAM.PUMP);
        dispatch(updateSiteExtraParameter(request));        
      }
      else{
        const request={
          "type": CONSTANTVALUEEXTRAPARAM.PUMP,
          "make":pumpMake,
          "model":pumpModel,
          "ratingKW":pumpRating,
          "headM":pumpHead,
          "dischargeM3":pumpDischarge,
        };
        setCurrentField(CONSTANTVALUEEXTRAPARAM.PUMP);
        dispatch(siteExtraParameter(request));
      }
      // handlePumpShow();
    }    
  }  
    return (
      <div className="page">
      <NavBarAdmin />
      <AdminMenu selMenu="AddPump" />
      {/* <Header moduleType={moduleType} /> */}
      <section>     
        <div className="container"> 
          <div className="row d-flex justify-content-center pt-4 h-100">
            <div className="card text-black" style={{ borderRadius: "25px" }}>
              <div className="card-body pt-5">
                <div className="row justify-content-center">                  
                  <div className="col-12 order-2 order-lg-1"> 
                    <p className="h1 fw-bold mb-4 ">{localPumpId?"Update Pump":"Add Pump"}</p>
                    {/* <form className="mx-1 mx-md-2"> */}
                      <div className="d-flex flex-row mb-3">
                        <div className="form-outline flex-fill mb-0">
                          <label for="make" className="col-sm-4 col-form-label">Make</label>
                          <input type="text" name="txtPumpMake" id="txtPumpMake" value={pumpMake} onChange={(event) => handleSiteInput(event)} className="form-control" />
                        </div>
                      </div>
                      <div className="d-flex flex-row mb-3">
                        <div className="form-outline flex-fill mb-0">
                          <label for="pumpModel" className="col-sm-4 col-form-label">Model</label>
                          <input type="text" name="txtPumpModel" id="txtPumpModel" value={pumpModel} onChange={(event) => handleSiteInput(event)} className="form-control" />
                        </div>
                      </div>
                      <div className="d-flex flex-row mb-3">
                        <div className="form-outline flex-fill mb-0">
                          <label for="rating" className="col-sm-4 col-form-label">Rating(KW)</label>
                          <input type="text" name="txtPumpRating" id="txtPumpRating" value={pumpRating} onChange={(event) => handleSiteInput(event)} className="form-control" />
                        </div>
                      </div>
                      <div className="d-flex flex-row mb-3">
                        <div className="form-outline flex-fill mb-0">
                          <label for="head" className="col-sm-4 col-form-label">Head(m)</label>
                          <input type="text" name="txtPumpHead" id="txtPumpHead" value={pumpHead} onChange={(event) => handleSiteInput(event)} className="form-control" />
                        </div>
                      </div>
                      <div className="d-flex flex-row mb-3">
                        <div className="form-outline flex-fill mb-0">
                          <label for="discharge" className="col-sm-4 col-form-label">Discharge(m3/hr)</label>
                          <input type="text" name="txtPumpDischarge" id="txtPumpDischarge" value={pumpDischarge} onChange={(event) => handleSiteInput(event)} className="form-control" />
                        </div>
                      </div>
                      <div className="d-flex justify-content-center mb-3">
                        <Button variant="primary" onClick={()=>handlePumpSubmit()}>Submit</Button>
                      </div>  
                    {/* </form>                      */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    )
};
export default AddPump;
