import React, { useEffect, useState } from 'react';
import { getHourlyReport } from '../redux/actions/siteAction'
import { useDispatch, useSelector } from 'react-redux';
import { types } from '../redux/actions/types';
import NavBar from "../Components/NavBar";
import { siteList } from '../redux/actions/siteAdminAction'
import moment from "moment";

import { Box, Select, Flex, background } from '@chakra-ui/react';
import ButtonWithSpinnerSmall from "../Components/ButtonWithSpinnerSmall";
import { Button, useToast } from '@chakra-ui/react';
import { CONSTANTVALUE, getData } from '../utils';
import { apiGetCallWithPromise } from '../store/axiosApicall';
import { apiName } from '../store/serverConfig';
import { useRef } from 'react';
import * as XLSX from 'xlsx';
import { AiFillFileExcel, AiFillPrinter } from 'react-icons/ai';
import { reportHourWiseAPI } from '../store/apis';


const hourArray = ["12:00 AM", "01:00 AM", "02:00 AM", "03:00 AM", "04:00 AM", "05:00 AM", "06:00 AM", "07:00 AM", "08:00 AM", "09:00 AM", "10:00 AM", "11:00 AM", "12:00 PM", "01:00 PM", "02:00 PM", "03:00 PM", "04:00 PM", "05:00 PM", "06:00 PM", "07:00 PM", "08:00 PM", "09:00 PM", "10:00 PM", "11:00 PM"]
const HourlyReport = ({ projects, newAssignedSites }) => {

  const dispatch = useDispatch();
  const [mqttData, setMqttData] = useState([]);
  const [imeino, setImeino] = useState("");
  const tableRef = useRef(null);
  // const [projectsList, setProjects] = useState(projects);
  const [selectedProject, setSelectedProject] = useState('');
  useEffect(() => {
    const p = localStorage.getItem('selectedProject');

    setTimeout(() => {
      if (p) {
        setSelectedProject(p)
      }
    }, 2000)
  }, [])
  const [selectedSites, setSelectedSites] = useState(newAssignedSites);
  const [site, setSite] = useState('')

  const [dateReport, setDateReport] = useState();
  const [loading, setIsLoading] = useState(false);
  // const toast = useToast()


  useEffect(() => {
    // const projectName = localStorage.getItem('selectedProjectName');
    console.log("CHANGING SITESSSSSSS", projects, selectedProject)
    if (selectedProject !== 'ALL') {
      const selectedProjectData = projects?.find((item) => item.project._id === selectedProject);
      console.log("CHANGING SITESSSSSSS 2", selectedProjectData, projects)
      setSelectedSites(selectedProjectData ? selectedProjectData.sites : []);
    } else {
      setSelectedSites([]);
    }
  }, [selectedProject]);
  const exportToExcel = () => {
    if (tableRef.current) {

      const tableElement = tableRef.current;


      const worksheet = XLSX.utils.table_to_sheet(tableElement);


      const workbook = XLSX.utils.book_new();


      XLSX.utils.book_append_sheet(workbook, worksheet, 'MQTT Data');


      const fileName = `Hourly_Report_${moment(dateReport).format("DD-MM-YYYY")}.xlsx`;


      XLSX.writeFile(workbook, fileName);
    }
  };

  const fontFamily = 'Work Sans'
  const handlePrint = () => {
    if (tableRef.current) {
      const printWindow = window.open("Hourly_Report", '_blank');
      printWindow.document.open();
      printWindow.document.write(`
        <html>
          <head>
            <title>Hourly_Report</title>
            <h4>${moment(dateReport).format("DD-MM-YYYY")}</h4>
            <style>
              /* Add your custom styles here */
              table {
                border-collapse: collapse;
                width: 100%;
              }
              th, td {
                border: 1px solid #ccc;
                padding: 8px;
                text-align: left;
              }
              th {
                background-color: #f2f2f2;
              }
              /* Align numbers to the right */
              td.align-right {
                text-align: right;
              }
            </style>
          </head>
          <body>
            <table style="font-family: ${fontFamily}">
              ${tableRef.current.innerHTML}
            </table>
            <script>
              
              document.querySelectorAll('td').forEach(cell => {
                if (!isNaN(cell.innerText) && cell.innerText.trim() !== '') {
                  cell.classList.add('align-right');
                }
              });
            </script>
          </body>
        </html>
      `);
      printWindow.document.close();
      // Set custom file name for printing
      printWindow.print();
      printWindow.close();
    }
  };




  // const fetchSiteList=async()=>{
  //   console.log("fetchSiteList");
  //   dispatch(siteList("page=1&limit=10000"));
  // }

  const fetchReportData = () => {
    if (imeino) {
      const todayDate = new Date();
      const request = {
        "IMEI": imeino,
        "type": "perameter",
        "searchDate": dateReport ? moment(dateReport).format("DD-MM-YYYY") : moment(todayDate).format("DD-MM-YYYY")
      }
      dispatch(getHourlyReport(request));
    }
  }
  const handleSearch = async () => {

    setIsLoading(true);
    const currData = await reportHourWiseAPI({ IMEI: imeino, searchDate: dateReport, type: "perameter" })
    if (currData && currData.data) setMqttData(currData.data);
    setIsLoading(false);

    console.log("XXXXX", currData)
  }
  const handleUserInput = (e) => {
    if (e.target.name === "reportDate") {
      setDateReport(e.target.value);
    }
  }
  const handleChangeEvent = (e) => {
    console.log('AddUser-handleChangeEvent-name-', e.target.name + "-value-" + e.target.value);
    console.log("PIKAAAA", e.target.value)
    if (e.target.name === "selSite") {
      setImeino(e.target.value);
    }
  }
  const handleSiteChange = (event) => {

    const selectedSiteName = event.target.options[event.target.selectedIndex].text;
    const selectedSiteIMEI = event.target.options[event.target.selectedIndex].getAttribute('imei');

    // console.log('Selected Site Name:', selectedSiteName);
    setSite(selectedSiteName);
    // console.log('Selected Site IMEI:', selectedSiteIMEI);
    setImeino(selectedSiteIMEI);
    console.log('Selected Site IMEI:', imeino);
  };

  const getSiteaName = () => {
    const d = newAssignedSites.filter((item) => item.IMEI == imeino);
    return d[0].pumpName
  }
  const bindSites = () => {
    console.log("Point", newAssignedSites)
    if (newAssignedSites) {
      // console.log('bindProjects1-listProjects',listProjects);
      if (newAssignedSites.length > 0) {
        // console.log('bindProjects2-listProjects',listProjects);
        return (newAssignedSites.map((item, index) => (
          <>
            <option key={index} value={item.IMEI}> {item.pumpName.toString() + "   (IMEI " + item.IMEI + ")"}</option>
          </>
        )))
      }
    }
  }
  return (
    <div className="panel_contentmqttdata  m-3">
      <div className="row mt-3 mb-3">
        <div className="col-12 col-md-12">
          <div className="row">
            <div className="col">
              <div className="d-flex flex-row">
                <div className="form-outline flex-fill mb-0">
                  {/* <label className="form-label" for="form3Example3c">Project</label> */}

                  {/* <option value="">Select Site</option>
                    {bindSites()} */}

                  <Flex >
                    <Select
                      id="pageSizeSelect"
                      value={selectedProject}
                      variant={'filled'}
                      onChange={(e) => setSelectedProject(e.target.value)}
                      color="black"
                    >
                      <option value={'ALL'}>Select Project</option>
                      {projects?.sort((a, b) => a.project.ProjectName.localeCompare(b.project.ProjectName)).map((item) => (
                        <option key={item.project._id} value={item.project._id}>
                          {item.project.ProjectName}
                        </option>
                      ))}
                    </Select>
                    {selectedProject !== 'ALL' && (
                      <Select
                        variant={'filled'}
                        ml={2}
                        onChange={(e) => handleSiteChange(e)}
                        color="black"
                      >
                        <option value={'ALL'}>Select Site</option>
                        {selectedSites?.sort((a, b) => a.pumpName.localeCompare(b.pumpName)).map((site) => (
                          <option key={site._id} value={site._id} imei={site.IMEI}>
                            {site.pumpName}
                          </option>
                        ))}
                      </Select>
                    )}
                  </Flex>

                  {/* Note: Data will refresh in 5 sec time interval. */}
                </div>
              </div>
            </div>
            <div className="col"><input type={"date"} name="reportDate" id="reportDate" value={dateReport} onChange={(event) => handleUserInput(event)} className="form-control" /></div>
            <div className="col"><Button onClick={() => handleSearch()} colorScheme='blue' isLoading={loading}>Search</Button></div>
            {/* <div className="col"><ButtonWithSpinner onClick={()=>handleStopRequest()} isLoading={siteReducer.loading?true:false} label={'Stop Request'} /></div> */}
          </div>
        </div>
      </div>
      <table className="table table-striped" ref={tableRef}>
        <thead>
          <tr>
            <th scope="col">IMEI</th>
            <th scope="col">Site Name</th>
            <th scope="col">Date</th>
            <th scope="col">Hour</th>
            <th scope="col">Flow Meter Opening Reading (L)</th>
            <th scope="col">Flow Meter Closing Reading (L)</th>
            <th scope="col">Total Production (L)</th>
            {/* <th scope="col">Energy Meter Opening Reading (KWh)</th>
            <th scope="col">Energy Meter Closing Reading (KWh)</th>
            <th scope="col">Total Power Consumption (KWh)</th> */}
          </tr>
        </thead>
        <tbody>
          {mqttData.length > 0 ?
            mqttData.map((item, index) => {
              // if(item.IMEI=="860987058180214")
              // {
              return (
                <tr key={index}>
                  <td>{item.IMEI ? item.IMEI.toString() : (imeino ? imeino.toString() : "")}</td>
                  <td>{getSiteaName()}</td>
                  <td>{moment(dateReport).format("DD-MM-YYYY")}</td>
                  <td>{(item.hour + "").toUpperCase()}</td>
                  <td>{item.openingFlow ? item.openingFlow : 0}</td>
                  <td>{item.cloginFlow ? item.cloginFlow : 0}</td>
                  <td>{item.totalFlow ? item.totalFlow : 0}</td>
                  {/* <td>{item.openingkWh?item.openingkWh.toFixed(2):0}</td>
                  <td>{item.clogingkWh?item.clogingkWh.toFixed(2):0}</td>
                  <td>{item.powerGenerationkWh?item.powerGenerationkWh.toFixed(2):0}</td> */}
                </tr>)
            }) : <tr><td colSpan={22}>No Record Found</td></tr>
          }
        </tbody>
      </table>
      <div style={{ display: 'flex', gap: '10px', justifyContent: 'flex-end' }}>
        <Button mt='15px' colorScheme='blue' style={{ display: 'flex', gap: '5px' }} onClick={handlePrint}>
          Print
          <AiFillPrinter />
        </Button>
        <Button
          mt='15px'
          colorScheme='blue'
          style={{ display: 'flex', gap: '5px' }}
          onClick={exportToExcel}
        >
          Export to Excel
          <AiFillFileExcel />
        </Button>
      </div>
    </div>
  );
};
export default HourlyReport;
