import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Table, Button, Modal, Input, Select } from 'antd';
import { useToast } from '@chakra-ui/react';
import validatePayload from 'z-validify';

const { Option } = Select;

const EmailMaster = ({ projects }) => {
    const toast = useToast();
    const [mails, setMails] = useState([]);
    const [visible, setVisible] = useState(false);
    const [email, setEmail] = useState('');
    const [selectedProjects, setSelectedProjects] = useState([]);
    const [selectedFrequency, setSelectedFrequency] = useState([]); // Multi-select frequency state as array
    const [editingMail, setEditingMail] = useState(null);

    useEffect(() => {
        fetchMails();
    }, []);

    const fetchMails = async () => {
        try {
            const response = await axios.get('http://mqtt.aqualogix.in:85/master/getMails');
            console.log("Fetched mails:", response.data);
            setMails(response.data.allMails); // Adjust based on actual API response structure
        } catch (error) {
            console.log(error.message);
        }
    };

    const handleAddEmail = () => {
        setVisible(true);
        fetchMails(); // Refresh list when adding a new email
    };

    const handleOk = async () => {
        try {
            const { isValid, message } = validatePayload({ email: email.trim(), selectedProjects, frequency: selectedFrequency });
            if (!isValid) {
                toast({
                    title: 'Invalid Fields',
                    description: message,
                    position: 'top-right',
                    status: 'error',
                    duration: 3000
                });
                return;
            }

            if (editingMail) {
                // Update existing email
                await axios.post(`http://mqtt.aqualogix.in:85/master/updateMail`, { 
                    email: email.trim(), 
                    projectArray: selectedProjects, 
                    frequency: selectedFrequency, // Multi-select frequency
                    id: editingMail._id 
                });
                fetchMails();
                toast({
                    title: 'Email Updated Successfully',
                    position: 'top-right',
                    status: 'success',
                    duration: 3000
                });
            } else {
                // Add new email
                await axios.post('http://mqtt.aqualogix.in:85/master/addMail', { 
                    email: email.trim(), 
                    projectArray: selectedProjects, 
                    frequency: selectedFrequency // Multi-select frequency
                });
                toast({
                    title: 'Email Saved Successfully',
                    position: 'top-right',
                    status: 'success',
                    duration: 3000
                });
            }

            fetchMails(); // Refresh the list of emails
        } catch (error) {
            console.log(error.message);
        } finally {
            setVisible(false);
            setEmail('');
            setSelectedProjects([]);
            setSelectedFrequency([]); // Reset frequency
            setEditingMail(null);
        }
    };

    const handleCancel = () => {
        setVisible(false);
        setEditingMail(null);
    };

    const handleEdit = (record) => {
        setEditingMail(record);
        setEmail(record.email);
        setSelectedProjects(record.projectArray);
        setSelectedFrequency(record.frequency || []); // Set frequency for editing as array
        setVisible(true);
        console.log('Editing Mail ID:', record._id); // Log the ID of the mail being edited
    };

    const handleProjectsChange = (value) => {
        console.log('Selected Projects:', value); // Log the selected projects
        if (editingMail) {
            console.log('Email:', editingMail.email); // Log the email of the currently edited row
        }
        setSelectedProjects(value);
    };

    const handleFrequencyChange = (value) => {
        setSelectedFrequency(value); // Update frequency state
    };

    const columns = [
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Projects',
            dataIndex: 'projectArray',
            key: 'projectArray',
            render: (text, record) => (
                <Select
                    mode="multiple"
                    value={record.projectArray}
                    onChange={handleProjectsChange}
                    style={{ width: '100%' }}
                >
                    {projects.map(project => (
                        <Option key={project?.project?._id} value={project?.project?.ProjectName}>
                            {project?.project?.ProjectName}
                        </Option>
                    ))}
                </Select>
            ),
        },
        {
            title: 'Frequency',
            dataIndex: 'frequency',
            key: 'frequency',
            render: (text) => (Array.isArray(text) ? text.join(', ') : 'N/A'), // Display frequency as comma-separated values
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (_, record) => (
                <Button onClick={() => handleEdit(record)} type="link">
                    Edit
                </Button>
            ),
        },
    ];

    return (
        <div>
            <Button onClick={handleAddEmail} mt={5} colorScheme="teal" mb={4}>
                Add Email
            </Button>
            <Table dataSource={mails} style={{ marginTop: "30px" }} columns={columns} rowKey="_id" />

            <Modal
                title={editingMail ? "Edit Email" : "Add Email"}
                visible={visible}
                onOk={handleOk}
                onCancel={handleCancel}
            >
                <Input
                    placeholder="Enter email"
                    value={email}
                    style={{ marginTop: "30px" }}
                    onChange={e => setEmail(e.target.value)}
                />
                <Select
                    mode="multiple"
                    placeholder="Select projects"
                    value={selectedProjects}
                    onChange={handleProjectsChange}
                    style={{ width: '100%', marginTop: '30px' }}
                >
                    {projects.map(project => (
                        <Option key={project?.project?._id} value={project?.project?.ProjectName}>
                            {project?.project?.ProjectName}
                        </Option>
                    ))}
                </Select>
                <Select
                    mode="multiple" // Enable multiple selections
                    placeholder="Select frequency"
                    value={selectedFrequency}
                    onChange={handleFrequencyChange}
                    style={{ width: '100%', marginTop: '30px' }}
                >
                    <Option value="Daily">Daily</Option>
                    <Option value="Weekly">Weekly</Option>
                </Select>
            </Modal>
        </div>
    );
};

export default EmailMaster;
