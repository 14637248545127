import React from 'react';
import {
  BarLoader,
  BeatLoader,
  BounceLoader,
  CircleLoader,
  ClimbingBoxLoader,
  ClipLoader,
  ClockLoader,
  DotLoader,
  FadeLoader,
  GridLoader,
  HashLoader,
  MoonLoader,
  PacmanLoader,
  PropagateLoader,
  PuffLoader,
  PulseLoader,
  RingLoader,
  RiseLoader,
  RotateLoader,
  ScaleLoader,
  SyncLoader,
} from 'react-spinners';

const Loader = () => {
  return (
    <div className='loader' style={{  textAlign: "center",
      backgroundColor: "white",
      display: "flex",
      justifyContent: "center",
      width: "100%",
      height: "50vh"  }}>
      <div style={{margin: 'auto 0'}}>

      <BeatLoader  color="#3182CE" size={30} />
      </div>
    </div>
  );
};

export default Loader;
