import {put, call, takeLatest, all} from 'redux-saga/effects';
import {types} from '../actions/types';
import {getCompanyAPI, addCompanyAPI, editCompanyAPI, deleteCompanyAPI,getCompanyDetailsAPI} from '../../store/apis';

function* getCompanyList({payload, error}) {
  try {
    // yield put({type: types.SEND_REQUEST_USER_LOGIN});
    // console.log('getSiteList',payload);
    const newsArray = yield call(getCompanyAPI,payload);   
    // console.log('newsArray',newsArray); 
    if(newsArray.status=="success")
      yield put({type: types.GET_COMPANY_REQUEST_SUCCESS, payload: newsArray});
    else
      yield put({type: types.GET_COMPANY_REQUEST_FAILURE, payload: newsArray});
  } catch (err) {
    // console.log('appSettings',err);
    yield put({type: types.GET_COMPANY_REQUEST_FAILURE, payload: err});
    // console.log(err);
  }
}
function* addCompany({payload, error}) {
  try {
    // yield put({type: types.SEND_REQUEST_USER_LOGIN});
    // console.log('getSiteList',payload);
    const newsArray = yield call(addCompanyAPI,payload);   
    // console.log('newsArray',newsArray); 
    if(newsArray.status=="success")
      yield put({type: types.ADD_COMPANY_REQUEST_SUCCESS, payload: newsArray});
    else
      yield put({type: types.ADD_COMPANY_REQUEST_FAILURE, payload: newsArray});
  } catch (err) {
    // console.log('appSettings',err);
    yield put({type: types.ADD_COMPANY_REQUEST_FAILURE, payload: err});
    // console.log(err);
  }
}
function* editCompany({payload, error}) {
  try {
    // yield put({type: types.SEND_REQUEST_USER_LOGIN});
    // console.log('getSiteList',payload);
    const newsArray = yield call(editCompanyAPI,payload);   
    // console.log('newsArray',newsArray); 
    if(newsArray.status=="success")
      yield put({type: types.EDIT_COMPANY_REQUEST_SUCCESS, payload: newsArray});
    else
      yield put({type: types.EDIT_COMPANY_REQUEST_FAILURE, payload: newsArray});
  } catch (err) {
    // console.log('appSettings',err);
    yield put({type: types.EDIT_COMPANY_REQUEST_FAILURE, payload: err});
    // console.log(err);
  }
}
function* deleteCompany({payload, error}) {
  try {
    // yield put({type: types.SEND_REQUEST_USER_LOGIN});
    // console.log('getSiteList',payload);
    const newsArray = yield call(deleteCompanyAPI,payload);   
    // console.log('newsArray',newsArray); 
    if(newsArray.status=="success")
      yield put({type: types.DELETE_COMPANY_REQUEST_SUCCESS, payload: newsArray});
    else
      yield put({type: types.DELETE_COMPANY_REQUEST_FAILURE, payload: newsArray});
  } catch (err) {
    // console.log('appSettings',err);
    yield put({type: types.DELETE_COMPANY_REQUEST_FAILURE, payload: err});
    // console.log(err);
  }
}
function* getCompanyDetails({payload, error}) {
  try {
    // yield put({type: types.SEND_REQUEST_USER_LOGIN});
    // console.log('getSiteList',payload);
    const newsArray = yield call(getCompanyDetailsAPI,payload);   
    // console.log('newsArray',newsArray); 
    if(newsArray.status=="success")
      yield put({type: types.GET_COMPANY_DETIALS_REQUEST_SUCCESS, payload: newsArray});
    else
      yield put({type: types.GET_COMPANY_DETIALS_REQUEST_FAILURE, payload: newsArray});
  } catch (err) {
    // console.log('appSettings',err);
    yield put({type: types.GET_COMPANY_DETIALS_REQUEST_FAILURE, payload: err});
    // console.log(err);
  }
}
export default function* companySaga() {
  yield all([
    yield takeLatest(types.GET_COMPANY_REQUEST, getCompanyList),
    yield takeLatest(types.ADD_COMPANY_REQUEST, addCompany),
    yield takeLatest(types.EDIT_COMPANY_REQUEST, editCompany),
    yield takeLatest(types.DELETE_COMPANY_REQUEST, deleteCompany),
    yield takeLatest(types.GET_COMPANY_DETIALS_REQUEST, getCompanyDetails),    
    // LOAD_LOGGEDIN_USER(),
  ])
}
