import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./index.css";
import { Provider } from "react-redux";
// import createSagaMiddleware from "@redux-saga/core";
// import { configureStore } from "@reduxjs/toolkit";
// import dashboardReducer from "./Reducers/dashboardSlice";
// import dashboardSaga from "./saga/dashboardSaga";
import storeConfigure from './store';
import { ChakraProvider } from "@chakra-ui/react";


const store = storeConfigure()

// const saga = createSagaMiddleware();
// const store = configureStore({
//   reducer: {
//     dashboard: dashboardReducer,
//   },
//   middleware: [saga],
// });
// saga.run(dashboardSaga);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ChakraProvider>
    <Provider store={store}>
    <App />
  </Provider>
  </ChakraProvider>
  
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
