import React, { useEffect, useState } from 'react';
import iconMap from "../../assets/images/icon-map.png";
import PropTypes from 'prop-types';
import LineChartOHRS from "../Graph/LineChartOHRS";
import {getOHRSGraphData} from '../../redux/actions/graphAction'
import {useDispatch, useSelector} from 'react-redux';
import {types} from '../../redux/actions/types';
import { useToast } from '@chakra-ui/react';

const CardOHRS = ({ title, graphFor, siteId, type }) => {
  const dispatch = useDispatch();
  const graphReducer = useSelector(state => state.graphReducer);
  const [graphData, setGraphData] = useState([]);
  const toast = useToast()
  let timeOutGraphs=null;

  useEffect(()=> {
    fetchOHRSGraphData()
  }, [siteId])

  useEffect(()=>{
    // console.log('CardOHRS-graphReducer-useEffect',graphReducer);    
    if(graphReducer.action){
      if(graphReducer.action===types.GET_GRAPH_OHRS_REQUEST_SUCCESS){
        // console.log('CardOHRS-graphReducer-useEffect',graphReducer)        
        if(graphReducer.status==="success"){
          if(graphReducer.data){
            setGraphData(graphReducer.data);
          }
        }else {
          toast({
            title: graphReducer.message,
            status: 'warning',
            duration: 2000,
            isClosable: true,
            position: "top-right",
            positionOptions: {
              right: "0",
              top: "0",
            },
          })
        }
      }
      else if(graphReducer.action===types.GET_GRAPH_OHRS_REQUEST_FAILURE){
        // alert(graphReducer.message);
      }
    }
  },[graphReducer.action])
  const fetchOHRSGraphData=()=>{
      const request={siteId,graphFor}
      dispatch(getOHRSGraphData(request));    
    // }    
  }
  
  return (
    <div className="card">
      <div className="card-header">
        <h4 className="card-header-title mb-2 mb-sm-0">
          <i className="icon-map">
            <img src={iconMap} alt="map icon" />
          </i>{" "}
          {title}
        </h4>
      </div>
      <div className="card-body"><LineChartOHRS dataList={graphData} yLabel={'OHSR Level'} xLabel={'Hour'} /></div>
    </div>
  );
};

CardOHRS.propTypes = {
  title: PropTypes.string,
  type: PropTypes.string,
  graphFor: PropTypes.string,
  siteId: PropTypes.string,
  // content: PropTypes.object,
};
export default CardOHRS;
