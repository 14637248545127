import {CONSTANTVALUE} from './index';
import CryptoJS from 'crypto-js';
export const storeData=async(key,value)=>{
    // To store data
    // Encrypt
    var ciphertext = CryptoJS.AES.encrypt(JSON.stringify(value),CONSTANTVALUE.SECREATEKEY).toString();
    //log encrypted data
    // console.log('Encrypt Data -')
    // console.log(ciphertext);
    localStorage.setItem(key,ciphertext);
}
export const getData=async(key)=>{
    // To store data
    const storedValue=localStorage.getItem(key);
    var decryptedData=null;
    if(storedValue){
        // Decrypt
        var bytes = CryptoJS.AES.decrypt(storedValue,CONSTANTVALUE.SECREATEKEY);
        decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        // console.log('Decrypt Data -')
        // console.log(decryptedData);
    }    
    return decryptedData;
}
export const clearData=async()=>{
    // To store data
    localStorage.removeItem(CONSTANTVALUE.USEROBJECT);
    localStorage.removeItem(CONSTANTVALUE.USERTOKEN);
    // console.log('clearData')
}