import React, { useEffect, useState } from 'react';
import { useNavigate,Link } from "react-router-dom";
import {useDispatch, useSelector} from 'react-redux';
import {projectList,deleteProject} from '../../redux/actions/projectAdminAction'
import {types} from '../../redux/actions/types';
import NavBarAdmin from "../../Components/NavBarAdmin";
import AdminMenu from "../../Components/AdminMenu";
import {isEmpty, isLoggedInUser,checkPermission, getData, CONSTANTVALUE} from '../../utils'
import { apiName } from '../../store/serverConfig';
import { apiGetCallWithPromise } from '../../store/axiosApicall';
import { Box,  Input, InputGroup, InputLeftElement, useToast } from '@chakra-ui/react';
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Button,
  TableCaption,
  TableContainer,
} from '@chakra-ui/react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Text,
  useDisclosure,
  Code,
  Flex,  
} from '@chakra-ui/react'
import { AiOutlineSearch } from 'react-icons/ai';
 
const Projects = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [listSite, setListSite] = useState([]);
  const [listProjects, setListProjects] = useState([]);

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(20);
  const [currentSite, setCurrentSite] = useState();
  /////////////////////////////////////////////////////////////////
  /////Parameters for getting permission value/////////////////////
  /////////////////////////////////////////////////////////////////
  const [isCreate, setIsCreate] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [isView, setIsView] = useState(false);   
  const [searchValue, setSearchValue] = useState('');
  const [isModalOpen, setModalOpen] = useState(false);
  const [userId, setUserId] = useState("");
  const [isAdmin, setIsAdmin] = useState(false);
  const [assignedProjects, setAssignedProjects] = useState([]);
  const [deleteButtonAllow, setDeleteButtonAllow] = useState(false);
  const [deletionProjectId, setDeletionProjectId] = useState('');
  const toast = useToast()

 
  const projectAdminReducer = useSelector(state => state.projectAdminReducer);
  useEffect(()=>{
    // setIsLoading(true);
    // console.log('LogIn-useEffect');    
    chkLogin(); 
    setPermission();    
    fetchUserData();
  },[])
 
  const fetchUserData = async () => {
    const data = await getData(CONSTANTVALUE.USEROBJECT);
    console.log("oooo", data)
    if(data && data.EmploymentType) setIsAdmin(data.EmploymentType == 'Admin')
    // if(data && data.companyDetails && data.companyDetails._id)
    setUserId(data.id)
  }

  const deleteSiteData = () => {
    deleteSelProject(deletionProjectId);
    setDeletionProjectId('')
  }
  
  useEffect(()=> {
    if(userId.length > 0)
      fetchAssignedProjects();
  }, [userId])
 
  useEffect(()=>{
    // setIsLoading(true);
    // console.log('LogIn-useEffect');    
    if(isView)
      fetchProjectList();   
  },[isView])
  useEffect(()=>{
    console.log('Projects-projectAdminReducer-useEffect',projectAdminReducer);    
    if(projectAdminReducer.action){
      if(projectAdminReducer.action===types.LIST_PROJECT_REQUEST_SUCCESS){
        console.log('Projects-projectAdminReducer-useEffect',projectAdminReducer)      
        if(projectAdminReducer.status==="success"){
          setListProjects(projectAdminReducer.data.list);
          // storeData(CONSTANTVALUE.USEROBJECT,userReducer.data);
          // storeData(CONSTANTVALUE.USERTOKEN,userReducer.token);
          // navigate('/dashboard');
        }else {
          toast({
            title: projectAdminReducer.message,
            status: 'info', // You can change the status to 'info', 'warning', 'success', or 'error' based on your preference.
            duration: 2000,
            isClosable: true,
            position: "top-right",
            positionOptions: {
              right: "0",
              top: "0",
            },
          });
          
        }
      }
      else if(projectAdminReducer.action===types.DELETE_PROJECT_REQUEST_SUCCESS){
        console.log('Projects-projectAdminReducer-useEffect',projectAdminReducer)      
        if(projectAdminReducer.status==="success"){
          fetchProjectList();
          // setListSite(projectAdminReducer.data.list);
          // storeData(CONSTANTVALUE.USEROBJECT,userReducer.data);
          // storeData(CONSTANTVALUE.USERTOKEN,userReducer.token);
          // navigate('/dashboard');
        }else {
          toast({
            title: projectAdminReducer.message,
            status: 'info', // You can change the status to 'info', 'warning', 'success', or 'error' based on your preference.
            duration: 2000,
            isClosable: true,
            position: "top-right",
            positionOptions: {
              right: "0",
              top: "0",
            },
          });
          
        }
      }
    }
  },[projectAdminReducer.action])
 
  const chkLogin=async()=>{
    const isLoggedIn=await isLoggedInUser();
    // console.log("isLoggedInUser()",isLoggedIn);
    if(!isLoggedIn){
      navigate('/Admin');
    }
  }  

  const fetchAssignedProjects = async () => {
    try {
      const data = await apiGetCallWithPromise(apiName.getAssignedProjectsByCompanyIdAPI, `userId=${userId}`);
      console.log("xxx main data", data)
      if(data && data.data) setAssignedProjects(data.data.map((item) => item.result.ProjectName));
    } catch (err) {
      console.log("Error while fetching projects assigned", err.message)
    }
  }

  const setPermission=async()=>{
    setIsCreate(await checkPermission('Projects','create'));
    setIsEdit(await checkPermission('Projects','edit'));
    setIsDelete(await checkPermission('Projects','delete'));
    setIsView(await checkPermission('Projects','view'));
    console.log('Projects-User Permissions-isCreate='+isCreate+'-isEdit-'+isEdit+'-isDelete-'+isDelete+'-isView-'+isView); 
   
  }
  const fetchProjectList=async()=>{
    // console.log("fetchSiteList");
    dispatch(projectList("page="+page+"&limit="+limit+""));
    // const isLoggedIn=await isLoggedInUser();
    // console.log("isLoggedInUser()",isLoggedIn);
    // if(isLoggedIn){
    //   navigate('/Admin/SiteListing');
    // }
  } 
  const handleDeleteEvent=async (item)=>{
    // Alert('Are you sure you want to remove?');
    // window.confirm("What Is Your Age?");
    // setSelectedId(item._id)
    console.log("adsf!=item",item);
    setCurrentSite(item.ProjectName)
    
    //comehere
    
    const response = await apiGetCallWithPromise(apiName.getSitesByProjectIdAPI,"projectID="+item._id+"&page="+1+"&limit="+1000);
    console.log('xxx sites information ::: > ', response.data.length)
    const sites = response.data.length;
    console.log('xxx current length', sites)
    if(sites > 0) {
      toast({
        title: 'You cannot delete the project without deleting the sites !',
        status: 'error', // You can change the status to 'info', 'warning', 'success', or 'error' based on your preference.
        duration: 2000,
        isClosable: true,
        position: "top-right",
        positionOptions: {
          right: "0",
          top: "0",
        },
      });
      return
    } else{
      setDeletionProjectId(item._id);
      setModalOpen(true);
    } 
  
    // setSelectedId(item._id);
    // const request={id:item._id};
    // dispatch(deleteSite(item._id));
  }
  const handleEditEvent=(item)=>{
    console.log('Project',item);
    navigate('/Admin/AddProject',{state: {
      projectId: item._id,
      projectName: item.ProjectName,
    }});
    // navigate('/Admin/AddSite');
  }
  // const deleteItemClick=(item)=>{
  //   setNewsObject(item);
  //   console.log(item);
  // }
  const deleteSelProject=async(selectedId)=>{   
    console.log("deleteSite-selectedId",selectedId); 
    dispatch(deleteProject(selectedId));
    // const interval = setInterval(() => {
    //   dispatch(deleteSite(selectedId));
    // }, 1000);
    // return () => clearInterval(interval);
    // console.log("deleteSite-selectedId",selectedId); 
    // dispatch(deleteSite(selectedId));
    // console.log('site',item);
  }
  const bindData=()=>{
    return (
      <TableContainer>
        <Table variant='striped' colorScheme='gray'>
          <Thead>
            <Tr>
              <Th fontSize={'md'}>Project Name</Th>
              <Th fontSize={'md'}>State</Th>
              <Th fontSize={'md'}>Latitude</Th>
              <Th fontSize={'md'}>Longitude</Th>
              <Th fontSize={'md'}>Action</Th>
            </Tr>
          </Thead>
          <Tbody>
            {listProjects.length > 0 ? (
              listProjects.filter((item)=> item.ProjectName.toString().toLowerCase().includes(searchValue.toLowerCase())).map((item, index) =>
                assignedProjects.includes(item.ProjectName.toString()) ? (
                  <Tr key={index}>
                    <Td>{item.ProjectName.toString()}</Td>
                    <Td>{item.StateName.toString()}</Td>
                    <Td>{item.latitude.toString()}</Td>
                    <Td>{item.longitude.toString()}</Td>
                    {isAdmin && (
                      <Td>
                        <Button
                          colorScheme='blue'
                          onClick={() => handleEditEvent(item)}
                          size={'sm'}
                        >
                          {isEdit ? 'Update' : 'View'}
                        </Button>{' '}
                        {isDelete ? (
                          <Button
                            colorScheme='red'
                            size={'sm'}
                            onClick={() => handleDeleteEvent(item)}
                          >
                            Delete
                          </Button>
                        ) : null}
                      </Td>
                    )}
                  </Tr>
                ) : null
              )
            ) : (
              <Tr>
                <Td>No Record Found</Td>
              </Tr>
            )}
          </Tbody>
        </Table>
      </TableContainer>
    )
  }

  const changeHandler = (e) => {
    if (e.target.value.trim() == currentSite.trim()) setDeleteButtonAllow(true);
    else setDeleteButtonAllow(false);
    console.log("xxx input data", e.target.value, currentSite)
  }
  const noViewPermission=()=>{
    return(<p class="text-center">You don't have permission to view the data. Please contact to administrator for more details</p>)
  }
  return (
    <div className="page">
      <Modal blockScrollOnMount={false} isOpen={isModalOpen} onClose={()=> {setModalOpen(false)}}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            Delete Action Required !
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text fontWeight='bold' mb='1rem'>
            </Text>
            <Code mb='7px'>{currentSite}</Code>
            <Input placeholder='Enter above project name to delete' onChange={(e) => { changeHandler(e) }} />
            <Button mt='10px' colorScheme='red' isDisabled={!deleteButtonAllow} onClick={() => deleteSiteData()}>Confirm</Button>
          </ModalBody>
          <ModalFooter>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <NavBarAdmin />
      <AdminMenu selMenu="Project" />
      <div className="project-page-head" style={{width: '90%', borderRight : '8px solid black', borderRadius: '20px', marginLeft: "5px", marginTop : '10px'}}>
        <div className="row m-0">
          <div className="col-12 py-4">
            <div className="sub-head">Projects</div>
            <h3 className="project-title fw-bold">Project Listing</h3>
          </div>
        </div>
      </div>
      <section>      
        <div className="container">   
        <Flex alignItems={'flex-end'} justifyContent={'end'} gap={'3'}>
        <Box>
        <InputGroup>
          <InputLeftElement pointerEvents='none'>
            <AiOutlineSearch color='gray.300' />
          </InputLeftElement>
              <Input size={'md'} placeholder='Enter project name' colorScheme='blue' variant='filled' onChange={(e) => setSearchValue(e.target.value)}/>
        </InputGroup>
        </Box>
      </Flex>     
          <div className="row d-flex justify-content-center h-100 pt-5">
            {/* <div className="col-md-8 col-lg-8 col-xl-6"> */}
              <div className="card text-black" style={{ borderRadius: "10px" }}>
                  <div className="d-flex justify-content-end mx-2 mb-2 mb-lg-2">
                    {isCreate?<Link className="btn btn-primary mt-3" to={"/Admin/AddProject"}>Add Project</Link>:null}
                  </div>
                <div className="card-body">                  
                  {isView?bindData():noViewPermission()}
                </div>
              </div>              
            {/* </div> */}
          </div>
        </div>
      </section>
    </div>
  );
};
 
export default Projects;