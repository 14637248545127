import React, { useEffect, useState } from 'react';
import iconMap from "../../assets/images/icon-map.png";
import PropTypes from 'prop-types';
import AreaChartTurbidity from "../Graph/AreaChartTurbidity";
import { getTurbidityGraphData } from '../../redux/actions/graphAction'
import { useDispatch, useSelector } from 'react-redux';
import { types } from '../../redux/actions/types';
import { useToast } from '@chakra-ui/react';

const CardTurbidity = ({ title, graphFor, siteId, type }) => {
  const dispatch = useDispatch();
  const graphReducer = useSelector(state => state.graphReducer);
  const [graphData, setGraphData] = useState([]);
  let timeOutGraphs = null;
  const toast = useToast()

  useEffect(() => {
    fetchTurbidityGraphData()
  }, [siteId])

  useEffect(() => {
    if (graphReducer.action) {
      if (graphReducer.action === types.GET_GRAPH_TURBIDITY_REQUEST_SUCCESS) {
        //console.log('CardTurbidity-graphReducer-useEffect',graphReducer.data)        
        if (graphReducer.status === "success") {
          if (graphReducer.data) {
            setGraphData(graphReducer.data);
          }
        } else {
          toast({
            title: graphReducer.message,
            status: 'warning',
            duration: 2000,
            isClosable: true,
            position: "top-right",
            positionOptions: {
              right: "0",
              top: "0",
            },
          });
        }
      }
      else if (graphReducer.action === types.GET_GRAPH_TURBIDITY_REQUEST_FAILURE) {
        toast({
          title: graphReducer.message,
          status: 'warning',
          duration: 2000,
          isClosable: true,
          position: "top-right",
          positionOptions: {
            right: "0",
            top: "0",
          },
        });
      }
    }
  }, [graphReducer.action])
  const fetchTurbidityGraphData = () => {
    const request = { siteId, graphFor }
    dispatch(getTurbidityGraphData(request));
  }

  return (
    <div className="card">
      <div className="card-header">
        <h4 className="card-header-title mb-2 mb-sm-0">
          <i className="icon-map">
            <img src={iconMap} alt="map icon" />
          </i>{" "}
          {title}
        </h4>
      </div>
      <div className="card-body"><AreaChartTurbidity dataList={graphData} yLabel={'Turbidity (NTU)'} xLabel={'Date'} /></div>
    </div>
  );
};

CardTurbidity.propTypes = {
  title: PropTypes.string,
  type: PropTypes.string,
  graphFor: PropTypes.string,
  siteId: PropTypes.string,
  // content: PropTypes.object,
};
export default CardTurbidity;
