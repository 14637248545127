import React, { useEffect, useState } from 'react';
import angleLeft from "../../assets/images/down-arrow.svg";
import "./offCanvas.css";
import BasicInfo from "../BasicInfo";
import {useDispatch, useSelector} from 'react-redux';
// import { leftCanvasToggle } from "../../redux/reducers/dashboardSlice";
// import { Link } from "react-router-dom";
// import CanvasItem from "./CanvasItem";
// import {getBasicInfo} from '../../redux/actions/basicInfoAction'
// import {types} from '../../redux/actions/types';

const OffCanvasLeft = () => {
  const dispatch = useDispatch();
  const navRef = React.useRef(null);
  const onToggleClick = (e) => {
    navRef.current.classList.toggle("show");
  };
  function addActiveClassForArrow() {
    var element = document.getElementById("active_l");
    {
      if (element.classList.contains("active_arrow_left"))
        element.classList.remove("active_arrow_left");
      else
        element.classList.add("active_arrow_left");      
    }
  }
  return (
    <div className="left_canvas_pop_bg" ref={navRef}>
      <div className="left_canvas_body">
        <BasicInfo />
      </div>
      <div className="arrow" onClick={() => dispatch(onToggleClick(), addActiveClassForArrow())}><img id="active_l" src={angleLeft} alt="" /></div>
    </div>
  );
};

export default OffCanvasLeft;
