import React, { useEffect, useState, useMemo, useRef } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import PropTypes from 'prop-types';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const LineChartOHRS = ({ dataList, xLabel, yLabel }) => {
  const chartRef = useRef();
  const [graphStatus, setGraphStatus] = useState(false);

  // Memoized chart data
  const chartData = useMemo(() => {
    if (dataList && dataList.length > 0) {
      const labels = dataList.map(item => item.x);
      const dataPoints = dataList.map(item => parseFloat(item.y.toFixed(5)));

      return {
        labels,
        datasets: [
          {
            label: "OHSR",
            data: dataPoints,
            borderColor: "rgba(53, 162, 235, 0.8)",
            backgroundColor: "rgba(53, 162, 235, 0.5)",
            pointBackgroundColor: "#fff",
            pointBorderColor: "rgba(53, 162, 235, 1)",
            pointHoverBackgroundColor: "rgba(255, 99, 132, 1)",
            pointHoverBorderColor: "#fff",
            pointRadius: 5,
            tension: 0.4, // Smoother curve
          },
        ],
      };
    }
    return null;
  }, [dataList]);

  useEffect(() => {
    if (chartData && chartData.labels.length > 0) {
      setGraphStatus(true);
    } else {
      setGraphStatus(false);
    }
  }, [chartData]);

  // Memoized chart options
  const chartOptions = useMemo(() => ({
    scales: {
      y: {
        title: {
          display: true,
          text: yLabel,
          font: {
            size: 16,
            weight: "bold",
            family: "'Roboto', sans-serif", // Modern font
          },
        },
        min: 0,
        max: 100,
        ticks: {
          stepSize: 10,
          color: "#888", // Modern text color
          font: {
            size: 12,
          },
        },
        grid: {
          color: "#ddd", // Light grid lines
        },
      },
      x: {
        title: {
          display: true,
          text: xLabel,
          font: {
            size: 16,
            weight: "bold",
            family: "'Roboto', sans-serif", // Modern font
          },
        },
        min: 0,
        max: new Date().getHours(),
        ticks: {
          stepSize: 1,
          color: "#888", // Modern text color
          font: {
            size: 12,
          },
        },
        grid: {
          color: "#ddd", // Light grid lines
        },
      },
    },
    responsive: true,
    maintainAspectRatio: false, // Adjust for dynamic sizing
    plugins: {
      legend: {
        position: "top",
        labels: {
          font: {
            size: 14,
            family: "'Roboto', sans-serif", // Modern font
          },
          color: "#444", // Modern text color
        },
      },
      title: {
       
      },
      tooltip: {
        enabled: true,
        backgroundColor: "rgba(0, 0, 0, 0.7)",
        titleFont: {
          size: 14,
          family: "'Roboto', sans-serif",
        },
        bodyFont: {
          size: 12,
        },
        footerFont: {
          size: 10,
        },
        cornerRadius: 4,
      },
    },
  }), [xLabel, yLabel]);

  return (
    graphStatus ? (
      <div style={{ position: 'relative', height : '270px', width: '100%' }}>
        <Line ref={chartRef} options={chartOptions} data={chartData} />
      </div>
    ) : (
      <p>No data available to display.</p>
    )
  );
};

LineChartOHRS.propTypes = {
  dataList: PropTypes.arrayOf(
    PropTypes.shape({
      x: PropTypes.string.isRequired,
      y: PropTypes.number.isRequired,
    })
  ).isRequired,
  xLabel: PropTypes.string.isRequired,
  yLabel: PropTypes.string.isRequired,
};

export default LineChartOHRS;
