  import React, { useState } from 'react';
  import NavBarAdmin from './NavBarAdmin';
  import AdminMenu from './AdminMenu';
  import { apiGetCallWithPromise } from '../store/axiosApicall';
  import { apiName } from '../store/serverConfig';
  import { useEffect } from 'react';
  import Popup from './Card/Popup';


import { Button, useToast, Table, Thead, Tbody, Tr, Th, Td, Switch } from '@chakra-ui/react';

  const ImeiApprovalPage = () => {
    

    const [data, setData] = useState([]);
    const [pageSize, setPageSize] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [isLoadingMore, setIsLoadingMore] = useState(false);
    const [popupMessage, setPopupMessage] = useState(false);
    const [skip, setSkip] = useState(0);
    const [limit, setLimit] = useState(50);
    const [dataExhausted, setDataExhausted] = useState(false);
    const rate = 50;


    useEffect(()=> {
      fetchImeiUsers();
    }, [])

    useEffect(()=> {
      fetchImeiUsers();
    }, [limit])

    useEffect(() => {
      if (popupMessage) {
        const timer = setTimeout(() => {
          setPopupMessage(false);
        }, 1000);
        return () => clearTimeout(timer);
      }
    }, [popupMessage]);
    
    const toast = useToast();

    const fetchImeiUsers = async () => {
      try {
        const userData = await apiGetCallWithPromise(apiName.getImeiAprrovalUsers, `skip=${skip}&&limit=${limit}`);
        console.log("xxx userData", userData)
        if(userData.data.length == 0 ||userData.data.length == data.length) setDataExhausted(true);
        const users = userData.data.map((item)=> {
          item.isApproved = true;
          return item;
        })
        setData(users);
        // if(flag){setPopupMessage(true);
        //     setTimeout(()=> {
        //       setPopupMessage(false);
        //       console.log("xxx inner timer")
        //     }, 3000)}
        console.log("xxx data", users)
      } catch (err) {
        console.log(err.message)
      }
    }

    const handleUpdate = async (row) => {
      console.log("Row", row);
      try {
        const data = await apiGetCallWithPromise(apiName.approveImei, `userId=${row._id}&&status=${row.isApproved}`);
        if (data.message === "IMEI Approved") {
          toast({
            title: 'IMEI Status Updated',
            description: `IMEI successfully updated !`,
            status: 'success',
            duration: 2000,
            isClosable: true,
            colorScheme: 'blue',
            position: "top-right",
            positionOptions: {
              right: "0",
              top: "0",
            },
        })
          fetchImeiUsers();
        }
      } catch (error) {
        console.log("err", error.message);
      }
    };
    

    const handlePageSizeChange = (event) => {
      setPageSize(Number(event.target.value));
      setCurrentPage(1);
    };

    const handlePageChange = (pageNumber) => {
      setCurrentPage(pageNumber);
    };

    const handleLoadMore = () => {
      setLimit(limit + rate);
    };

    const renderTableRows = () => {
      const startIndex = (currentPage - 1) * pageSize;
      const endIndex = startIndex + pageSize;
      const currentRecords = data.slice(startIndex, endIndex);

      return currentRecords.map((row, index) => (
          <Tr key={index}>
        <Td>{row.OperatorName}</Td>
        <Td>{row.MobileNo}</Td>
        <Td>{row.IMEINumber}</Td>
        <Td>
  <Switch
    size={'md'}
    isChecked={row.isApproved}
    onChange={(e) => {
      const updatedData = [...data];
      updatedData[index].isApproved = e.target.checked;
      setData(updatedData);
      console.log("xxx row", e.target.checked);
    }}
/>
</Td>

        <Td>
          <Button onClick={() => handleUpdate(row)} colorScheme='blue'>
            Update
          </Button>
        </Td>
      </Tr>
      ));
    };

    const renderPagination = () => {
      const totalPages = Math.ceil(data.length / pageSize);

      return (
        <ul className="pagination">
          {Array.from({ length: totalPages }, (_, i) => i + 1).map((pageNumber) => (
            <li
              key={pageNumber}
              className={`page-item ${currentPage === pageNumber ? 'active' : ''}`}
              onClick={() => handlePageChange(pageNumber)}
            >
              <button className="page-link">{pageNumber}</button>
            </li>
          ))}
        </ul>
      );
    };

    return (
      <div className="page">
        {console.log("xxx popupMessage",popupMessage)}
        <NavBarAdmin />
        <AdminMenu selMenu="AssetReport" />
        <div className="project-page-head" style={{width: '90%', borderRight : '8px solid black', borderRadius: '20px', marginLeft: "5px", marginTop : '10px'}}>
          <div className="row m-0">
            <div className="col-12 py-4">
              <div className="sub-head">IMEI Approval</div>
              <h3 className="project-title fw-bold">IMEI APPROVAL</h3>
            </div>
          </div>
        </div>

        <section>
          <div className="container">
        {popupMessage && <Popup message={`IMEI Status Updated`}/>}
            <div className="row d-flex justify-content-center h-100 pt-5">
              <div className="card text-black" style={{ borderRadius: '10px' }}>
                <div className="card-body">
                  <div className="row mb-3">
                    <div className="col-12 col-md-6">
                      <label htmlFor="pageSizeSelect" className="form-label">
                        Page Size:
                      </label>
                      <select
                        id="pageSizeSelect"
                        className="form-select"
                        value={pageSize}
                        onChange={handlePageSizeChange}
                      >
                        <option value={10}>10</option>
                        <option value={20}>20</option>
                        <option value={30}>30</option>
                      </select>
                    </div>
                    <div className="col-12 col-md-6">
                      <div className="d-flex justify-content-end">
                        <div>Page:</div>
                        <div className="mx-2">{currentPage}</div>
                      </div>
                    </div>
                  </div>
                  {/* <div className="table-responsive"> */}
                  <Table variant='striped' colorScheme='gray' size='md'>
                    <Thead>
                      <Tr>
                        <Th>Name</Th>
                        <Th>Mobile Number</Th>
                        <Th>IMEI Number</Th>
                        <Th>Status</Th>
                        <Th>Update</Th>
                      </Tr>
                    </Thead>
                    <Tbody>{renderTableRows()}</Tbody>
                  </Table>
                  {/* </div> */}
                  <div className="d-flex justify-content-center mt-3">
                    {isLoadingMore ? (
                      <button className="btn btn-secondary" disabled>
                        Loading...
                      </button>
                    ) : (
                      <button className="btn btn-primary" onClick={handleLoadMore} disabled={dataExhausted}>
                        {dataExhausted ? "No More Data" : "Load More"}
                      </button>
                    )}
                  </div>

                </div>
              </div>
            </div>
            <div className="row d-flex justify-content-center mt-3">
              {renderPagination()}
            </div>
          </div>
        </section>
      </div>
    );
  };

  export default ImeiApprovalPage;
