import {types} from './types';

export function getLPCDGraphData(payload) {
  return {
    type: types.GET_GRAPH_LPCD_REQUEST,
    payload,
  };
}
export function getChlorineGraphData(payload) {
  return {
    type: types.GET_GRAPH_CHLORINE_REQUEST_REQUEST,
    payload,
  };
}
export function getPressureGraphData(payload) {
  return {
    type: types.GET_GRAPH_PRESSURE_REQUEST,
    payload,
  };
}
export function getWSGraphData(payload) {
  return {
    type: types.GET_GRAPH_WS_REQUEST,
    payload,
  };
}
export function getTurbidityGraphData(payload) {
  return {
    type: types.GET_GRAPH_TURBIDITY_REQUEST,
    payload,
  };
}
export function getOHRSGraphData(payload) {
  return {
    type: types.GET_GRAPH_OHRS_REQUEST,
    payload,
  };
}