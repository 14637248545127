import {put, call, takeLatest, all} from 'redux-saga/effects';
import {types} from '../actions/types';
import {newsListAPI,addNewsAPI,editNewsAPI,deleteNewsAPI,newsDetailsAPI} from '../../store/apis';

function* getNewsList({payload, error}) {
  try {
    // yield put({type: types.SEND_REQUEST_USER_LOGIN});
    // console.log('getSiteList',payload);
    const newsArray = yield call(newsListAPI,payload);   
    // console.log('newsArray',newsArray); 
    if(newsArray.status=="success")
      yield put({type: types.GET_NEWS_REQUEST_SUCCESS, payload: newsArray});
    else
      yield put({type: types.GET_NEWS_REQUEST_FAILURE, payload: newsArray});
  } catch (err) {
    // console.log('appSettings',err);
    yield put({type: types.GET_NEWS_REQUEST_FAILURE, payload: err});
    // console.log(err);
  }
}
function* addNews({payload, error}) {
  try {
    // yield put({type: types.SEND_REQUEST_USER_LOGIN});
    // console.log('getSiteList',payload);
    const newsArray = yield call(addNewsAPI,payload);   
    // console.log('newsArray',newsArray); 
    if(newsArray.status=="success")
      yield put({type: types.ADD_NEWS_REQUEST_SUCCESS, payload: newsArray});
    else
      yield put({type: types.ADD_NEWS_REQUEST_FAILURE, payload: newsArray});
  } catch (err) {
    // console.log('appSettings',err);
    yield put({type: types.ADD_NEWS_REQUEST_FAILURE, payload: err});
    // console.log(err);
  }
}
function* editNews({payload, error}) {
  try {
    // yield put({type: types.SEND_REQUEST_USER_LOGIN});
    // console.log('getSiteList',payload);
    const newsArray = yield call(editNewsAPI,payload);   
    // console.log('newsArray',newsArray); 
    if(newsArray.status=="success")
      yield put({type: types.EDIT_NEWS_REQUEST_SUCCESS, payload: newsArray});
    else
      yield put({type: types.EDIT_NEWS_REQUEST_FAILURE, payload: newsArray});
  } catch (err) {
    // console.log('appSettings',err);
    yield put({type: types.EDIT_NEWS_REQUEST_FAILURE, payload: err});
    // console.log(err);
  }
}
function* deleteNews({payload, error}) {
  try {
    // yield put({type: types.SEND_REQUEST_USER_LOGIN});
    // console.log('getSiteList',payload);
    const newsArray = yield call(deleteNewsAPI,payload);   
    // console.log('newsArray',newsArray); 
    if(newsArray.status=="success")
      yield put({type: types.DELETE_NEWS_REQUEST_SUCCESS, payload: newsArray});
    else
      yield put({type: types.DELETE_NEWS_REQUEST_FAILURE, payload: newsArray});
  } catch (err) {
    // console.log('appSettings',err);
    yield put({type: types.DELETE_NEWS_REQUEST_FAILURE, payload: err});
    // console.log(err);
  }
}
function* getNewsById({payload, error}) {
  try {
    // yield put({type: types.SEND_REQUEST_USER_LOGIN});
    // console.log('getSiteDetails',payload);
    const responseObject = yield call(newsDetailsAPI,payload);   
    // console.log('siteDetailsObject',siteDetailsObject); 
    // if(userObject.status=200)
    yield put({type: types.NEWS_BY_ID_REQUEST_SUCCESS, payload: responseObject});
    // else
    //   yield put({type: types.SEND_REQUEST_USER_LOGIN_FAILURE, payload: userObject.message});
  } catch (err) {
    // console.log('appSettings',err);
    yield put({type: types.NEWS_BY_ID_REQUEST_FAILURE, payload: err});
    // console.log(err);
  }
}
function* clearNews({payload, error}) {
  yield put({type: types.GET_NEWS_REQUEST_SUCCESS, payload: null});
}
export default function* newsSaga() {
  yield all([
    yield takeLatest(types.GET_NEWS_REQUEST, getNewsList),
    yield takeLatest(types.ADD_NEWS_REQUEST, addNews),
    yield takeLatest(types.EDIT_NEWS_REQUEST, editNews),
    yield takeLatest(types.DELETE_NEWS_REQUEST, deleteNews),
    yield takeLatest(types.NEWS_BY_ID_REQUEST, getNewsById),    
    yield takeLatest(types.CLEAR_NEWS_DATA, clearNews),
    // LOAD_LOGGEDIN_USER(),
  ])
}
