export const types = {
    LOGIN_REQUEST_APPSETTINGS: 'LOGIN_REQUEST_APPSETTINGS',
    LOGIN_REQUEST_APPSETTINGS_SUCCESS: 'LOGIN_REQUEST_APPSETTINGS_SUCCESS',
    LOGIN_REQUEST_APPSETTINGS_FAILURE: 'LOGIN_REQUEST_APPSETTINGS_FAILURE',

    CLEAR_LOGIN_REQUEST_APPSETTINGS: 'CLEAR_LOGIN_REQUEST_APPSETTINGS',
    CLEAR_LOGIN_REQUEST_APPSETTINGS_SUCCESS: 'CLEAR_LOGIN_REQUEST_APPSETTINGS_SUCCESS',
    CLEAR_LOGIN_REQUEST_APPSETTINGS_FAILURE: 'CLEAR_LOGIN_REQUEST_APPSETTINGS_FAILURE',

    GET_SITELIST_REQUEST_APPSETTINGS: 'GET_SITELIST_REQUEST_APPSETTINGS',
    GET_SITELIST_REQUEST_APPSETTINGS_SUCCESS: 'GET_SITELIST_REQUEST_APPSETTINGS_SUCCESS',
    GET_SITELIST_REQUEST_APPSETTINGS_FAILURE: 'GET_SITELIST_REQUEST_APPSETTINGS_FAILURE',


    GET_SITEDETAILS_REQUEST_APPSETTINGS: 'GET_SITEDETAILS_REQUEST_APPSETTINGS',
    GET_SITEDETAILS_REQUEST_APPSETTINGS_SUCCESS: 'GET_SITEDETAILS_REQUEST_APPSETTINGS_SUCCESS',
    GET_SITEDETAILS_REQUEST_APPSETTINGS_FAILURE: 'GET_SITEDETAILS_REQUEST_APPSETTINGS_FAILURE',

    GET_PROJECTDETAILS_REQUEST_APPSETTINGS: 'GET_PROJECTDETAILS_REQUEST_APPSETTINGS',
    GET_PROJECTDETAILS_REQUEST_APPSETTINGS_SUCCESS: 'GET_PROJECTDETAILS_REQUEST_APPSETTINGS_SUCCESS',
    GET_PROJECTDETAILS_REQUEST_APPSETTINGS_FAILURE: 'GET_PROJECTDETAILS_REQUEST_APPSETTINGS_FAILURE',

    GET_BASIC_INFO_REQUEST: 'GET_BASIC_INFO_REQUEST',
    GET_BASIC_INFO_REQUEST_SUCCESS: 'GET_BASIC_INFO_REQUEST_SUCCESS',
    GET_BASIC_INFO_REQUEST_FAILURE: 'GET_BASIC_INFO_REQUEST_FAILURE',

    GET_NEWS_REQUEST: 'GET_NEWS_REQUEST',
    GET_NEWS_REQUEST_SUCCESS: 'GET_NEWS_REQUEST_SUCCESS',
    GET_NEWS_REQUEST_FAILURE: 'GET_NEWS_REQUEST_FAILURE',

    ADD_NEWS_REQUEST: 'ADD_NEWS_REQUEST',
    ADD_NEWS_REQUEST_SUCCESS: 'ADD_NEWS_REQUEST_SUCCESS',
    ADD_NEWS_REQUEST_FAILURE: 'ADD_NEWS_REQUEST_FAILURE',

    EDIT_NEWS_REQUEST: 'EDIT_NEWS_REQUEST',
    EDIT_NEWS_REQUEST_SUCCESS: 'EDIT_NEWS_REQUEST_SUCCESS',
    EDIT_NEWS_REQUEST_FAILURE: 'EDIT_NEWS_REQUEST_FAILURE',

    DELETE_NEWS_REQUEST: 'DELETE_NEWS_REQUEST',
    DELETE_NEWS_REQUEST_SUCCESS: 'DELETE_NEWS_REQUEST_SUCCESS',
    DELETE_NEWS_REQUEST_FAILURE: 'DELETE_NEWS_REQUEST_FAILURE',

    NEWS_BY_ID_REQUEST: 'NEWS_BY_ID_REQUEST',
    NEWS_BY_ID_REQUEST_SUCCESS: 'NEWS_BY_ID_REQUEST_SUCCESS',
    NEWS_BY_ID_REQUEST_FAILURE: 'NEWS_BY_ID_REQUEST_FAILURE',

    GET_MQTTDATA_REQUEST: 'GET_MQTTDATA_REQUEST',
    GET_MQTTDATA_REQUEST_SUCCESS: 'GET_MQTTDATA_REQUEST_SUCCESS',
    GET_MQTTDATA_REQUEST_FAILURE: 'GET_MQTTDATA_REQUEST_FAILURE',

    PUSH_MQTTDATA_REQUEST: 'PUSH_MQTTDATA_REQUEST',
    PUSH_MQTTDATA_REQUEST_SUCCESS: 'PUSH_MQTTDATA_REQUEST_SUCCESS',
    PUSH_MQTTDATA_REQUEST_FAILURE: 'PUSH_MQTTDATA_REQUEST_FAILURE',

    GET_SITELIST_BY_PROJECT: 'GET_SITELIST_BY_PROJECT',
    GET_SITELIST_BY_PROJECT_SUCCESS: 'GET_SITELIST_BY_PROJECT_SUCCESS',
    GET_SITELIST_BY_PROJECT_FAILURE: 'GET_SITELIST_BY_PROJECT_FAILURE',

    SET_IMEI_NUMBER: 'SET_IMEI_NUMBER',
    SET_IMEI_NUMBER_SUCCESS: 'SET_IMEI_NUMBER_SUCCESS',
    SET_IMEI_NUMBER_FAILURE: 'SET_IMEI_NUMBER_FAILURE',

    ADD_SITE_REQUEST: 'ADD_SITE_REQUEST',
    ADD_SITE_REQUEST_SUCCESS: 'ADD_SITE_REQUEST_SUCCESS',
    ADD_SITE_REQUEST_FAILURE: 'ADD_SITE_REQUEST_FAILURE',

    EDIT_SITE_REQUEST: 'EDIT_SITE_REQUEST',
    EDIT_SITE_REQUEST_SUCCESS: 'EDIT_SITE_REQUEST_SUCCESS',
    EDIT_SITE_REQUEST_FAILURE: 'EDIT_SITE_REQUEST_FAILURE',

    DELETE_SITE_REQUEST: 'DELETE_SITE_REQUEST',
    DELETE_SITE_REQUEST_SUCCESS: 'DELETE_SITE_REQUEST_SUCCESS',
    DELETE_SITE_REQUEST_FAILURE: 'DELETE_SITE_REQUEST_FAILURE',

    GET_ASSIGNED_SITES: 'GET_ASSIGNED_SITES',
    GET_ASSIGNED_SITES_SUCCESS : 'GET_ASSIGNED_SITES',
    GET_ASSIGNED_SITES_FAILURE : 'GET_ASSIGNED_SITES_FAILURE',

    LIST_SITE_REQUEST: 'LIST_SITE_REQUEST',
    LIST_SITE_REQUEST_SUCCESS: 'LIST_SITE_REQUEST_SUCCESS',
    LIST_SITE_REQUEST_FAILURE: 'LIST_SITE_REQUEST_FAILURE',

    ADD_PROJECT_REQUEST: 'ADD_PROJECT_REQUEST',
    ADD_PROJECT_REQUEST_SUCCESS: 'ADD_PROJECT_REQUEST_SUCCESS',
    ADD_PROJECT_REQUEST_FAILURE: 'ADD_PROJECT_REQUEST_FAILURE',

    EDIT_PROJECT_REQUEST: 'EDIT_PROJECT_REQUEST',
    EDIT_PROJECT_REQUEST_SUCCESS: 'EDIT_PROJECT_REQUEST_SUCCESS',
    EDIT_PROJECT_REQUEST_FAILURE: 'EDIT_PROJECT_REQUEST_FAILURE',

    DELETE_PROJECT_REQUEST: 'DELETE_PROJECT_REQUEST',
    DELETE_PROJECT_REQUEST_SUCCESS: 'DELETE_PROJECT_REQUEST_SUCCESS',
    DELETE_PROJECT_REQUEST_FAILURE: 'DELETE_PROJECT_REQUEST_FAILURE',

    LIST_PROJECT_REQUEST: 'LIST_PROJECT_REQUEST',
    LIST_PROJECT_REQUEST_SUCCESS: 'LIST_PROJECT_REQUEST_SUCCESS',
    LIST_PROJECT_REQUEST_FAILURE: 'LIST_PROJECT_REQUEST_FAILURE',

    REPORT_REQUEST: 'REPORT_REQUEST',
    REPORT_REQUEST_SUCCESS: 'REPORT_REQUEST_SUCCESS',
    REPORT_REQUEST_FAILURE: 'REPORT_REQUEST_FAILURE',

    CLEAR_NEWS_DATA: 'CLEAR_NEWS_DATA',
    CLEAR_NEWS_DATA_SUCCESS: 'CLEAR_NEWS_DATA_SUCCESS',
    CLEAR_NEWS_DATA_FAILURE: 'CLEAR_NEWS_DATA_FAILURE',

    CLEAR_SITE_DATA: 'CLEAR_SITE_DATA',
    CLEAR_SITE_DATA_SUCCESS: 'CLEAR_SITE_DATA_SUCCESS',
    CLEAR_SITE_DATA_FAILURE: 'CLEAR_SITE_DATA_FAILURE',

    PROJECT_BY_ID_REQUEST: 'PROJECT_BY_ID_REQUEST',
    PROJECT_BY_ID_REQUEST_SUCCESS: 'PROJECT_BY_ID_REQUEST_SUCCESS',
    PROJECT_BY_ID_REQUEST_FAILURE: 'PROJECT_BY_ID_REQUEST_FAILURE',

    GET_GRAPH_CHLORINE_REQUEST_REQUEST: 'GET_GRAPH_CHLORINE_REQUEST_REQUEST',
    GET_GRAPH_CHLORINE_REQUEST_REQUEST_SUCCESS: 'GET_GRAPH_CHLORINE_REQUEST_REQUEST_SUCCESS',
    GET_GRAPH_CHLORINE_REQUEST_REQUEST_FAILURE: 'GET_GRAPH_CHLORINE_REQUEST_REQUEST_FAILURE',
    
    GET_GRAPH_LPCD_REQUEST: 'GET_GRAPH_LPCD_REQUEST',
    GET_GRAPH_LPCD_REQUEST_SUCCESS: 'GET_GRAPH_LPCD_REQUEST_SUCCESS',
    GET_GRAPH_LPCD_REQUEST_FAILURE: 'GET_GRAPH_LPCD_REQUEST_FAILURE',

    GET_GRAPH_PRESSURE_REQUEST: 'GET_GRAPH_PRESSURE_REQUEST',
    GET_GRAPH_PRESSURE_REQUEST_SUCCESS: 'GET_GRAPH_PRESSURE_REQUEST_SUCCESS',
    GET_GRAPH_PRESSURE_REQUEST_FAILURE: 'GET_GRAPH_PRESSURE_REQUEST_FAILURE',

    GET_GRAPH_WS_REQUEST: 'GET_GRAPH_WS_REQUEST',
    GET_GRAPH_WS_REQUEST_SUCCESS: 'GET_GRAPH_WS_REQUEST_SUCCESS',
    GET_GRAPH_WS_REQUEST_FAILURE: 'GET_GRAPH_WS_REQUEST_FAILURE',

    GET_GRAPH_TURBIDITY_REQUEST: 'GET_GRAPH_TURBIDITY_REQUEST',
    GET_GRAPH_TURBIDITY_REQUEST_SUCCESS: 'GET_GRAPH_TURBIDITY_REQUEST_SUCCESS',
    GET_GRAPH_TURBIDITY_REQUEST_FAILURE: 'GET_GRAPH_TURBIDITY_REQUEST_FAILURE',

    GET_GRAPH_OHRS_REQUEST: 'GET_GRAPH_OHRS_REQUEST',
    GET_GRAPH_OHRS_REQUEST_SUCCESS: 'GET_GRAPH_OHRS_REQUEST_SUCCESS',
    GET_GRAPH_OHRS_REQUEST_FAILURE: 'GET_GRAPH_OHRS_REQUEST_FAILURE',

    GET_COMPANY_REQUEST: 'GET_COMPANY_REQUEST',
    GET_COMPANY_REQUEST_SUCCESS: 'GET_COMPANY_REQUEST_SUCCESS',
    GET_COMPANY_REQUEST_FAILURE: 'GET_COMPANY_REQUEST_FAILURE',

    ADD_COMPANY_REQUEST: 'ADD_COMPANY_REQUEST',
    ADD_COMPANY_REQUEST_SUCCESS: 'ADD_COMPANY_REQUEST_SUCCESS',
    ADD_COMPANY_REQUEST_FAILURE: 'ADD_COMPANY_REQUEST_FAILURE',

    EDIT_COMPANY_REQUEST: 'EDIT_COMPANY_REQUEST',
    EDIT_COMPANY_REQUEST_SUCCESS: 'EDIT_COMPANY_REQUEST_SUCCESS',
    EDIT_COMPANY_REQUEST_FAILURE: 'EDIT_COMPANY_REQUEST_FAILURE',

    DELETE_COMPANY_REQUEST: 'DELETE_COMPANY_REQUEST',
    DELETE_COMPANY_REQUEST_SUCCESS: 'DELETE_COMPANY_REQUEST_SUCCESS',
    DELETE_COMPANY_REQUEST_FAILURE: 'DELETE_COMPANY_REQUEST_FAILURE',

    GET_COMPANY_DETIALS_REQUEST: 'GET_COMPANY_DETIALS_REQUEST',
    GET_COMPANY_DETIALS_REQUEST_SUCCESS: 'GET_COMPANY_DETIALS_REQUEST_SUCCESS',
    GET_COMPANY_DETIALS_REQUEST_FAILURE: 'GET_COMPANY_DETIALS_REQUEST_FAILURE',


    GET_USERS_REQUEST: 'GET_USERS_REQUEST',
    GET_USERS_REQUEST_SUCCESS: 'GET_USERS_REQUEST_SUCCESS',
    GET_USERS_REQUEST_FAILURE: 'GET_USERS_REQUEST_FAILURE',

    ADD_USER_REQUEST: 'ADD_USER_REQUEST',
    ADD_USER_REQUEST_SUCCESS: 'ADD_USER_REQUEST_SUCCESS',
    ADD_USER_REQUEST_FAILURE: 'ADD_USER_REQUEST_FAILURE',

    EDIT_USER_REQUEST: 'EDIT_USER_REQUEST',
    EDIT_USER_REQUEST_SUCCESS: 'EDIT_USER_REQUEST_SUCCESS',
    EDIT_USER_REQUEST_FAILURE: 'EDIT_USER_REQUEST_FAILURE',

    DELETE_USER_REQUEST: 'DELETE_USER_REQUEST',
    DELETE_USER_REQUEST_SUCCESS: 'DELETE_USER_REQUEST_SUCCESS',
    DELETE_USER_REQUEST_FAILURE: 'DELETE_USER_REQUEST_FAILURE',

    GET_USER_DETAIL_REQUEST: 'GET_USER_DETAIL_REQUEST',
    GET_USER_DETAIL_REQUEST_SUCCESS: 'GET_USER_DETAIL_REQUEST_SUCCESS',
    GET_USER_DETAIL_REQUEST_FAILURE: 'GET_USER_DETAIL_REQUEST_FAILURE',


    ASSIGN_SITES_REQUEST: 'ASSIGN_SITES_REQUEST',
    ASSIGN_SITES_REQUEST_SUCCESS: 'ASSIGN_SITES_REQUEST_SUCCESS',
    ASSIGN_SITES_REQUEST_FAILURE: 'ASSIGN_SITES_REQUEST_FAILURE',

    ASSIGNED_SITES_REQUEST: 'ASSIGNED_SITES_REQUEST',
    ASSIGNED_SITES_REQUEST_SUCCESS: 'ASSIGNED_SITES_REQUEST_SUCCESS',
    ASSIGNED_SITES_REQUEST_FAILURE: 'ASSIGNED_SITES_REQUEST_FAILURE',

    REPORT_HOURLY_REQUEST: 'REPORT_HOURLY_REQUEST',
    REPORT_HOURLY_REQUEST_SUCCESS: 'REPORT_HOURLY_REQUEST_SUCCESS',
    REPORT_HOURLY_REQUEST_FAILURE: 'REPORT_HOURLY_REQUEST_FAILURE',

    SITE_EXTRA_PARAMETER_REQUEST: 'SITE_EXTRA_PARAMETER_REQUEST',
    SITE_EXTRA_PARAMETER_SUCCESS: 'SITE_EXTRA_PARAMETER_SUCCESS',
    SITE_EXTRA_PARAMETER_FAILURE: 'SITE_EXTRA_PARAMETER_FAILURE',

    GET_SITE_EXTRA_PARAMETER_REQUEST: 'GET_SITE_EXTRA_PARAMETER_REQUEST',
    GET_SITE_EXTRA_PARAMETER_SUCCESS: 'GET_SITE_EXTRA_PARAMETER_SUCCESS',
    GET_SITE_EXTRA_PARAMETER_FAILURE: 'GET_SITE_EXTRA_PARAMETER_FAILURE',

    GET_MODULES: 'GET_MODULES',
    GET_MODULES_SUCCESS: 'GET_MODULES_SUCCESS',
    GET_MODULES_FAILURE: 'GET_MODULES_FAILURE',

    GET_ROLES: 'GET_ROLES',
    GET_ROLES_SUCCESS: 'GET_ROLES_SUCCESS',
    GET_ROLES_FAILURE: 'GET_ROLES_FAILURE',

    ADD_ROLES: 'ADD_ROLES',
    ADD_ROLES_SUCCESS: 'ADD_ROLES_SUCCESS',
    ADD_ROLES_FAILURE: 'ADD_ROLES_FAILURE',

    GET_ROLE_DETAILS: 'GET_ROLE_DETAILS',
    GET_ROLE_DETAILS_SUCCESS: 'GET_ROLE_DETAILS_SUCCESS',
    GET_ROLE_DETAILS_FAILURE: 'GET_ROLE_DETAILS_FAILURE',

    EDIT_ROLES: 'EDIT_ROLES',
    EDIT_ROLES_SUCCESS: 'EDIT_ROLES_SUCCESS',
    EDIT_ROLES_FAILURE: 'EDIT_ROLES_FAILURE',

    GET_DASHBOARD_LPCD_GRAPH_REQUEST: 'GET_DASHBOARD_LPCD_GRAPH_REQUEST',
    GET_DASHBOARD_LPCD_GRAPH_REQUEST_SUCCESS: 'GET_DASHBOARD_LPCD_GRAPH_REQUEST_SUCCESS',
    GET_DASHBOARD_LPCD_GRAPH_REQUEST_FAILURE: 'GET_DASHBOARD_LPCD_GRAPH_REQUEST_FAILURE',

    GET_DASHBOARD_FHTC_GRAPH_REQUEST: 'GET_DASHBOARD_FHTC_GRAPH_REQUEST',
    GET_DASHBOARD_FHTC_GRAPH_REQUEST_SUCCESS: 'GET_DASHBOARD_FHTC_GRAPH_REQUEST_SUCCESS',
    GET_DASHBOARD_FHTC_GRAPH_REQUEST_FAILURE: 'GET_DASHBOARD_FHTC_GRAPH_REQUEST_FAILURE',

    GET_DASHBOARD_WATSUP_GRAPH_REQUEST: 'GET_DASHBOARD_WATSUP_GRAPH_REQUEST',
    GET_DASHBOARD_WATSUP_GRAPH_REQUEST_SUCCESS: 'GET_DASHBOARD_WATSUP_GRAPH_REQUEST_SUCCESS',
    GET_DASHBOARD_WATSUP_GRAPH_REQUEST_FAILURE: 'GET_DASHBOARD_WATSUP_GRAPH_REQUEST_FAILURE',

    GET_ASSET_REPORT_REQUEST: 'GET_ASSET_REPORT_REQUEST',
    GET_ASSET_REPORT_SUCCESS: 'GET_ASSET_REPORT_SUCCESS',
    GET_ASSET_REPORT_FAILURE: 'GET_ASSET_REPORT_FAILURE',

    GET_SITE_PARAMETER_REPORT_REQUEST: 'GET_SITE_PARAMETER_REPORT_REQUEST',
    GET_SITE_PARAMETER_REPORT_SUCCESS: 'GET_SITE_PARAMETER_REPORT_SUCCESS',
    GET_SITE_PARAMETER_REPORT_FAILURE: 'GET_SITE_PARAMETER_REPORT_FAILURE',

    UPDATE_SITE_EXTRA_PARAMETER_REQUEST: 'UPDATE_SITE_EXTRA_PARAMETER_REQUEST',
    UPDATE_SITE_EXTRA_PARAMETER_SUCCESS: 'UPDATE_SITE_EXTRA_PARAMETER_SUCCESS',
    UPDATE_SITE_EXTRA_PARAMETER_FAILURE: 'UPDATE_SITE_EXTRA_PARAMETER_FAILURE',

    DELETE_SITE_EXTRA_PARAMETER_REQUEST: 'DELETE_SITE_EXTRA_PARAMETER_REQUEST',
    DELETE_SITE_EXTRA_PARAMETER_SUCCESS: 'DELETE_SITE_EXTRA_PARAMETER_SUCCESS',
    DELETE_SITE_EXTRA_PARAMETER_FAILURE: 'DELETE_SITE_EXTRA_PARAMETER_FAILURE',

    GET_PUMP_ACTIVITY_REQUEST: 'GET_PUMP_ACTIVITY_REQUEST',
    GET_PUMP_ACTIVITY_SUCCESS: 'GET_PUMP_ACTIVITY_SUCCESS',
    GET_PUMP_ACTIVITY_FAILURE: 'GET_PUMP_ACTIVITY_FAILURE',
};

