// import { createSlice } from "@reduxjs/toolkit";
import {types} from '../actions/types';

const initialState = {
  loading: false,
  status:null,
  data:null,
  message:null,
  action:null,
};

const companyReducer=(state = initialState, {type, payload})=>{
  switch (type) {
    case types.GET_COMPANY_REQUEST:
      // console.log('LOGIN_REQUEST_APPSETTINGS');
      return {
        ...state,
        loading: true,
        // isLogin:false,
        action:types.GET_COMPANY_REQUEST
      };
    case types.GET_COMPANY_REQUEST_SUCCESS:
      // console.log('LOGIN_REQUEST_APPSETTINGS_SUCCESS');
      // console.log('LOGIN_REQUEST_APPSETTINGS_SUCCESS-payload',payload);
      return {
        ...state,
        status: payload.status?payload.status:null,
        data: payload.data?payload.data:null,
        message: payload.message?payload.message:null,
        loading: false,
        action:types.GET_COMPANY_REQUEST_SUCCESS
      };
    case types.GET_COMPANY_REQUEST_FAILURE:
      // console.log('LOGIN_REQUEST_APPSETTINGS_FAILURE');
      // console.log('GET_NEWS_REQUEST_FAILURE-payload',payload);
      return {
        ...state,
        status: payload.status?payload.status:null,
        data: payload.data?payload.data:null,
        message: payload.data?payload.data.message:null,    
        token:null,    
        loading: false,
        action:types.GET_COMPANY_REQUEST_FAILURE
      };
    case types.ADD_COMPANY_REQUEST:
      // console.log('LOGIN_REQUEST_APPSETTINGS');
      return {
        ...state,
        loading: true,
        // isLogin:false,
        action:types.ADD_COMPANY_REQUEST
      };
    case types.ADD_COMPANY_REQUEST_SUCCESS:
      // console.log('LOGIN_REQUEST_APPSETTINGS_SUCCESS');
      // console.log('LOGIN_REQUEST_APPSETTINGS_SUCCESS-payload',payload);
      return {
        ...state,
        status: payload.status?payload.status:null,
        data: payload.data?payload.data:null,
        message: payload.message?payload.message:null,
        loading: false,
        action:types.ADD_COMPANY_REQUEST_SUCCESS
      };
    case types.ADD_COMPANY_REQUEST_FAILURE:
      // console.log('LOGIN_REQUEST_APPSETTINGS_FAILURE');
      // console.log('GET_NEWS_REQUEST_FAILURE-payload',payload);
      return {
        ...state,
        status: payload.status?payload.status:null,
        data: payload.data?payload.data:null,
        message: payload.data?payload.data.message:null,    
        token:null,    
        loading: false,
        action:types.ADD_COMPANY_REQUEST_FAILURE
      };  
    case types.EDIT_COMPANY_REQUEST:
      // console.log('LOGIN_REQUEST_APPSETTINGS');
      return {
        ...state,
        loading: true,
        // isLogin:false,
        action:types.EDIT_COMPANY_REQUEST
      };
    case types.EDIT_COMPANY_REQUEST_SUCCESS:
      // console.log('LOGIN_REQUEST_APPSETTINGS_SUCCESS');
      // console.log('LOGIN_REQUEST_APPSETTINGS_SUCCESS-payload',payload);
      return {
        ...state,
        status: payload.status?payload.status:null,
        data: payload.data?payload.data:null,
        message: payload.message?payload.message:null,
        loading: false,
        action:types.EDIT_COMPANY_REQUEST_SUCCESS
      };
    case types.EDIT_COMPANY_REQUEST_FAILURE:
      // console.log('LOGIN_REQUEST_APPSETTINGS_FAILURE');
      // console.log('GET_NEWS_REQUEST_FAILURE-payload',payload);
      return {
        ...state,
        status: payload.status?payload.status:null,
        data: payload.data?payload.data:null,
        message: payload.data?payload.data.message:null,    
        token:null,    
        loading: false,
        action:types.EDIT_COMPANY_REQUEST_FAILURE
      };  
    case types.DELETE_COMPANY_REQUEST:
      // console.log('LOGIN_REQUEST_APPSETTINGS');
      return {
        ...state,
        loading: true,
        // isLogin:false,
        action:types.DELETE_COMPANY_REQUEST
      };
    case types.DELETE_COMPANY_REQUEST_SUCCESS:
      // console.log('LOGIN_REQUEST_APPSETTINGS_SUCCESS');
      // console.log('LOGIN_REQUEST_APPSETTINGS_SUCCESS-payload',payload);
      return {
        ...state,
        status: payload.status?payload.status:null,
        data: payload.data?payload.data:null,
        message: payload.message?payload.message:null,
        loading: false,
        action:types.DELETE_COMPANY_REQUEST_SUCCESS
      };
    case types.DELETE_COMPANY_REQUEST_FAILURE:
      // console.log('LOGIN_REQUEST_APPSETTINGS_FAILURE');
      // console.log('GET_NEWS_REQUEST_FAILURE-payload',payload);
      return {
        ...state,
        status: payload.status?payload.status:null,
        data: payload.data?payload.data:null,
        message: payload.data?payload.data.message:null,    
        token:null,    
        loading: false,
        action:types.DELETE_COMPANY_REQUEST_FAILURE
      };
    case types.GET_COMPANY_DETIALS_REQUEST:
      // console.log('LOGIN_REQUEST_APPSETTINGS');
      return {
        ...state,
        loading: true,
        // isLogin:false,
        action:types.GET_COMPANY_DETIALS_REQUEST
      };
    case types.GET_COMPANY_DETIALS_REQUEST_SUCCESS:
      // console.log('LOGIN_REQUEST_APPSETTINGS_SUCCESS');
      // console.log('LOGIN_REQUEST_APPSETTINGS_SUCCESS-payload',payload);
      return {
        ...state,
        status: payload.status?payload.status:null,
        data: payload.data?payload.data:null,
        message: payload.message?payload.message:null,
        loading: false,
        action:types.GET_COMPANY_DETIALS_REQUEST_SUCCESS
      };
    case types.GET_COMPANY_DETIALS_REQUEST_FAILURE:
      // console.log('LOGIN_REQUEST_APPSETTINGS_FAILURE');
      // console.log('GET_NEWS_REQUEST_FAILURE-payload',payload);
      return {
        ...state,
        status: payload.status?payload.status:null,
        data: payload.data?payload.data:null,
        message: payload.data?payload.data.message:null,    
        token:null,    
        loading: false,
        action:types.GET_COMPANY_DETIALS_REQUEST_FAILURE
      };
    default:
      return state;
  }
}

export default companyReducer;