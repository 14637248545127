import React, { useEffect, useState } from 'react';
// import { Link } from "react-router-dom";
// import PropTypes from "prop-types";
import { useNavigate,useLocation, Link } from "react-router-dom";
import {useDispatch, useSelector} from 'react-redux';
import Button from 'react-bootstrap/Button';
import NavBarAdmin from "../../../Components/NavBarAdmin";
import AdminMenu from "../../../Components/AdminMenu";
import {types} from '../../../redux/actions/types';
import {isEmpty, isLoggedInUser, CONSTANTVALUEEXTRAPARAM, sortArrayData, checkPermission} from '../../../utils'
import {addSite,updateSite,siteExtraParameter,updateSiteExtraParameter,clearSiteReducer} from '../../../redux/actions/siteAdminAction'
import { useToast } from '@chakra-ui/react';

const AddFlowmeter = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const siteAdminReducer = useSelector(state => state.siteAdminReducer);
  const [flowMeterMake,setFlowMeterMake] = useState('');
  const [flowMeterModel,setFlowMeterModel] = useState('');
  const [flowMeterFMType,setFlowMeterFMType] = useState('');
  const [flowMeterSize,setFlowMeterSize] = useState('');
  const [flowMeterMaxFlow,setFlowMeterMaxFlow] = useState('');
  const [flowMeterSupplyto,setFlowMeterSupplyto] = useState('');
  const [flowMeterSerialNo,setFlowMeterSerialNo] = useState('');
  const [localFlowMeterId, setLocalFlowMeterId] = useState(null);
  const toast = useToast() 

  useEffect(()=>{
    if(location){
      if(location.state){
        if(location.state.item){
          //console.log('Flowmeter-item',location.state.item);
          setFlowMeterMake(location.state.item.flowMeterMake);
          setFlowMeterModel(location.state.item.flowMeterModel);
          setFlowMeterFMType(location.state.item.flowMeterFMType);
          setFlowMeterSize(location.state.item.flowMeterSize);
          setFlowMeterMaxFlow(location.state.item.flowMeterMaxFlow);
          setLocalFlowMeterId(location.state.item._id)
        }
      }
    }    
  },[])

  useEffect(()=>{
    // console.log('AddSite-userReducer-useEffect',siteAdminReducer);    
    if(siteAdminReducer.action){
      if(siteAdminReducer.action===types.SITE_EXTRA_PARAMETER_SUCCESS){
        if(siteAdminReducer.status==="success"){          
          dispatch(clearSiteReducer());
          navigate('/Admin/Flowmeter', {
            moduleType: CONSTANTVALUEEXTRAPARAM.FLOWMETER
          });
        }else {
          toast({
        title: siteAdminReducer.message,
        status: 'warning',
        duration: 2000,
        isClosable: true,
        position: "top-right",
        positionOptions: {
          right: "0",
          top: "0",
        },
    });
        }
      }
      if(siteAdminReducer.action===types.UPDATE_SITE_EXTRA_PARAMETER_SUCCESS){
        if(siteAdminReducer.status==="success"){          
          //navigate('/Admin/Pump');          
          dispatch(clearSiteReducer());   
          navigate('/Admin/Flowmeter', {
            moduleType: CONSTANTVALUEEXTRAPARAM.FLOWMETER
          });
        }else {
          toast({
        title: siteAdminReducer.message,
        status: 'warning',
        duration: 2000,
        isClosable: true,
        position: "top-right",
        positionOptions: {
          right: "0",
          top: "0",
        },
    });
        }
      }
    }
  },[siteAdminReducer.action])

  const handleSiteInput=(e)=>{
    //////////////// Flow Meter Popup ////////////////////////////////////
    if(e.target.name==="txtFlowMeterMake"){
      setFlowMeterMake(e.target.value);
    }
    if(e.target.name==="txtFlowMeterModel"){
      setFlowMeterModel(e.target.value);
    }
    if(e.target.name==="txtFlowMeterFMType"){
      setFlowMeterFMType(e.target.value);
    }
    if(e.target.name==="txtFlowMeterSize"){
      setFlowMeterSize(e.target.value);
    }
    if(e.target.name==="txtFlowMeterMaxFlow"){
      setFlowMeterMaxFlow(e.target.value);
    }
    if(e.target.name==="txtFlowMeterSupplyto"){
      setFlowMeterSupplyto(e.target.value);
    }
    if(e.target.name==="txtFlowMeterSerialNo"){
      setFlowMeterSerialNo(e.target.value);
    }
    ////////////////////////////////////////////////////////////////
  }
  const handleFlowMeterSubmit=()=>{
    if(isEmpty(flowMeterMake)){
      toast({
        title: 'Please select make',
        status: 'warning',
        duration: 2000,
        isClosable: true,
        position: "top-right",
        positionOptions: {
          right: "0",
          top: "0",
        },
    })
      return;
    }else if(isEmpty(flowMeterModel)){
      toast({
        title: 'Please select model',
        status: 'warning',
        duration: 2000,
        isClosable: true,
        position: "top-right",
        positionOptions: {
          right: "0",
          top: "0",
        },
    })
      return;
    }else if(isEmpty(flowMeterFMType)){
      toast({
        title: 'Please select FM type',
        status: 'warning',
        duration: 2000,
        isClosable: true,
        position: "top-right",
        positionOptions: {
          right: "0",
          top: "0",
        },
    })
      return;
    } else if(isEmpty(flowMeterSize)){
      toast({
        title: 'Please select size',
        status: 'warning',
        duration: 2000,
        isClosable: true,
        position: "top-right",
        positionOptions: {
          right: "0",
          top: "0",
        },
    })
      return;
    }else if(isEmpty(flowMeterMaxFlow)){
      toast({
        title: 'Please select max value',
        status: 'warning',
        duration: 2000,
        isClosable: true,
        position: "top-right",
        positionOptions: {
          right: "0",
          top: "0",
        },
    })
      return;
    }
    else{
      if(localFlowMeterId){
        const request={
          "id": localFlowMeterId,
          "type":CONSTANTVALUEEXTRAPARAM.FLOWMETER,
          "flowMeterMake":flowMeterMake,
          "flowMeterModel":flowMeterModel,
          "flowMeterFMType":flowMeterFMType,
          "flowMeterSize":flowMeterSize,
          "flowMeterMaxFlow":flowMeterMaxFlow,
        };
        // setCurrentField(CONSTANTVALUEEXTRAPARAM.FLOWMETER);
        dispatch(updateSiteExtraParameter(request));
        // handleFlowMeterShow();
      }
      else{
        const request={
          "type":CONSTANTVALUEEXTRAPARAM.FLOWMETER,
          "flowMeterMake":flowMeterMake,
          "flowMeterModel":flowMeterModel,
          "flowMeterFMType":flowMeterFMType,
          "flowMeterSize":flowMeterSize,
          "flowMeterMaxFlow":flowMeterMaxFlow,
        };
        // setCurrentField(CONSTANTVALUEEXTRAPARAM.FLOWMETER);
        dispatch(siteExtraParameter(request));
        // handleFlowMeterShow();
      } 
    }    
  }
    return (
      <div className="page">
      <NavBarAdmin />
      <AdminMenu selMenu="AddFlowmeter" />
      {/* <Header moduleType={moduleType} /> */}
      <section>     
        <div className="container"> 
          <div className="container"> 
            <div className="row d-flex justify-content-center pt-4 h-100">
              <div className="card text-black" style={{ borderRadius: "25px" }}>
                <div className="card-body pt-5">
                  <div className="row justify-content-center">                  
                    <div className="col-12 order-2 order-lg-1"> 
                      <p className="h1 fw-bold mb-4 ">{localFlowMeterId?"Update Flow Meter":"Add Flow Meter"}</p>
                      <div className="d-flex flex-row mb-3">
                        <div className="form-outline flex-fill mb-0">
                          <label for="flowMeterMake" className="col-sm-4 col-form-label">Make</label>
                          <input type="text" name="txtFlowMeterMake" id="txtFlowMeterMake" value={flowMeterMake} onChange={(event) => handleSiteInput(event)} className="form-control" />
                        </div>
                      </div>
                      <div className="d-flex flex-row mb-3">
                        <div className="form-outline flex-fill mb-0">
                          <label for="flowMeterModel" className="col-sm-4 col-form-label">Model</label>
                          <input type="text" name="txtFlowMeterModel" id="txtFlowMeterModel" value={flowMeterModel} onChange={(event) => handleSiteInput(event)} className="form-control" />
                        </div>
                      </div>
                      <div className="d-flex flex-row mb-3">
                        <div className="form-outline flex-fill mb-0">
                          <label for="flowMeterFMType" className="col-sm-4 col-form-label">FM Type</label>
                          <input type="text" name="txtFlowMeterFMType" id="txtFlowMeterFMType" value={flowMeterFMType} onChange={(event) => handleSiteInput(event)} className="form-control" />
                        </div>
                      </div>
                      <div className="d-flex flex-row mb-3">
                        <div className="form-outline flex-fill mb-0">
                          <label for="flowMeterSize" className="col-sm-4 col-form-label">Size</label>
                          <input type="text" name="txtFlowMeterSize" id="txtFlowMeterSize" value={flowMeterSize} onChange={(event) => handleSiteInput(event)} className="form-control" />
                        </div>
                      </div>
                      <div className="d-flex flex-row mb-3">
                        <div className="form-outline flex-fill mb-0">
                          <label for="flowMeterMaxFlow" className="col-sm-4 col-form-label">Max Flow</label>
                          <input type="text" name="txtFlowMeterMaxFlow" id="txtFlowMeterMaxFlow" value={flowMeterMaxFlow} onChange={(event) => handleSiteInput(event)} className="form-control" />
                        </div>
                      </div>
                      <div className="d-flex justify-content-center mb-3">
                        <Button variant="primary" onClick={()=>handleFlowMeterSubmit()}>Submit</Button>
                      </div>                    
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    )
};
export default AddFlowmeter;
