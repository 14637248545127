import React, { useEffect, useState } from 'react';
import { useNavigate,useLocation, Link } from "react-router-dom";
import {useDispatch, useSelector} from 'react-redux';
import {types} from '../../redux/actions/types';
import ButtonWithSpinner from "../../Components/ButtonWithSpinner";
import NavBarAdmin from "../../Components/NavBarAdmin";
import AdminMenu from "../../Components/AdminMenu";
import {isEmpty, isLoggedInUser} from '../../utils'
import {getModules,addRoles,getRoleDetails,updateRole} from '../../redux/actions/userAdminAction'
import { useToast } from '@chakra-ui/react';

let selModules=[];
const AddUser = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [roleName, setRoleName] = useState('');
  const [roleDesc, setRoleDesc] = useState('');
  const [listModule, setListModule] = useState([]);
  const [permissionList, setPermissionList] = useState([]);
  const [localRoleId, setLocalRoleId] = useState(null); 
  const userAdminReducer = useSelector(state => state.userAdminReducer);
  const location = useLocation();
  const toast = useToast()

  useEffect(()=>{
    // setIsLoading(true);
    // console.log('LogIn-useEffect');    
    // chkLogin(); 
    fetchModuleList();
    if(location){
      if(location.state){
        if(location.state.roleId){
          setLocalRoleId(location.state.roleId);
          fetchRoleDetails();          
        }
      }
    }    
  },[])
  useEffect(()=>{
    // console.log('AddUser-userReducer-useEffect',userAdminReducer);    
    if(userAdminReducer.action){
      if(userAdminReducer.action===types.GET_MODULES_SUCCESS){
        // console.log('LoginScreen-userReducer-useEffect',userReducer)        
        if(userAdminReducer.status==="success"){
          setListModule(userAdminReducer.data);
          // storeData(CONSTANTVALUE.USEROBJECT,userReducer.data);
          // storeData(CONSTANTVALUE.USERTOKEN,userReducer.token);
          // navigate('/Admin/Users');
        }else {
          toast({
        title: userAdminReducer.message,
        status: 'warning',
        duration: 2000,
        isClosable: true,
        position: "top-right",
        positionOptions: {
          right: "0",
          top: "0",
        },
    });
        }
      }
      else if(userAdminReducer.action===types.GET_ROLE_DETAILS_SUCCESS){
        //console.log('GET_ROLE_DETAILS_SUCCESS-useEffect',userAdminReducer)        
        if(userAdminReducer.status==="success"){
          setRoleDesc(userAdminReducer.data.details);
          setRoleName(userAdminReducer.data.role);
          setPermissionList(userAdminReducer.data.permission);
          userAdminReducer.data.permission.map((item)=>{
            if(item.create){
              const objModuleC={
                "action":"create",
                "menu":item.moduleName
              }
              selModules.push(objModuleC);
            }            
            if(item.view){
              const objModuleR={
                "action":"view",
                "menu":item.moduleName
              }
              selModules.push(objModuleR);
            }
            if(item.edit){
              const objModuleU={
                "action":"edit",
                "menu":item.moduleName
              }
              selModules.push(objModuleU);
            }
            if(item.delete){
              const objModuleD={
                "action":"delete",
                "menu":item.moduleName
              }
              selModules.push(objModuleD);
            }
          })
          // console.log('GET_ROLE_DETAILS_SUCCESS-selModules : ',selModules);
          // storeData(CONSTANTVALUE.USEROBJECT,userReducer.data);
          // storeData(CONSTANTVALUE.USERTOKEN,userReducer.token);
          // navigate('/Admin/Users');
        }else {
          toast({
        title: userAdminReducer.message,
        status: 'warning',
        duration: 2000,
        isClosable: true,
        position: "top-right",
        positionOptions: {
          right: "0",
          top: "0",
        },
    });
        }
      }
    }
  },[userAdminReducer.action])
  const submitUser=()=>{   
    if (isEmpty(roleName)) {
      toast({
        title: 'Please enter role name',
        status: 'warning',
        duration: 2000,
        isClosable: true,
        position: "top-right",
        positionOptions: {
          right: "0",
          top: "0",
        },
      });
      return;
    } else if (selModules.length === 0) {
      toast({
        title: 'Please select module permission',
        status: 'warning',
        duration: 2000,
        isClosable: true,
        position: "top-right",
        positionOptions: {
          right: "0",
          top: "0",
        },
      });
      return;
    }    
    else{
      if(localRoleId){
        let permissionList=[];
        // const request="";
        listModule.map((item,index)=>{
          // console.log('item.name',item.name);        
          const modules=selModules.filter((modul,index)=>modul.menu==item.name);
          // console.log('modules',modules);
          let actionParam='';
          let isCreate=false;
          let isEdit=false;
          let isView=false;
          let isDelete=false;
          let isListing=false;
          if(modules[0])
          {
            if(modules[0].action=='create')
              isCreate=true;
            if(modules[0].action=='edit')
              isEdit=true;
            if(modules[0].action=='view')
              isView=true;
            if(modules[0].action=='delete')
              isDelete=true;
          }
          if(modules[1])
          {
            if(modules[1].action=='create')
              isCreate=true;
            if(modules[1].action=='edit')
              isEdit=true;
            if(modules[1].action=='view')
              isView=true;
            if(modules[1].action=='delete')
              isDelete=true;
          }
          if(modules[2])
          {
            if(modules[2].action=='create')
              isCreate=true;
            if(modules[2].action=='edit')
              isEdit=true;
            if(modules[2].action=='view')
              isView=true;
            if(modules[2].action=='delete')
              isDelete=true;
          }
          if(modules[3])
          {
            if(modules[3].action=='create')
              isCreate=true;
            if(modules[3].action=='edit')
              isEdit=true;
            if(modules[3].action=='view')
              isView=true;
            if(modules[3].action=='delete')
              isDelete=true;
          }
          let permission={'moduleName':item.name.trim(),create:isCreate,edit:isEdit,view:isView,delete:isDelete,listing:isListing};
          permissionList.push(permission);
        })
        const requestObj={          
          "role":roleName,
          "details":roleDesc,
          "permission":permissionList
        };
        const request={
          "request":requestObj,
          "id":localRoleId
        }
        //console.log('request',request);
        dispatch(updateRole(request)); 
      }
      else{
        let permissionList=[];
        // const request="";
        listModule.map((item,index)=>{
          // console.log('item.name',item.name);        
          const modules=selModules.filter((modul,index)=>modul.menu==item.name);
          // console.log('modules',modules);
          let actionParam='';
          let isCreate=false;
          let isEdit=false;
          let isView=false;
          let isDelete=false;
          let isListing=false;
          if(modules[0])
          {
            if(modules[0].action=='create')
              isCreate=true;
            if(modules[0].action=='edit')
              isEdit=true;
            if(modules[0].action=='view')
              isView=true;
            if(modules[0].action=='delete')
              isDelete=true;
          }
          if(modules[1])
          {
            if(modules[1].action=='create')
              isCreate=true;
            if(modules[1].action=='edit')
              isEdit=true;
            if(modules[1].action=='view')
              isView=true;
            if(modules[1].action=='delete')
              isDelete=true;
          }
          if(modules[2])
          {
            if(modules[2].action=='create')
              isCreate=true;
            if(modules[2].action=='edit')
              isEdit=true;
            if(modules[2].action=='view')
              isView=true;
            if(modules[2].action=='delete')
              isDelete=true;
          }
          if(modules[3])
          {
            if(modules[3].action=='create')
              isCreate=true;
            if(modules[3].action=='edit')
              isEdit=true;
            if(modules[3].action=='view')
              isView=true;
            if(modules[3].action=='delete')
              isDelete=true;
          }
          let permission={'moduleName':item.name.trim(),create:isCreate,edit:isEdit,view:isView,delete:isDelete,listing:isListing};
          permissionList.push(permission);
        })
        const request={
          "role":roleName,
          "details":roleDesc,
          "permission":permissionList
        };
        //console.log('request',request);
        dispatch(addRoles(request));
      }    
    }
    navigate('/Admin/Roles');
  }
  const handleRoleInput=(e)=>{
    //console.log('AddRoles-handleProjectInput-name-',e.target.name+"-value-"+e.target.value);
    if(e.target.name==="txtRoleName"){
      setRoleName(e.target.value);
    }
    if(e.target.name==="txtDesc"){
      setRoleDesc(e.target.value);
    }
  }
  const handleMenuActions=(e)=>{
    //console.log('AddRoles-handleMenuActions-name : ',e.target.name+" : value : "+e.target.value+" : default value : "+e.target.defaultChecked+" : checked value : "+e.target.checked);    
    const objModule={
      "action":e.target.name,
      "menu":e.target.value
    }
    //console.log('AddRoles-handleMenuActions-objModule : ',objModule);
    const findModuleIndex=selModules.findIndex((item)=>(item.action==e.target.name && item.menu==e.target.value));
    //console.log('AddRoles-handleMenuActions-findModuleIndex : ',findModuleIndex);
    if(findModuleIndex>-1){
      selModules.splice(findModuleIndex,1);
      ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
      //////// Logic to check if view permission is removed then need to remove create, edit, delete also /////////////////
      ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
      if(e.target.name=="view"){        
        const findModuleIndexC=selModules.findIndex((item)=>(item.action=="create" && item.menu==e.target.value));
        // console.log('HandleMenuActions-findModuleIndexC : ',findModuleIndexC);
        if(findModuleIndexC>-1){
          selModules.splice(findModuleIndexC,1);
        }

        const findModuleIndexE=selModules.findIndex((item)=>(item.action=="edit" && item.menu==e.target.value));
        // console.log('HandleMenuActions-findModuleIndexE : ',findModuleIndexE);
        if(findModuleIndexE>-1){
          selModules.splice(findModuleIndexE,1);
        }

        const findModuleIndexD=selModules.findIndex((item)=>(item.action=="delete" && item.menu==e.target.value));
        // console.log('HandleMenuActions-findModuleIndexD : ',findModuleIndexD);
        if(findModuleIndexD>-1){
          selModules.splice(findModuleIndexD,1);
        }
      }
      ////////////////////////////////////////////////////////////////////////////
      ////////////////////////////////////////////////////////////////////////////
      // selModules.splice(findModuleIndex,1);
      // console.log('handleMenuActions-findModuleIndex : ',findModuleIndex);
      // console.log('handleMenuActions-selModules : ',selModules);
    }
    else{
      ////////////////////////////////////////////////////////////////////////////
      //////// Logic to check if view permission is exist or not /////////////////
      ////////////////////////////////////////////////////////////////////////////
      if(e.target.name=="create" || e.target.name=="edit" || e.target.name=="delete"){
        const objModuleView={
          "action":"view",
          "menu":e.target.value
        }
        const findModuleIndex=selModules.findIndex((item)=>(item.action=="view" && item.menu==e.target.value));
        if(findModuleIndex==-1){
          selModules.push(objModuleView);
        }
      }
      ////////////////////////////////////////////////////////////////////////////
      ////////////////////////////////////////////////////////////////////////////
      selModules.push(objModule);      
    }
    console.log('AddRoles-handleMenuActions-permissionList-1 : ',permissionList);
    setPermissionList([]);
    console.log('AddRoles-handleMenuActions-selModules : ',selModules);
    console.log('AddRoles-handleMenuActions-permissionList-1 : ',permissionList);
    let permisionLocalList=[];
    listModule.map((item,index)=>{
      // console.log('item.name',item.name);        
      const modules=selModules.filter((modul,index)=>modul.menu==item.name);
      // console.log('modules',modules);
      // let actionParam='';
      let isCreate=false;
      let isEdit=false;
      let isView=false;
      let isDelete=false;
      let isListing=false;
      if(modules[0])
      {
        if(modules[0].action=='create')
          isCreate=true;
        if(modules[0].action=='edit')
          isEdit=true;
        if(modules[0].action=='view')
          isView=true;
        if(modules[0].action=='delete')
          isDelete=true;
      }
      if(modules[1])
      {
        if(modules[1].action=='create')
          isCreate=true;
        if(modules[1].action=='edit')
          isEdit=true;
        if(modules[1].action=='view')
          isView=true;
        if(modules[1].action=='delete')
          isDelete=true;
      }
      if(modules[2])
      {
        if(modules[2].action=='create')
          isCreate=true;
        if(modules[2].action=='edit')
          isEdit=true;
        if(modules[2].action=='view')
          isView=true;
        if(modules[2].action=='delete')
          isDelete=true;
      }
      if(modules[3])
      {
        if(modules[3].action=='create')
          isCreate=true;
        if(modules[3].action=='edit')
          isEdit=true;
        if(modules[3].action=='view')
          isView=true;
        if(modules[3].action=='delete')
          isDelete=true;
      }
      let permission={'moduleName':item.name.trim(),create:isCreate,edit:isEdit,view:isView,delete:isDelete,listing:isListing};
      permisionLocalList.push(permission);
    })
    setPermissionList(permisionLocalList);
    console.log('AddRoles-handleMenuActions-selModules : ',selModules);
    console.log('AddRoles-handleMenuActions-permissionList-2 : ',permissionList);
  }
  const fetchRoleDetails=()=>{
    //console.log('fetchRoleDetails-roleId=',location.state.roleId);
    dispatch(getRoleDetails(location.state.roleId));
  }
  const fetchModuleList=async()=>{
    dispatch(getModules());
  } 
  const bindMenus=()=>{
    // console.log('bindMenus-listModule',listModule);
    // console.log('bindMenus-permissionList',permissionList);
    return listModule.map((item,index)=>{
      const permission = permissionList.filter(obj=>obj.moduleName==item.name);
      // console.log('bindMenus-permission',permission);
      // console.log('bindMenus-item.name',item.name);
      // console.log('bindMenus-permission[0]?.[create]',permission[0]?.['create']);
      // console.log('bindMenus-permission[0]?.[view]',permission[0]?.['view']);
      // console.log('bindMenus-permission[0]?.[view]',permission[0]?.['edit']);
      // console.log('bindMenus-permission[0]?.[view]',permission[0]?.['delete']);
      return (
        <tr key={index}>                                
          <td className="mdl-data-table__cell--non-numeric table_cl">{item.name}</td>
          <td>
            <input className="form-check-input" type="checkbox" id={item._id} name="create" value={item.name} checked={permission[0]?.['create']} onClick={handleMenuActions}/>
          </td>
          <td>
            <input className="form-check-input" type="checkbox" id={item._id} name="view" value={item.name} defaultChecked={permission[0]?.['view']} checked={permission[0]?.['view']} onClick={handleMenuActions}/>
          </td>
          <td>
            <input className="form-check-input" type="checkbox" id={item._id} name="edit" value={item.name} checked={permission[0]?.['edit']} onClick={handleMenuActions}/>
          </td>
          <td>
            <input className="form-check-input" type="checkbox" id={item._id} name="delete" value={item.name} checked={permission[0]?.['delete']} onClick={handleMenuActions}/>
          </td>
        </tr>
      )
    })
  }
  return (
    <div className="page">
      <NavBarAdmin />
      <AdminMenu selMenu="Roles" />
        <section>      
        <div className="container">        
            <div className="row d-flex justify-content-center pt-4 h-100">
            {/* <div className="col-md-8 col-lg-8 col-xl-6"> */}
                <div className="card text-black" style={{ borderRadius: "25px" }}>
                <div className="card-body pt-5">
                    <div className="row justify-content-center">                  
                    <div className="col-12 order-2 order-lg-1">  
                        <p className="h1 fw-bold mb-4 ">{localRoleId?"Update Role":"Add Role"}</p>
                        <form className="mx-1 mx-md-2">
                        <div className="d-flex flex-row mb-3">
                            {/* <i className="fas fa-envelope fa-lg me-3 fa-fw"></i> */}
                            <div className="form-outline flex-fill mb-0">
                                <label className="form-label" for="form3Example3c">Role Name</label>
                                <input type="text" name="txtRoleName" id="txtRoleName" value={roleName} onChange={(event) => handleRoleInput(event)} className="form-control" />
                            </div>
                        </div>
                        <div className="d-flex flex-row mb-3">
                            {/* <i className="fas fa-envelope fa-lg me-3 fa-fw"></i> */}
                            <div className="form-outline flex-fill mb-0">
                                <label className="form-label" for="form3Example3c">Description</label>
                                <input type="text" name="txtDesc" id="txtDesc" value={roleDesc} onChange={(event) => handleRoleInput(event)} className="form-control" />
                            </div>
                        </div>
                        <div className="d-flex flex-row mb-3">
                          <div className="form-outline flex-fill mb-0">
                            <table className="table">
                              <thead>
                                <tr>                                
                                  <th className="table_cl">Module</th>
                                  <th>Create</th>
                                  <th>View</th>
                                  <th>Update</th>
                                  <th>Delete</th>
                                </tr>
                              </thead>
                              <tbody>{bindMenus()}</tbody>
                            </table>
                          </div>
                        </div>
                        <div className="d-flex justify-content-center mb-3">
                            {/* <button type="button" className="btn btn-primary btn-lg" disabled={isLoading} onClick={doSignin}>{userReducer.loading?<LoadingSpinner />:"Submit"}</button> */}
                            <Link className="btn btn-danger btn-lg btnMarginRight" to={"/Admin/Roles"}>Back</Link>
                            <ButtonWithSpinner onClick={()=>submitUser()} isLoading={userAdminReducer.loading?true:false} label={'Save'} />                            
                        </div>
                      </form>                        
                    </div>                  
                    </div>
                </div>
                </div>
            {/* </div> */}
            </div>
        </div>
        </section>
    </div>
  );
};

export default AddUser;
