import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation, Link } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { types } from '../../redux/actions/types';
import ButtonWithSpinner from "../../Components/ButtonWithSpinner";
import NavBarAdmin from "../../Components/NavBarAdmin";
import AdminMenu from "../../Components/AdminMenu";
import { getUsers, assignSitesToUser, getAssignedSitesToUser } from '../../redux/actions/userAdminAction'
import { projectList } from '../../redux/actions/projectAdminAction'
import { siteList, deleteSite } from '../../redux/actions/siteAdminAction'
import { isEmpty, isLoggedInUser, checkPermission } from '../../utils'
import { useToast } from '@chakra-ui/react';
// import {getCompany} from '../../redux/actions/companyAction'
// import {AddUser,updateProject,getProjectById} from '../../redux/actions/projectAdminAction'
// import {getSiteDetails} from '../../redux/actions/siteAction'
// import mainLogo from "../../assets/images/aqualogix-logo-b.png";
// import harGharJalLogo from "../../assets/images/hargharjal-logo-b.png";

const AssignSites = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [listUser, setListUser] = useState([]);
  const [listProjects, setListProjects] = useState([]);
  const [listSite, setListSite] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedProject, setSelectedProject] = useState(null);
  const [selectedSites, setSelectedSites] = useState([]);

  /////////////////////////////////////////////////////////////////
  /////Parameters for getting permission value/////////////////////
  /////////////////////////////////////////////////////////////////
  const [isCreate, setIsCreate] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [isView, setIsView] = useState(false);

  const userAdminReducer = useSelector(state => state.userAdminReducer);
  const projectAdminReducer = useSelector(state => state.projectAdminReducer);
  const siteAdminReducer = useSelector(state => state.siteAdminReducer);
  const location = useLocation();
  useEffect(() => {
    chkLogin();
    setPermission();
  }, [])
  useEffect(() => {
    if (isView)
      fetchUserList();
  }, [isView])
  useEffect(() => {
    console.log('AssignSites-userAdminReducer-', userAdminReducer);
    if (userAdminReducer.action) {
      if (userAdminReducer.action === types.GET_USERS_REQUEST_SUCCESS) {
        if (userAdminReducer.status === "success") {
          setListUser(userAdminReducer.data.list);
        } else {
          //       toast({
          //     title: userAdminReducer.message,
          //     status: 'warning',
          //     duration: 2000,
          //     isClosable: true,
          //     position: "top-right",
          //     positionOptions: {
          //       right: "0",
          //       top: "0",
          //     },
          // });
          console.log("error:", userAdminReducer.message)
        }
      } else if (userAdminReducer.action === types.ASSIGN_SITES_REQUEST_SUCCESS) {
        console.log('LoginScreen-userReducer-useEffect', userAdminReducer)
        if (userAdminReducer.status === "success") {
          // toast({
          //   title: userAdminReducer.message,
          //   status: 'warning',
          //   duration: 2000,
          //   isClosable: true,
          //   position: "top-right",
          //   positionOptions: {
          //     right: "0",
          //     top: "0",
          //   },
          // });
          console.log("error:",userAdminReducer.message)
        } else {
          // toast({
          //   title: userAdminReducer.message,
          //   status: 'warning',
          //   duration: 2000,
          //   isClosable: true,
          //   position: "top-right",
          //   positionOptions: {
          //     right: "0",
          //     top: "0",
          //   },
          // });
          console.log("error:",userAdminReducer.message)
        }
      } else if (userAdminReducer.action === types.ASSIGNED_SITES_REQUEST_SUCCESS) {
        console.log('userAdminReducer-useEffect', userAdminReducer)
        if (userAdminReducer.status === "success") {
          setSelectedSites(userAdminReducer.data.map((item, index) => {
            if (item.isExist) {
              return item._id;
            }
          }));
        } else {
          // toast({
          //   title: userAdminReducer.message,
          //   status: 'warning',
          //   duration: 2000,
          //   isClosable: true,
          //   position: "top-right",
          //   positionOptions: {
          //     right: "0",
          //     top: "0",
          //   },
          // });
          console.log("error:",userAdminReducer.message)

        }
      }
      else if (userAdminReducer.action === types.ASSIGNED_SITES_REQUEST_FAILURE) {
        console.log('userAdminReducer-useEffect', userAdminReducer)
        // toast({
        //   title: userAdminReducer.message,
        //   status: 'warning',
        //   duration: 2000,
        //   isClosable: true,
        //   position: "top-right",
        //   positionOptions: {
        //     right: "0",
        //     top: "0",
        //   },
        // });
      }
    }
  }, [userAdminReducer.action])
  useEffect(() => {
    if (projectAdminReducer.action) {
      if (projectAdminReducer.action === types.LIST_PROJECT_REQUEST_SUCCESS) {
        console.log('Projects-projectAdminReducer-useEffect', projectAdminReducer)
        if (projectAdminReducer.status === "success") {
          setListProjects(projectAdminReducer.data.list);
        } else {
          // toast({
          //   title: projectAdminReducer.message,
          //   status: 'warning',
          //   duration: 2000,
          //   isClosable: true,
          //   position: "top-right",
          //   positionOptions: {
          //     right: "0",
          //     top: "0",
          //   },
          // })
          console.log("error:",projectAdminReducer.message)
        }
      }
    }
  }, [projectAdminReducer.action])
  useEffect(() => {
    if (siteAdminReducer.action) {
      if (siteAdminReducer.action === types.LIST_SITE_REQUEST_SUCCESS) {
        if (siteAdminReducer.status === "success") {
          const siteList = siteAdminReducer.data.list.filter(item => item.projectID === selectedProject);
          setListSite(siteList);
        } else {
          console.log("error:",siteAdminReducer.message)
          // toast({
          //   title: siteAdminReducer.message,
          //   status: 'warning',
          //   duration: 2000,
          //   isClosable: true,
          //   position: "top-right",
          //   positionOptions: {
          //     right: "0",
          //     top: "0",
          //   },
          // })
        }
      }
    }
  }, [siteAdminReducer.action])
  const chkLogin = async () => {
    const isLoggedIn = await isLoggedInUser();
    if (!isLoggedIn) {
      navigate('/Admin');
    }
  }
  const toast = useToast()
  const setPermission = async () => {
    setIsView(await checkPermission('Assign Sites To User', 'view'));
  }

  const submitAssignSiteToUser = () => {
    if (isEmpty(selectedUser)) {
      toast({
        title: 'Please select user',
        status: 'warning',
        duration: 2000,
        isClosable: true,
        position: "top-right",
        positionOptions: {
          right: "0",
          top: "0",
        },
      })

      return;
    } else if (isEmpty(selectedProject)) {
      toast({
        title: 'Please select project',
        status: 'warning',
        duration: 2000,
        isClosable: true,
        position: "top-right",
        positionOptions: {
          right: "0",
          top: "0",
        },
      })
      return;
    }
    const request = {
      "projectId": selectedProject,
      "siteList": selectedSites,
      "userId": selectedUser
    }
    dispatch(assignSitesToUser(request));
  }
  const handleChangeEvent = (e) => {
    if (e.target.name === "selUser") {
      fetchProjectList();
      setSelectedProject(null);
      setSelectedSites([]);
      setListProjects([]);
      setListSite([]);
      setSelectedUser(e.target.value);
    }
    if (e.target.name === "selProject") {
      setSelectedProject(e.target.value);
      fetchSiteList();
      fetchAssignedSites(selectedUser, e.target.value);
    }
  }
  const fetchUserList = () => {
    dispatch(getUsers());
  }
  const fetchProjectList = async () => {
    dispatch(projectList("page=1&limit=10000"));
  }
  const fetchSiteList = async () => {
    dispatch(siteList("page=1&limit=10000"));
  }
  const fetchAssignedSites = async (selUser, selProject) => {
    const payload = { projectId: selProject, userId: selUser }
    dispatch(getAssignedSitesToUser(payload));
  }
  const handleSitesStatus = (e) => {
    let finalArray = [];
    if (e.target.name === "chkSites") {
      if (e.target.checked) {
        finalArray = [...selectedSites];
        finalArray.push(e.target.value);
      }
      else {
        const index = selectedSites.findIndex((item) => item == e.target.value);
        finalArray = [...selectedSites];
        finalArray.splice(index, 1);
      }
    }
    setSelectedSites(finalArray);
  }
  const handleAllSitesStatus = (e) => {
    let finalArray = [];
    if (e.target.name === "chkSites") {
      if (e.target.checked) {
        listSite.map((item) => {
          finalArray.push(item._id);
        })
      }
      else
        finalArray = [];
    }
    setSelectedSites(finalArray);
  }
  const bindUsers = () => {
    if (listUser) {
      if (listUser.length > 0) {
        return (listUser.map((item, index) => (
          <option key={index} value={item._id}>{item.OperatorName.toString() + "   (" + item.EmploymentType.toString() + " - " + item.MobileNo.toString() + ")"}</option>
        )))
      }
    }
  }
  const bindProjects = () => {
    if (listProjects) {
      if (listProjects.length > 0) {
        return (listProjects.map((item, index) => (
          <option key={index} value={item._id}>{item.ProjectName.toString() + "   (" + item.StateName.toString() + ")"}</option>
        )))
      }
    }
  }
  const bindProjectData = () => {
    if (listProjects) {
      if (listProjects.length > 0) {
        return (<div className="d-flex flex-row mb-3">
          <div className="form-outline flex-fill mb-0">
            <label className="form-label" for="form3Example3c">Project</label>
            <div className="col-sm-10">
              <select className="selectpicker form-control" name="selProject" onChange={(e) => handleChangeEvent(e)}>
                <option value="">Select Project</option>
                {bindProjects()}
              </select>
            </div>
          </div>
        </div>);
      }
    }

  }
  const bindSites = () => {
    if (listSite) {
      if (listSite.length > 0) {
        <div className="form-outline flex-fill mb-3">
          <input className="form-check-input me-2" type="checkbox" value={0} name="chkSites" id="chkSites" onChange={(event) => handleAllSitesStatus(event)} />
          <label className="form-check-label" for="chkSites">Select All</label>
        </div>
        return (listSite.map((item, index) => (<div key={index} className="form-outline flex-fill mb-3">
          <input className="form-check-input me-2" type="checkbox" value={item._id} name="chkSites" checked={(selectedSites.findIndex(itemSite => (itemSite == item._id)) > -1) ? true : false} id="chkSites" onChange={(event) => handleSitesStatus(event)} />
          <label className="form-check-label" for="chkSites">{item.pumpName}<br /><span className='checkboxLbl'>(IMEI {item.IMEI})</span></label>
        </div>)));
      }
    }
  }
  const bindAllSiteCheckbox = () => {
    if (listSite) {
      if (listSite.length > 0) {
        return (<div className="d-flex flex-row mb-3">
          <div className="form-outline flex-fill mb-0">
            <label className="form-label" for="form3Example3c">Sites</label>
            <div className="col-sm-10">
              <div className="form-outline flex-fill mb-3">
                <input className="form-check-input me-2" type="checkbox" value={0} name="chkSites" id="chkSites" onChange={(event) => handleAllSitesStatus(event)} />
                <label className="form-check-label" for="chkSites">Select All</label>
              </div>
              {bindSites()}
            </div>
          </div>
        </div>);
      }
    }
  }
  const bindActionButtons = () => {
    if (listSite) {
      if (listSite.length > 0) {
        return (<div className="d-flex justify-content-center mb-3">
          <Link className="btn btn-danger btn-lg btnMarginRight" to={"/Admin/Users"}>Back</Link>
          <ButtonWithSpinner onClick={() => submitAssignSiteToUser()} isLoading={userAdminReducer.loading ? true : false} label={'Save'} />
        </div>);
      }
    }
  }
  const bindData = () => {
    return (
      <div className="row justify-content-center">
        <div className="col-12 order-2 order-lg-1">
          <p className="h1 fw-bold mb-4 ">Assign Sites To User</p>
          <form className="mx-1 mx-md-2">
            <div className="d-flex flex-row mb-3">
              <div className="form-outline flex-fill mb-0">
                <label className="form-label" for="form3Example3c">User</label>
                <div className="col-sm-10">
                  <select className="selectpicker form-control" name="selUser" onChange={(e) => handleChangeEvent(e)}>
                    <option value="" selected={true}>Select User</option>
                    {bindUsers()}
                  </select>
                </div>
              </div>
            </div>
            {bindProjectData()}
            {bindAllSiteCheckbox()}
            {bindActionButtons()}
          </form>
        </div>
      </div>
    )
  }
  const noViewPermission = () => {
    return (<p class="text-center">You don't have permission to view the data. Please contact to administrator for more details</p>)
  }
  return (
    <div className="page">
      <NavBarAdmin />
      <AdminMenu selMenu="AssignSites" />
      <section>
        <div className="container">
          <div className="row d-flex justify-content-center pt-4 h-100">
            <div className="card text-black" style={{ borderRadius: "25px" }}>
              <div className="card-body pt-5">
                {isView ? bindData() : noViewPermission()}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default AssignSites;
