import React, { useEffect, useState } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";
import { Line } from "react-chartjs-2";
import PropTypes from 'prop-types';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler // Enable gradients and fill effects
);

const cDate = new Date();

let data = null;
const LineChartChlorine = ({ dataList, xLabel, yLabel }) => {
  const [graphStatus, setGraphStatus] = useState(false);

  useEffect(() => {
    let labels = [];
    let countArray = [];

    dataList.map((item) => {
      labels.push(item.x);
      countArray.push(item.y / 10);
    });

    data = {
      labels,
      datasets: [
        {
          label: "Chlorine",
          data: countArray.map((count) => parseFloat(count.toFixed(2))),
          backgroundColor: "rgba(75, 192, 192, 0.2)", // Soft turquoise background fill
          borderColor: "rgba(75, 192, 192, 1)", // Turquoise border
          pointBackgroundColor: "rgba(255, 99, 132, 0.8)", // Vibrant point color
          pointBorderColor: "#fff", // White point border
          pointRadius: 5, // Larger point size for better visibility
          tension: 0.4, // Smooth curve for line
          fill: true, // Gradient fill
        },
      ],
    };

    if (labels.length > 0) setGraphStatus(true);
  }, [dataList]);

  const options = {
    scales: {
      y: {
        title: {
          display: true,
          text: yLabel,
          font: {
            size: 16,
            weight: "bold",
            family: 'Arial, sans-serif',
          },
          color: '#666',
        },
        ticks: {
          color: '#333', // Darker tick labels
        },
        beginAtZero: true, // Ensure y-axis starts at zero
      },
      x: {
        title: {
          display: true,
          text: xLabel,
          font: {
            size: 16,
            weight: "bold",
            family: 'Arial, sans-serif',
          },
          color: '#666',
        },
        ticks: {
          color: '#333',
        },
        min: 0,
        max: cDate.getHours(),
        stepSize: 1,
      },
    },
    responsive: true,
    plugins: {
      legend: {
        position: "top",
        labels: {
          color: '#333', // Darker legend text
          font: {
            family: 'Arial, sans-serif',
            size: 14,
          },
        },
      },
      title: {
      
      },
      tooltip: {
        backgroundColor: 'rgba(0,0,0,0.8)', // Darker tooltip background
        titleColor: '#fff',
        bodyColor: '#fff',
        borderWidth: 1,
        borderColor: '#ccc',
        titleFont: {
          family: 'Arial, sans-serif',
          size: 14,
        },
        bodyFont: {
          family: 'Arial, sans-serif',
          size: 12,
        },
      },
    },
  };

  if (graphStatus) {
    return (
    <div style={{ position: 'relative', height : '270px', width: '100%' }}>
      <Line options={options} data={data} />
    </div>
  )
  }
  return null; // In case no data is available, return nothing
};

LineChartChlorine.propTypes = {
  dataList: PropTypes.array,
  xLabel: PropTypes.string,
  yLabel: PropTypes.string,
};

export default LineChartChlorine;
