import React, { useEffect, useState } from 'react';
import angleLeft from "../../assets/images/down-arrow.svg";
import "./offCanvas.css";
import {getNewsList,clearData} from '../../redux/actions/newsAction'
import {types} from '../../redux/actions/types';
import {useDispatch, useSelector} from 'react-redux';
import PropTypes from "prop-types";
// import { Link } from "react-router-dom";
// import { useDispatch, useSelector } from "react-redux";
// import { rightCanvasToggle } from "../../redux/reducers/dashboardSlice";
// import Button from "react-bootstrap/Button";
// import Offcanvas from "react-bootstrap/Offcanvas";
// style={'font-weight: 300;font-size: 16px;line-height: 20px;color: #F2C94C;margin-bottom: 20px;'}

const OffCanvasRight = ({onItemClick,newsObject}) => {
  const dispatch = useDispatch();
  const newsReducer = useSelector(state => state.newsReducer);
  const [newsList, setNewsList] = useState([]);

  const navRef = React.useRef(null);
  const { isRightCanvas } = useSelector((state) => state.dashboardReducer);

  const onToggleClick = (e) => {
    navRef.current.classList.toggle("show");
  };

  function addActiveClassForArrow() {
    var element = document.getElementById("active_r");
    {
      if (element.classList.contains("active_arrow_right")) {
        element.classList.remove("active_arrow_right");
      } else {
        element.classList.add("active_arrow_right");
      }
    }
  }
  useEffect(()=>{
    fetchNews();
  },[])
  useEffect(()=>{
    // console.log('OffCanvasRight-newsReducer-useEffect',newsReducer);    
    if(newsReducer.action){
      if(newsReducer.action===types.GET_NEWS_REQUEST_SUCCESS){
        // console.log('OffCanvasRight-newsReducer-useEffect',newsReducer)        
        // if(newsReducer.status==="success"){
          if(newsReducer.data){
            if(newsReducer.data.list.length){
              setNewsList(newsReducer.data.list);
            }
          }       
          // console.log('OffCanvasLeft-basicInfo-useEffect',basicInfo); 
        // }else {
        //   alert(newsReducer.message);
        // }
      }
      else if(newsReducer.action===types.GET_NEWS_REQUEST_FAILURE){
        // console.log('OffCanvasRight-newsReducer-useEffect',newsReducer); 
        dispatch(clearData());
        // alert(newsReducer.message);
      }
    }
  },[newsReducer.action])
  const fetchNews=()=>{
    dispatch(getNewsList());
  }
  const itemClick=(item)=>{
    newsObject(item);
  }
  const bindNews=()=>{
    // console.log('newsList',newsList);
    if(newsList){
      if(newsList.length>0){
        return newsList.map((item,index)=><li key={item._id}><a href={item.newsDetails} target='_blank' className='newsLink'>{item.newsTitle}</a></li>);
        // return newsList.map((item,index)=><li key={item._id}>{bindHtml(urlify(item.newsTitle))}</li>);
        // return newsList.map((item,index)=><li key={item._id}><Link className="more-info" data-bs-toggle="modal" data-bs-target="#newsDtls" onClick={()=>itemClick(item)}>{item.newsTitle}</Link></li>);
      }
    }    
  }
  // const bindHtml=(myHTML)=>{
  //   return <div dangerouslySetInnerHTML={{ __html: myHTML }} />
  // }
  // const urlify=(text)=>{
  //   var urlRegex = /(https?:\/\/[^\s]+)/g;
  //   return text.replace(urlRegex, function(url) {
  //     return '<a href="' + url + '" target="_blank" style="font-weight: 300;font-size: 16px;line-height: 20px;color: #F2C94C;margin-bottom: 20px;">' + url + '</a>';
  //   })
  // }
  return (
    <div className="right_canvas_pop_bg" ref={navRef}>
      <div
        className="arrow_right"
        onClick={() => dispatch(onToggleClick(), addActiveClassForArrow())}>
        <img id="active_r" src={angleLeft} alt="<" />
      </div>
      {/* {isRightCanvas ? ( */}
      <div className="right_canvas_body">
        <div className="panel_content d-flex flex-column">
          <h2>Latest News</h2>
          <ul className="news-list scroll">
            {bindNews()}
          </ul>
          {/* <ul className="info-list mt-auto">
            <li>Flow Meter</li>
            <li>Pressure</li>
            <li>pH</li>
            <li>TDS</li>
          </ul> */}
        </div>
      </div>
      {/* ) : null} */}
    </div>
    // </div>
  );
};

OffCanvasRight.propTypes = {
  onItemClick: PropTypes.func,
  newsObject:PropTypes.object,
};

export default OffCanvasRight;
