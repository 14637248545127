import React from "react";
import PropTypes from 'prop-types';
import moment from 'moment';
import { createSearchParams,useNavigate } from "react-router-dom";
const InfoComponent = ({ site,onInfoItemClick }) => {
  // const navigate = useNavigate();
  // const siteDetails=(site)=>{
  //   console.log('site',site);
  //   navigate('/project',{state: {
  //     projectId: site.projectID,
  //     projectName: site.projectName,
  //   }});
  //   // navigate({pathname:'/site-dashboard', search:createSearchParams({
  //   //   siteId: site.id,
  //   //   siteName: site.pumpName,
  //   // }).toString()});
  // }
  // navigate('/site-dashboard',{
  //   siteId: site.id,
  //   siteName: site.pumpName,
  // });
  // console.log('site',site);
  const bindContent=(content)=>{
    if(content)
      return (
        <ul className="mapmarker-list">
          {content.map((item,index)=>{return(
            <li>
              <span className="title">{item.lbl}</span>
              <span className="amount">{item.val}</span>
            </li>
          )})}
        </ul>
      )
  }
  return (
      <div id="content">
        <div className="mapmarker-head" onClick={()=> onInfoItemClick(site)}>
          {/* <p className="last-update">Last update: {site.lastUpdate?moment(site.lastUpdate).format('DD/MM/YYYY hh:mm:ss a'):null}</p> */}
          <h4>{site.projectName}</h4>
          <h2>{site.pumpName}</h2>
        </div>
        <div className="mapmarker-bodycontent">
          {/* {site.empData?bindContent(site.empData):null} */}
          {site.empData?<ul className="mapmarker-list">
            <li>
              <span className="title">Empoloyee Name</span>
              <span className="amount">{site.empData.empID+"-"+site.empData.empName}</span>
            </li>
            <li>
              <span className="title">Mobile Number</span>
              <span className="amount">{site.empData.mobileNos}</span>
            </li>
            <li>
              <span className="title">Status</span>
              <span className="amount">{site.empData.totalSites>0?site.empData.inWorkingLnNo>0?"Working":"InTransite  ":"No Route Assigned"}</span>
            </li>
            <li>
              <span className="title">Current Site</span>
              <span className="amount">{site.empData.currentSite}</span>
            </li>
            <li>
              <span className="title">Time</span>
              <span className="amount">{site.empData.time}</span>
            </li>
            <li>
              <span className="title">Next Site</span>
              <span className="amount">{site.empData.nextSite}</span>
            </li>
            <li>
              <span className="title">Type</span>
              <span className="amount">{site.empData.complaintType}</span>
            </li>
            <li>
              <span className="title">Today's Target</span>
              <span className="amount">{site.empData.doneSites+"/"+site.empData.totalSites}</span>
            </li>
            
          </ul>:null}
          {onInfoItemClick?<p className="text-end">
          </p>:null}
        </div>
      </div>
  );
};

InfoComponent.propTypes = {
  site: PropTypes.object,
  onInfoItemClick: PropTypes.func,
};
export default InfoComponent;
