import React, { useEffect, useState } from 'react';
import NavBarAdmin from './NavBarAdmin';
import AdminMenu from './AdminMenu';
import { Flex, Select, Button, Text } from '@chakra-ui/react';
import { Table, Input, Checkbox } from 'antd';  // Import Input from Ant Design
import axios from 'axios';
import { CONSTANTVALUE, getData } from '../utils';
import { getSiteListWithProjectsAPI } from '../store/apis';
import EmailMaster from './EmailMaster';

const { Search } = Input;

const MailSystem = () => {
  const [projects, setProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState('');
  const [sites, setSites] = useState([]);
  const [markAll, setMarkAll] = useState(false); // State for "Mark All" checkbox
  const [flip, setFlip] = useState(false);
  const [searchText, setSearchText] = useState(''); // State for search text

  useEffect(() => {
    const p = localStorage.getItem('selectedProject');

    setTimeout(() => {
      if (p) {
        setSelectedProject(p)
      }
    }, 2000)
  }, [])

  
  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (selectedProject) {
      fetchSites();
    }
    setMarkAll(false);
  }, [selectedProject]);

  const fetchSites = async () => {
    try {
      const response = await axios.get(`http://mqtt.aqualogix.in:85/sites/getSitesForMail?projectID=${selectedProject}`);
      setSites(response.data.sites);
    } catch (error) {
      console.error('Error fetching sites:', error);
    }
  };

  const fetchData = async () => {
    const data = await getData(CONSTANTVALUE.USEROBJECT);
    const info = await getSiteListWithProjectsAPI(data.id);
    const filteredProjects = info.message.filter((item) => item.project && item.sites.length > 0);
    setProjects(filteredProjects);
  };

  const handleCheckboxChange = async (siteId, currentStatus) => {
    try {
      const newStatus = !currentStatus; // Toggle the status
      await axios.get(`http://mqtt.aqualogix.in:85/sites/updateMailStatus?siteId=${siteId}&status=${newStatus}`);
      // Update local state after successful API call
      fetchSites();
    } catch (error) {
      console.error('Error updating site status:', error);
    }
  };

  const handleMarkAllChange = async (status) => {
    try {
      const promises = sites.map(site =>
        axios.get(`http://mqtt.aqualogix.in:85/sites/updateMailStatus?siteId=${site._id}&status=${status}`)
      );
      await Promise.all(promises); // Wait for all updates to complete
      setMarkAll(status); // Set markAll state
      fetchSites(); // Refresh site data after updating
    } catch (error) {
      console.error('Error updating all sites:', error);
    }
  };

  const handleSearch = (value) => {
    setSearchText(value.toLowerCase());
  };

  const filteredSites = sites.filter(site => 
    site.pumpName.toLowerCase().includes(searchText)
  );

  const columns = [
    {
      title: 'Site Name',
      dataIndex: 'pumpName',
      key: 'pumpName',
      ...{
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Search
              placeholder="Search site"
              onSearch={handleSearch}
              style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
          </div>
        ),
      }
    },
    {
      title: 'Alert Status',
      dataIndex: 'mailAlert',
      key: 'mailAlert',
      render: (text, record) => (
        <Checkbox
          checked={record.mailAlert}
          onChange={() => handleCheckboxChange(record._id, record.mailAlert)}
        />
      ),
    },
  ];

  return (
    <>
      <div className='page'>
        <NavBarAdmin />
        <AdminMenu selMenu="Mail Alert System" />
        <div className="project-page-head" style={{ width: '90%', borderRight: '8px solid black', borderRadius: '20px', marginLeft: "5px", marginTop: '10px' }}>
          <div className="row m-0">
            <div className="col-12 py-4">
              <div className="sub-head">Mail Alert System</div>
              <h3 className="project-title fw-bold">Mail Alert Advanced System</h3>
            </div>
          </div>
        </div>
        <section>
          <div className="container">
            <div className="row d-flex justify-content-center h-100 pt-5">
              <div className="card text-black" style={{ borderRadius: '10px' }}>
                <div className="card-body">
                  {!flip ?
                    <>
                      <Flex mb={4} justifyContent={'flex-end'}>
                        <Select
                          id="pageSizeSelect"
                          w={'20%'}
                          value={selectedProject}
                          variant={'filled'}
                          onChange={(e) => setSelectedProject(e.target.value)}
                          color="black"
                        >
                          <option value={'ALL'}>Select Project</option>
                          {projects?.sort((a, b) => a.project.ProjectName.localeCompare(b.project.ProjectName)).map((item) => (
                            <option key={item.project._id} value={item.project._id}>
                              {item.project.ProjectName}
                            </option>
                          ))}
                        </Select>
                        <Button ml={4} colorScheme='facebook' color={'white'} onClick={() => setFlip(!flip)}>
                          Email Master Control
                        </Button>
                      </Flex>

                      {/* Display Mark All */}
                      {sites.length > 0 && (
                        <Flex justifyContent="flex-end" mt={2}>
                          <Checkbox
                            isChecked={markAll}
                            onChange={(e) => handleMarkAllChange(e.target.checked)}
                          >
                            Mark All
                          </Checkbox>
                        </Flex>
                      )}

                      {/* Display sites in a table */}
                      <div style={{ textAlign: 'center' }}>
                        {sites.length > 0 ? (
                          <Table columns={columns} dataSource={filteredSites} rowKey="_id" style={{ marginTop: "30px" }} />
                        ) : (
                          <Text fontSize='lg' fontWeight='medium' mt={'100px'} mb={'100px'}>
                            Please select a project
                          </Text>
                        )}
                      </div>
                    </>
                    :
                    <>
                      <Flex justifyContent={'flex-end'}>
                        <Button colorScheme='blue' onClick={() => setFlip(!flip)}>
                          Site Mail Control
                        </Button>
                      </Flex>
                      <EmailMaster projects={projects} />
                    </>
                  }
                </div>
              </div>
            </div>
          </div>
        </section >
      </div >
    </>
  );
};

export default MailSystem;
