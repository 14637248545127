import React, { useEffect, useMemo, useState } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import PropTypes from 'prop-types';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);

const AreaChart = ({ dataList, xLabel, yLabel }) => {
  const [graphStatus, setGraphStatus] = useState(false);

  // Memoize chart data for optimization
  const chartData = useMemo(() => {
    if (dataList && dataList.length > 0) {
      const labels = dataList.map(item => item.x);
      const dataPoints = dataList.map(item => (item.y * 10).toFixed(5));

      return {
        labels,
        datasets: [
          {
            fill: true,
            label: "Pressure",
            data: dataPoints,
            borderColor: "rgb(53, 162, 235)",
            backgroundColor: "rgba(53, 162, 235, 0.5)",
          },
        ],
      };
    }
    return null;
  }, [dataList]);

  // Set graph status when data is ready
  useEffect(() => {
    if (chartData && chartData.labels.length > 0) {
      setGraphStatus(true);
    } else {
      setGraphStatus(false);
    }
  }, [chartData]);

  // Dynamic chart options based on xLabel and yLabel
  const options = useMemo(() => ({
    scales: {
      y: {
        title: {
          display: true,
          text: yLabel,
          font: {
            size: 16,
            weight: 'bold',
          },
        },
      },
      x: {
        title: {
          display: true,
          text: xLabel,
          font: {
            size: 16,
            weight: 'bold',
          },
        },
        min: 0,
        max: new Date().getHours(),
        stepSize: 1,
      },
    },
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {

      },
    },
  }), [xLabel, yLabel]);

  if (!graphStatus) {
    return <p>No data available for the chart</p>;
  }

  return (
    <div style={{ position: 'relative', height : '270px', width: '100%' }}>
      <Line options={options} data={chartData} />;
    </div>
  )
};

AreaChart.propTypes = {
  dataList: PropTypes.arrayOf(
    PropTypes.shape({
      x: PropTypes.string.isRequired,
      y: PropTypes.number.isRequired,
    })
  ).isRequired,
  xLabel: PropTypes.string.isRequired,
  yLabel: PropTypes.string.isRequired,
};

export default AreaChart;
