// import { createSlice } from "@reduxjs/toolkit";
import {types} from '../actions/types';

const initialState = {
  loading: false,
  status:null,
  data:null,
  message:null,
  action:null,
  IMEINumber:"",
};

const settingReducer=(state = initialState, {type, payload})=>{
  switch (type) {
    case types.GET_NEWS_REQUEST:
      // console.log('LOGIN_REQUEST_APPSETTINGS');
      return {
        ...state,
        loading: true,
        // isLogin:false,
        action:types.GET_NEWS_REQUEST
      };
    case types.SET_IMEI_NUMBER_SUCCESS:
      // console.log('LOGIN_REQUEST_APPSETTINGS_SUCCESS');
      // console.log('LOGIN_REQUEST_APPSETTINGS_SUCCESS-payload',payload);
      return {
        ...state,
        status: payload.status?payload.status:null,
        data: payload.data?payload.data:null,
        message: payload.message?payload.message:null,
        loading: false,
        IMEINumber: payload.IMEINumber?payload.IMEINumber:null,
        action:types.SET_IMEI_NUMBER_SUCCESS
      };
    case types.SET_IMEI_NUMBER_FAILURE:
      // console.log('LOGIN_REQUEST_APPSETTINGS_FAILURE');
      // console.log('LOGIN_REQUEST_APPSETTINGS_FAILURE-payload',payload);
      return {
        ...state,
        status: payload.status?payload.status:null,
        data: payload.data?payload.data:null,
        message: payload.message?payload.message:null,    
        token:null,    
        loading: false,
        IMEINumber: payload.IMEINumber?payload.IMEINumber:null,
        action:types.SET_IMEI_NUMBER_FAILURE
      };
    default:
      return state;
  }
}

export default settingReducer;