import React, { ChangeEvent, useEffect, useState } from 'react';
import { useNavigate, useLocation, Link } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { addCompany, editCompany, getCompanyDetails } from '../../redux/actions/companyAction'
// import {getSiteDetails} from '../../redux/actions/siteAction'
import { types } from '../../redux/actions/types';
// import mainLogo from "../../assets/images/aqualogix-logo-b.png";
// import harGharJalLogo from "../../assets/images/hargharjal-logo-b.png";
import ButtonWithSpinner from "../../Components/ButtonWithSpinner";
import NavBarAdmin from "../../Components/NavBarAdmin";
import AdminMenu from "../../Components/AdminMenu";
import { isEmpty, isLoggedInUser, checkPermission } from '../../utils'
import moment from 'moment'
import { useToast } from '@chakra-ui/react';

const AddCompanyDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  //   const [userName, setUserName] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [remark, setRemark] = useState('');
  const [startdate, setStartdate] = useState('');
  const [supUsrAtDesireCheckbox, setSupUsrAtDesireCheckbox] = useState(false);
  const [file, setFile] = useState();
  const [serverFile, setServerFile] = useState();
  const [signatureFile, setSignatureFile] = useState();
  const [supAdminCheckbox, setSupAdminCheckbox] = useState(false);
  const [isActive, setIsACtive] = useState(null);
  const [localProjectId, setLocalProjectId] = useState(null);

  const [companyAddress, setCompanyAddress] = useState();
  const [companyAddress1, setCompanyAddress1] = useState();
  const [companyPincode, setCompanyPincode] = useState();
  const [companyCity, setCompanyCity] = useState();
  const [companyState, setCompanyState] = useState();
  const [companyCountry, setCompanyCountry] = useState();
  const [companyPancard, setCompanyPancard] = useState();
  const [companyGSTI, setCompanyGSTI] = useState();
  const [companyTIN, setCompanyTIN] = useState();
  const [companyCIN, setCompanyCIN] = useState();
  const [companyBankName, setCompanyBankName] = useState();
  const [companyBankAccount, setCompanyBankAccount] = useState();
  const [companyAccountType, setCompanyAccountType] = useState();
  const [companyBranch, setCompanyBranch] = useState();
  const [companyIFSC, setCompanyIFSC] = useState();
  const [signature, setSignature] = useState();
  const toast = useToast()

  /////////////////////////////////////////////////////////////////
  /////Parameters for getting permission value/////////////////////
  ///////////////////////////////////////////////////////////////// 
  const [isEdit, setIsEdit] = useState(false);

  // const [inImei, setInImei] = useState(''); 
  // const [isLoading, setIsLoading] = useState(false);
  const companyReducer = useSelector(state => state.companyReducer);
  // const companyReducer = useSelector(state => state.companyReducer);
  const location = useLocation();
  useEffect(() => {
    // setIsLoading(true);
    // console.log('LogIn-useEffect');    
    chkLogin();
    setPermission();
    if (location) {
      if (location.state) {
        if (location.state.companyId) {
          setLocalProjectId(location.state.companyId);
          fetchCompanyDetails();
        }
      }
    }
  }, [])
  useEffect(() => {
    // console.log('AddCompanyDetails-userReducer-useEffect',companyReducer);    
    if (companyReducer.action) {
      if (companyReducer.action === types.ADD_COMPANY_REQUEST_SUCCESS) {
        // console.log('LoginScreen-userReducer-useEffect',userReducer)        
        if (companyReducer.status === "success") {
          // storeData(CONSTANTVALUE.USEROBJECT,userReducer.data);
          // storeData(CONSTANTVALUE.USERTOKEN,userReducer.token);
          navigate('/Admin/Company');
          // navigate('/Admin/Company',{state: {isReload: true}});
        } else {
          toast({
            title: companyReducer.message,
            status: 'warning',
            duration: 2000,
            isClosable: true,
            position: "top-right",
            positionOptions: {
              right: "0",
              top: "0",
            },
          });
        }
      } else if (companyReducer.action === types.EDIT_COMPANY_REQUEST_SUCCESS) {
        // console.log('LoginScreen-userReducer-useEffect',userReducer)        
        if (companyReducer.status === "success") {
          // storeData(CONSTANTVALUE.USEROBJECT,userReducer.data);
          // storeData(CONSTANTVALUE.USERTOKEN,userReducer.token);
          navigate('/Admin/Company');
        } else {
          toast({
            title: companyReducer.message,
            status: 'warning',
            duration: 2000,
            isClosable: true,
            position: "top-right",
            positionOptions: {
              right: "0",
              top: "0",
            },
          });
        }
      } else if (companyReducer.action === types.GET_COMPANY_DETIALS_REQUEST_SUCCESS) {
        // console.log('moment(companyReducer.data.StartDate)',moment(companyReducer.data.StartDate).format('YYYY-MM-DD').toString());
        setLocalProjectId(companyReducer.data._id);
        setCompanyName(companyReducer.data.CompanyName);
        setStartdate(moment(companyReducer.data.StartDate).format('YYYY-MM-DD').toString());
        setRemark(companyReducer.data.Remarks);
        setSupAdminCheckbox(companyReducer.data.SupportAdmin);
        setSupUsrAtDesireCheckbox(companyReducer.data.SupportUserAtDesire);
        setServerFile(companyReducer.data.Logo);
        setSignatureFile(companyReducer.data.CompanySignature);
        setCompanyAddress(companyReducer.data.CompanyAddress);
        setCompanyAddress1(companyReducer.data.CompanyAddress1);
        setCompanyAccountType(companyReducer.data.CompanyAccountType);
        setCompanyBranch(companyReducer.data.CompanyBranch);
        setCompanyBankAccount(companyReducer.data.CompanyBankAccount);
        setCompanyBankName(companyReducer.data.CompanyBankName);
        setCompanyCIN(companyReducer.data.CompanyCIN);
        setCompanyCity(companyReducer.data.CompanyCity);
        setCompanyCountry(companyReducer.data.CompanyCountry);
        setCompanyGSTI(companyReducer.data.CompanyGSTI);
        setCompanyIFSC(companyReducer.data.CompanyIFSC);
        setCompanyPancard(companyReducer.data.CompanyPancard);
        setCompanyPincode(companyReducer.data.CompanyPincode);
        setCompanyState(companyReducer.data.CompanyState);
        setCompanyTIN(companyReducer.data.CompanyTIN);
        // if(companyReducer.activeCheckbox==="activeCheckbox"){
        // setCompanyName(companyReducer.data.companyName);
        // setRemark(companyReducer.data.remark);
        // setInLongitude(companyReducer.data.longitude);
        // setInLatitude(companyReducer.data.latitude);
        // setLocalProjectId(companyReducer.data._id);
        // setIsACtive(activeCheckbox)
        // activeCheckbox
        // setInImei(companyReducer.data.IMEI);
        // }else {
        //   toast({
        //     title: companyReducer.message,
        //     status: 'warning',
        //     duration: 2000,
        //     isClosable: true,
        //     position: "top-right",
        //     positionOptions: {
        //       right: "0",
        //       top: "0",
        //     },
        // });
        // }
      } else if (companyReducer.action === types.ADD_COMPANY_REQUEST_FAILURE) {
        toast({
          title: companyReducer.message,
          status: 'warning',
          duration: 2000,
          isClosable: true,
          position: "top-right",
          positionOptions: {
            right: "0",
            top: "0",
          },
        });
      } else if (companyReducer.action === types.EDIT_COMPANY_REQUEST_FAILURE) {
        toast({
          title: companyReducer.message,
          status: 'warning',
          duration: 2000,
          isClosable: true,
          position: "top-right",
          positionOptions: {
            right: "0",
            top: "0",
          },
        });
      } else if (companyReducer.action === types.GET_COMPANY_DETIALS_REQUEST_FAILURE) {
        toast({
          title: companyReducer.message,
          status: 'warning',
          duration: 2000,
          isClosable: true,
          position: "top-right",
          positionOptions: {
            right: "0",
            top: "0",
          }
        })
      }
    }
  }, [companyReducer.action])
  // useEffect(()=>{
  //   // console.log('Dashboard-companyReducer-useEffect',companyReducer);    
  //   if(companyReducer.action){
  //     if(companyReducer.action===types.GET_SITEDETAILS_REQUEST_APPSETTINGS_SUCCESS){
  //       // console.log('AddCompanyDetails-companyReducer-useEffect',companyReducer);
  //       // console.log('AddCompanyDetails-companyReducer-useEffect.data',companyReducer.data);
  //       setCompanyName(companyReducer.data.pumpName);
  //       setRemark(companyReducer.data.scheme);
  //       setInLongitude(companyReducer.data.longitude);
  //       setInLatitude(companyReducer.data.latitude);
  //       setInImei(companyReducer.data.IMEI);
  //     }
  //     else if(companyReducer.action===types.GET_SITEDETAILS_REQUEST_APPSETTINGS_FAILURE){
  //       toast({
  //     title: companyReducer.message,
  //     status: 'warning',
  //     duration: 2000,
  //     isClosable: true,
  //     position: "top-right",
  //     positionOptions: {
  //       right: "0",
  //       top: "0",
  //     },
  // });
  //     }
  //   }
  // },[companyReducer.action])
  const chkLogin = async () => {
    const isLoggedIn = await isLoggedInUser();
    // console.log("isLoggedInUser()",isLoggedIn);
    if (!isLoggedIn) {
      navigate('/Admin');
    }
  }
  const setPermission = async () => {
    setIsEdit(await checkPermission('Company', 'edit'));
  }
  const submitCompany = () => {
    // console.log('companyName',companyName);
    // console.log('companyAddress',companyAddress);
    // console.log('companyPincode',companyPincode);
    // console.log('companyCity',companyCity);
    // console.log('companyState',companyState);
    // console.log('companyCountry',companyCountry);
    // console.log('companyPancard',companyPancard);
    // console.log('companyGSTI',companyGSTI);
    // console.log('companyTIN',companyTIN);
    // console.log('companyCIN',companyCIN);
    // console.log('companyBankName',companyBankName);
    // console.log('companyBankAccount',companyBankAccount);
    // console.log('companyAccountType',companyAccountType);
    // console.log('companyBranch',companyBranch);
    // console.log('companyIFSC',companyIFSC);
    // console.log('remark',remark);
    // console.log('startdate',startdate);
    if (isEmpty(companyName)) {
      toast({
        title: 'Please select company name',
        status: 'warning',
        duration: 2000,
        isClosable: true,
        position: "top-right",
        positionOptions: {
          right: "0",
          top: "0",
        },
      })
      // alert(string.strFNameValMsg);
      return;
    }
    else if (isEmpty(companyAddress ? companyAddress : "")) {
      toast({
        title: 'Please select adress',
        status: 'warning',
        duration: 2000,
        isClosable: true,
        position: "top-right",
        positionOptions: {
          right: "0",
          top: "0",
        },
      })
      // alert(string.strLNameValMsg);
      return;
    }
    else if (isEmpty(companyPincode ? companyPincode : "")) {
      toast({
        title: 'Please select pincode',
        status: 'warning',
        duration: 2000,
        isClosable: true,
        position: "top-right",
        positionOptions: {
          right: "0",
          top: "0",
        },
      })
      // alert(string.strLNameValMsg);
      return;
    }
    else if (isEmpty(companyCity ? companyCity : "")) {
      toast({
        title: 'Please select city',
        status: 'warning',
        duration: 2000,
        isClosable: true,
        position: "top-right",
        positionOptions: {
          right: "0",
          top: "0",
        },
      })
      // alert(string.strLNameValMsg);
      return;
    }
    else if (isEmpty(companyState ? companyState : "")) {
      toast({
        title: 'Please select state',
        status: 'warning',
        duration: 2000,
        isClosable: true,
        position: "top-right",
        positionOptions: {
          right: "0",
          top: "0",
        },
      })
      // alert(string.strLNameValMsg);
      return;
    }
    else if (isEmpty(companyCountry ? companyCountry : "")) {
      toast({
        title: 'Please select country',
        status: 'warning',
        duration: 2000,
        isClosable: true,
        position: "top-right",
        positionOptions: {
          right: "0",
          top: "0",
        },
      })
      // alert(string.strLNameValMsg);
      return;
    }
    else if (isEmpty(companyPancard ? companyPancard : "")) {

      toast({
        title: 'Please enter pancard',
        status: 'warning',
        duration: 2000,
        isClosable: true,
        position: "top-right",
        positionOptions: {
          right: "0",
          top: "0",
        },
      })// alert(string.strLNameValMsg);
      return;
    }
    else if (isEmpty(companyGSTI ? companyGSTI : "")) {
      toast({
        title: 'Please enter GSTI',
        status: 'warning',
        duration: 2000,
        isClosable: true,
        position: "top-right",
        positionOptions: {
          right: "0",
          top: "0",
        },
      })
      // alert(string.strLNameValMsg);
      return;
    }
    else if (isEmpty(companyTIN ? companyTIN : "")) {
      toast({
        title: 'Please enter TIN',
        status: 'warning',
        duration: 2000,
        isClosable: true,
        position: "top-right",
        positionOptions: {
          right: "0",
          top: "0",
        },
      })
      // alert(string.strLNameValMsg);
      return;
    }
    else if (isEmpty(companyCIN ? companyCIN : "")) {
      toast({
        title: 'Please select CIN',
        status: 'warning',
        duration: 2000,
        isClosable: true,
        position: "top-right",
        positionOptions: {
          right: "0",
          top: "0",
        },
      })
      // alert(string.strLNameValMsg);
      return;
    }
    else if (isEmpty(companyBankName ? companyBankName : "")) {
      toast({
        title: 'Please enter bank name',
        status: 'warning',
        duration: 2000,
        isClosable: true,
        position: "top-right",
        positionOptions: {
          right: "0",
          top: "0",
        },
      })
      // alert(string.strLNameValMsg);
      return;
    }
    else if (isEmpty(companyBankAccount ? companyBankAccount : "")) {
      toast({
        title: 'Please enter bank account',
        status: 'warning',
        duration: 2000,
        isClosable: true,
        position: "top-right",
        positionOptions: {
          right: "0",
          top: "0",
        },
      })
      // alert(string.strLNameValMsg);
      return;
    }
    else if (isEmpty(companyAccountType ? companyAccountType : "")) {
      toast({
        title: 'Please enter account type',
        status: 'warning',
        duration: 2000,
        isClosable: true,
        position: "top-right",
        positionOptions: {
          right: "0",
          top: "0",
        },
      })
      // alert(string.strLNameValMsg);
      return;
    }
    else if (isEmpty(companyBranch ? companyBranch : "")) {
      toast({
        title: 'Please enter branch',
        status: 'warning',
        duration: 2000,
        isClosable: true,
        position: "top-right",
        positionOptions: {
          right: "0",
          top: "0",
        },
      })
      // alert(string.strLNameValMsg);
      return;
    }
    else if (isEmpty(companyIFSC ? companyIFSC : "")) {
      toast({
        title: 'Please enter IFSC',
        status: 'warning',
        duration: 2000,
        isClosable: true,
        position: "top-right",
        positionOptions: {
          right: "0",
          top: "0",
        },
      })
      // alert(string.strLNameValMsg);
      return;
    } else if (isEmpty(remark ? remark : "")) {
      toast({
        title: 'Please enter remark',
        status: 'warning',
        duration: 2000,
        isClosable: true,
        position: "top-right",
        positionOptions: {
          right: "0",
          top: "0",
        },
      })
      // alert(string.strLNameValMsg);
      return;
    } else if (isEmpty(startdate ? startdate : "")) {
      toast({
        title: 'Please enter start date',
        status: 'warning',
        duration: 2000,
        isClosable: true,
        position: "top-right",
        positionOptions: {
          right: "0",
          top: "0",
        },
      })
      // alert(string.strLNameValMsg);
      return;
    }

    // console.log('loginRequest',loginRequest);
    if (localProjectId) {
      // const formData=new FormData();
      // formData.append("id",localProjectId);
      const request = {
        "id": localProjectId,
        "CompanyName": companyName,
        "StartDate": startdate,
        "Logo": file,
        "Remarks": remark,
        "SupportAdmin": supAdminCheckbox,
        "SupportUserAtDesire": supUsrAtDesireCheckbox,
        "CompanyAddress": companyAddress,
        "CompanyAddress1": companyAddress1,
        "CompanyPincode": companyPincode,
        "CompanyCity": companyCity,
        "CompanyState": companyState,
        "CompanyCountry": companyCountry,
        "CompanyPancard": companyPancard,
        "CompanyGSTI": companyGSTI,
        "CompanyTIN": companyTIN,
        "CompanyCIN": companyCIN,
        "CompanyBankName": companyBankName,
        "CompanyBankAccount": companyBankAccount,
        "CompanyAccountType": companyAccountType,
        "CompanyBranch": companyBranch,
        "CompanyIFSC": companyIFSC,
        "CompanySignature": signature,
      };
      console.log('AddProject-request', request);
      dispatch(editCompany(request));
    } else {
      if (!file) {
        toast({
          title: 'please choose logo',
          status: 'warning',
          duration: 2000,
          isClosable: true,
          position: "top-right",
          positionOptions: {
            right: "0",
            top: "0",
          },
        })
        // alert(string.strLNameValMsg);
        return;
      }
      const request = {
        "CompanyName": companyName,
        "StartDate": startdate,
        "Logo": file,
        "Remarks": remark,
        "SupportAdmin": supAdminCheckbox,
        "SupportUserAtDesire": supUsrAtDesireCheckbox,
        "CompanyAddress": companyAddress,
        "CompanyAddress1": companyAddress1,
        "CompanyPincode": companyPincode,
        "CompanyCity": companyCity,
        "CompanyState": companyState,
        "CompanyCountry": companyCountry,
        "CompanyPancard": companyPancard,
        "CompanyGSTI": companyGSTI,
        "CompanyTIN": companyTIN,
        "CompanyCIN": companyCIN,
        "CompanyBankName": companyBankName,
        "CompanyBankAccount": companyBankAccount,
        "CompanyAccountType": companyAccountType,
        "CompanyBranch": companyBranch,
        "CompanyIFSC": companyIFSC,
        "CompanySignature": signature,
      };
      console.log('AddProject-request', request);
      dispatch(addCompany(request));
    }
    // navigate('/dashboard');
  }
  const handleProjectInput = (e) => {
    console.log('e.target.name', e.target.name);
    console.log('e.target.value', e.target.value);
    if (e.target.name === "txtName") {
      setCompanyName(e.target.value);
    }
    if (e.target.name === "txtRemark") {
      setRemark(e.target.value);
    }
    if (e.target.name === "txtStartdate") {
      setStartdate(e.target.value);
    }
    if (e.target.name === "txtAddress") {
      setCompanyAddress(e.target.value);
    }
    if (e.target.name === "txtAddress1") {
      setCompanyAddress1(e.target.value);
    }
    if (e.target.name === "txtPincode") {
      setCompanyPincode(e.target.value);
    }
    if (e.target.name === "txtCity") {
      setCompanyCity(e.target.value);
    }
    if (e.target.name === "txtState") {
      setCompanyState(e.target.value);
    }
    if (e.target.name === "txtCountry") {
      setCompanyCountry(e.target.value);
    }
    if (e.target.name === "txtPancard") {
      setCompanyPancard(e.target.value);
    }
    if (e.target.name === "txtGSTI") {
      setCompanyGSTI(e.target.value);
    }
    if (e.target.name === "txtTIN") {
      setCompanyTIN(e.target.value);
    }
    if (e.target.name === "txtCIN") {
      setCompanyCIN(e.target.value);
    }
    if (e.target.name === "txtBankName") {
      setCompanyBankName(e.target.value);
    }
    if (e.target.name === "txtBankAccount") {
      setCompanyBankAccount(e.target.value);
    }
    if (e.target.name === "txtAccountType") {
      setCompanyAccountType(e.target.value);
    }
    if (e.target.name === "txtBranch") {
      setCompanyBranch(e.target.value);
    }
    if (e.target.name === "txtIFSC") {
      setCompanyIFSC(e.target.value);
    }
    // if(e.target.name==="txtLat"){
    //     setInLatitude(e.target.value);
    // }
  }
  const handleActiveStatus = (e) => {
    console.log('e.target.name', e.target.name);
    console.log('e.target.checked', e.target.checked);
    if (e.target.name === "supUsrAtDesireCheckbox") {
      setSupUsrAtDesireCheckbox(e.target.checked);
    }
    if (e.target.name === "supAdminCheckbox") {
      setSupAdminCheckbox(e.target.checked);
    }
  }
  const handleFileChange = (e) => {
    if (e.target.name == "txtLogo") {
      setFile(e.target.files[0]);
    }
    if (e.target.name == "txtSignature") {
      setSignature(e.target.files[0]);
    }
  };
  const fetchCompanyDetails = () => {
    console.log('AddCompanyDetails-fetchCompanyDetails-companyId', location.state.companyId);
    dispatch(getCompanyDetails(location.state.companyId));
  }
  return (
    <div className="page">
      <NavBarAdmin />
      <AdminMenu selMenu="Company" />
      <section className="h-100" style={{ backgroundColor: "#eee" }}>
        <div className="container">
          <div className="row d-flex justify-content-center pt-4 h-100 mb-3">
            {/* <div className="col-md-8 col-lg-8 col-xl-6"> */}
            <div className="card text-black" style={{ borderRadius: "25px" }}>
              <div className="card-body pt-5">
                <div className="row justify-content-center">
                  <form className="mx-1 mx-md-2">
                    <div className='row'>
                      <p className="h1 fw-bold mb-4 ">{localProjectId ? "Update Company" : "Add Company"}</p>
                      <div className="col-6">
                        <div className="d-flex flex-row mb-3">
                          {/* <i className="fas fa-envelope fa-lg me-3 fa-fw"></i> */}
                          <div className="form-outline flex-fill mb-0">
                            <label className="form-label" for="form3Example3c">Company Name</label>
                            <input type="text" name="txtName" id="txtName" value={companyName} onChange={(event) => handleProjectInput(event)} className="form-control" />
                          </div>
                        </div>
                        <div className="d-flex flex-row mb-3">
                          {/* <i className="fas fa-envelope fa-lg me-3 fa-fw"></i> */}
                          <div className="form-outline flex-fill mb-0">
                            <label className="form-label" for="form3Example3c">Address</label>
                            <input type="text" name="txtAddress" id="txtAddress" value={companyAddress} onChange={(event) => handleProjectInput(event)} className="form-control" />
                          </div>
                        </div>
                        <div className="d-flex flex-row mb-3">
                          {/* <i className="fas fa-envelope fa-lg me-3 fa-fw"></i> */}
                          <div className="form-outline flex-fill mb-0">
                            <label className="form-label" for="form3Example3c"></label>
                            <input type="text" name="txtAddress1" id="txtAddress1" value={companyAddress1} onChange={(event) => handleProjectInput(event)} className="form-control" />
                          </div>
                        </div>
                        <div className="d-flex flex-row mb-3">
                          {/* <i className="fas fa-envelope fa-lg me-3 fa-fw"></i> */}
                          <div className="form-outline flex-fill mb-0">
                            <label className="form-label" for="form3Example3c">Pincode</label>
                            <input type="text" name="txtPincode" id="txtPincode" value={companyPincode} onChange={(event) => handleProjectInput(event)} className="form-control" />
                          </div>
                        </div>
                        <div className="d-flex flex-row mb-3">
                          {/* <i className="fas fa-envelope fa-lg me-3 fa-fw"></i> */}
                          <div className="form-outline flex-fill mb-0">
                            <label className="form-label" for="form3Example3c">City</label>
                            <input type="text" name="txtCity" id="txtCity" value={companyCity} onChange={(event) => handleProjectInput(event)} className="form-control" />
                          </div>
                        </div>
                        <div className="d-flex flex-row mb-3">
                          {/* <i className="fas fa-envelope fa-lg me-3 fa-fw"></i> */}
                          <div className="form-outline flex-fill mb-0">
                            <label className="form-label" for="form3Example3c">State</label>
                            <input type="text" name="txtState" id="txtState" value={companyState} onChange={(event) => handleProjectInput(event)} className="form-control" />
                          </div>
                        </div>
                        <div className="d-flex flex-row mb-3">
                          {/* <i className="fas fa-envelope fa-lg me-3 fa-fw"></i> */}
                          <div className="form-outline flex-fill mb-0">
                            <label className="form-label" for="form3Example3c">Country</label>
                            <input type="text" name="txtCountry" id="txtCountry" value={companyCountry} onChange={(event) => handleProjectInput(event)} className="form-control" />
                          </div>
                        </div>
                        <div className="d-flex flex-row mb-3">
                          {/* <i className="fas fa-envelope fa-lg me-3 fa-fw"></i> */}
                          <div className="form-outline flex-fill mb-0">
                            <label className="form-label" for="form3Example3c">Company PanCard</label>
                            <input type="text" name="txtPancard" id="txtPancard" value={companyPancard} onChange={(event) => handleProjectInput(event)} className="form-control" />
                          </div>
                        </div>
                        <div className="d-flex flex-row mb-3">
                          {/* <i className="fas fa-envelope fa-lg me-3 fa-fw"></i> */}
                          <div className="form-outline flex-fill mb-0">
                            <label className="form-label" for="form3Example3c">GSTIN(GST iden num.)</label>
                            <input type="text" name="txtGSTI" id="txtGSTI" value={companyGSTI} onChange={(event) => handleProjectInput(event)} className="form-control" />
                          </div>
                        </div>
                        <div className="d-flex flex-row mb-3">
                          {/* <i className="fas fa-envelope fa-lg me-3 fa-fw"></i> */}
                          <div className="form-outline flex-fill mb-0">
                            <label className="form-label" for="form3Example3c">TIN Number(Taxpayer iden. num.)</label>
                            <input type="text" name="txtTIN" id="txtTIN" value={companyTIN} onChange={(event) => handleProjectInput(event)} className="form-control" />
                          </div>
                        </div>
                        <div className="d-flex flex-row mb-3">
                          {/* <i className="fas fa-envelope fa-lg me-3 fa-fw"></i> */}
                          <div className="form-outline flex-fill mb-0">
                            <label className="form-label" for="form3Example3c">CIN(Corporate iden. num.)</label>
                            <input type="text" name="txtCIN" id="txtCIN" value={companyCIN} onChange={(event) => handleProjectInput(event)} className="form-control" />
                          </div>
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="d-flex flex-row mb-3">
                          {/* <i className="fas fa-envelope fa-lg me-3 fa-fw"></i> */}
                          <div className="form-outline flex-fill mb-0">
                            <label className="form-label" for="form3Example3c">Company Bank Name</label>
                            <input type="text" name="txtBankName" id="txtBankName" value={companyBankName} onChange={(event) => handleProjectInput(event)} className="form-control" />
                          </div>
                        </div>
                        <div className="d-flex flex-row mb-3">
                          {/* <i className="fas fa-envelope fa-lg me-3 fa-fw"></i> */}
                          <div className="form-outline flex-fill mb-0">
                            <label className="form-label" for="form3Example3c">Bank Account No.</label>
                            <input type="text" name="txtBankAccount" id="txtBankAccount" value={companyBankAccount} onChange={(event) => handleProjectInput(event)} className="form-control" />
                          </div>
                        </div>
                        <div className="d-flex flex-row mb-3">
                          {/* <i className="fas fa-envelope fa-lg me-3 fa-fw"></i> */}
                          <div className="form-outline flex-fill mb-0">
                            <label className="form-label" for="form3Example3c">Account Type</label>
                            <input type="text" name="txtAccountType" id="txtAccountType" value={companyAccountType} onChange={(event) => handleProjectInput(event)} className="form-control" />
                          </div>
                        </div>
                        <div className="d-flex flex-row mb-3">
                          {/* <i className="fas fa-envelope fa-lg me-3 fa-fw"></i> */}
                          <div className="form-outline flex-fill mb-0">
                            <label className="form-label" for="form3Example3c">Branch</label>
                            <input type="text" name="txtBranch" id="txtBranch" value={companyBranch} onChange={(event) => handleProjectInput(event)} className="form-control" />
                          </div>
                        </div>
                        <div className="d-flex flex-row mb-3">
                          {/* <i className="fas fa-envelope fa-lg me-3 fa-fw"></i> */}
                          <div className="form-outline flex-fill mb-0">
                            <label className="form-label" for="form3Example3c">IFSC Code</label>
                            <input type="text" name="txtIFSC" id="txtIFSC" value={companyIFSC} onChange={(event) => handleProjectInput(event)} className="form-control" />
                          </div>
                        </div>
                        <div className="d-flex flex-row mb-3">
                          {/* <i className="fas fa-envelope fa-lg me-3 fa-fw"></i> */}
                          <div className="form-outline flex-fill mb-0">
                            <label className="form-label" for="form3Example3c">Remark</label>
                            <input type="text" name="txtRemark" id="txtRemark" value={remark} onChange={(event) => handleProjectInput(event)} className="form-control" />
                          </div>
                        </div>
                        <div className="d-flex flex-row mb-3">
                          {/* <i className="fas fa-envelope fa-lg me-3 fa-fw"></i> */}
                          <div className="form-outline flex-fill mb-0">
                            <label className="form-label" for="form3Example3c">Start Date</label>
                            <input type="date" name="txtStartdate" id="txtStartdate" value={startdate} onChange={(event) => handleProjectInput(event)} className="form-control" />
                          </div>
                        </div>
                        <div className="d-flex flex-row mb-3">
                          {/* <i className="fas fa-envelope fa-lg me-3 fa-fw"></i> */}
                          <div className="form-outline flex-fill mb-0">
                            <label className="form-label" for="form3Example3c">Logo</label>
                            <input type="file" name="txtLogo" id="txtLogo" onChange={(event) => handleFileChange(event)} className="form-control" />
                            <img src={`data:image/png;base64,${serverFile}`} height='100px' width='100px' />
                          </div>
                        </div>
                        <div className="d-flex flex-row mb-3">
                          <div className="form-outline flex-fill mb-0">
                            <input className="form-check-input me-2" type="checkbox" value="" name="supUsrAtDesireCheckbox" id="supUsrAtDesireCheckbox" checked={supUsrAtDesireCheckbox ? true : false} onChange={(event) => handleActiveStatus(event)} />
                            <label className="form-check-label" for="supUsrAtDesireCheckbox">Support User At Desire <br /><span className='checkboxLbl'>(Note: Tick to make it Yes)</span></label>
                          </div>
                        </div>
                        <div className="d-flex flex-row mb-3">
                          <div className="form-outline flex-fill mb-0">
                            <input className="form-check-input me-2" type="checkbox" value="" name="supAdminCheckbox" id="supAdminCheckbox" checked={supAdminCheckbox ? true : false} onChange={(event) => handleActiveStatus(event)} />
                            <label className="form-check-label" for="supAdminCheckbox">Support Admin <br /><span className='checkboxLbl'>(Note: Tick to make it true)</span></label>
                          </div>
                        </div>
                        <div className="d-flex flex-row mb-3">
                          {/* <i className="fas fa-envelope fa-lg me-3 fa-fw"></i> */}
                          <div className="form-outline flex-fill mb-0">
                            <label className="form-label" for="form3Example3c">Signature</label>
                            <input type="file" name="txtSignature" id="txtSignature" onChange={(event) => handleFileChange(event)} className="form-control" />
                            <img src={`data:image/png;base64,${signatureFile}`} height='100px' width='100px' />

                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex justify-content-center mb-3 mt-5">
                      {/* <button type="button" className="btn btn-primary btn-lg" disabled={isLoading} onClick={doSignin}>{userReducer.loading?<LoadingSpinner />:"Submit"}</button> */}
                      <Link className="btn btn-danger btn-lg btnMarginRight" to={"/Admin/Company"}>Back</Link>
                      {isEdit ? <ButtonWithSpinner onClick={() => submitCompany()} isLoading={companyReducer.loading ? true : false} label={'Save'} /> : null}
                    </div>
                  </form>
                </div>
              </div>
            </div>
            {/* </div> */}
          </div>
        </div>
      </section>
    </div>
  );
};

export default AddCompanyDetails;