import {put, call, takeLatest, all} from 'redux-saga/effects';
import {types} from '../actions/types';
import {addSiteAPI,updateSiteAPI,adminSiteListAPI,deleteSiteAPI,addSiteExtraParameterAPI,updateExtraParametersAPI,deleteExtraParameterAPI,getSiteExtraParameterAPI} from '../../store/apis';

function* addSite({payload, error}) {
  try {
    // yield put({type: types.SEND_REQUEST_USER_LOGIN});
    // console.log('addSite',payload);
    const responseObject = yield call(addSiteAPI,payload);   
    // console.log('responseObject',responseObject); 
    // if(userObject.status=200)
    yield put({type: types.ADD_SITE_REQUEST_SUCCESS, payload: responseObject});
    // else
    //   yield put({type: types.SEND_REQUEST_USER_LOGIN_FAILURE, payload: userObject.message});
  } catch (err) {
    // console.log('appSettings',err);
    yield put({type: types.ADD_SITE_REQUEST_FAILURE, payload: err});
    // console.log(err);
  }
}
function* updateSite({payload, error}) {
  try {
    // yield put({type: types.SEND_REQUEST_USER_LOGIN});
    //console.log('editSite',payload);
    const responseObject = yield call(updateSiteAPI,payload);   
    // console.log('siteDetailsObject',siteDetailsObject); 
    // if(userObject.status=200)
    yield put({type: types.EDIT_SITE_REQUEST_SUCCESS, payload: responseObject});
    // else
    //   yield put({type: types.SEND_REQUEST_USER_LOGIN_FAILURE, payload: userObject.message});
  } catch (err) {
    // console.log('appSettings',err);
    yield put({type: types.EDIT_SITE_REQUEST_FAILURE, payload: err});
    // console.log(err);
  }
}
function* deleteSite({payload, error}) {
  try {
    // yield put({type: types.SEND_REQUEST_USER_LOGIN});
    // console.log('getSiteDetails',payload);
    const responseObject = yield call(deleteSiteAPI,payload);   
    // console.log('siteDetailsObject',siteDetailsObject); 
    // if(userObject.status=200)
    yield put({type: types.DELETE_SITE_REQUEST_SUCCESS, payload: responseObject});
    // else
    //   yield put({type: types.SEND_REQUEST_USER_LOGIN_FAILURE, payload: userObject.message});
  } catch (err) {
    // console.log('appSettings',err);
    yield put({type: types.DELETE_SITE_REQUEST_FAILURE, payload: err});
    // console.log(err);
  }
}
function* getSiteList({payload, error}) {
  try {
    // yield put({type: types.SEND_REQUEST_USER_LOGIN});
    // console.log('getSiteDetails',payload);
    const responseObject = yield call(adminSiteListAPI,payload);   
    // console.log('siteDetailsObject',siteDetailsObject); 
    // if(userObject.status=200)
    yield put({type: types.LIST_SITE_REQUEST_SUCCESS, payload: responseObject});
    // else
    //   yield put({type: types.SEND_REQUEST_USER_LOGIN_FAILURE, payload: userObject.message});
  } catch (err) {
    // console.log('appSettings',err);
    yield put({type: types.LIST_SITE_REQUEST_FAILURE, payload: err});
    // console.log(err);
  }
}
function* addSiteExtraParam({payload, error}) {
  try {
    //console.log('addSiteExtraParam',payload);
    const responseObject = yield call(addSiteExtraParameterAPI,payload);   
    //console.log('responseObject',responseObject); 
    // if(userObject.status=200)
    yield put({type: types.SITE_EXTRA_PARAMETER_SUCCESS, payload: responseObject});
    // else
    //   yield put({type: types.SEND_REQUEST_USER_LOGIN_FAILURE, payload: userObject.message});
  } catch (err) {
    // //console.log('appSettings',err);
    yield put({type: types.SITE_EXTRA_PARAMETER_FAILURE, payload: err});
    // //console.log(err);
  }
}
function* updateExtraParameters({payload, error}) {
  try {
    //console.log('addSiteExtraParam',payload);
    const responseObject = yield call(updateExtraParametersAPI,payload);   
    //console.log('responseObject',responseObject); 
    // if(userObject.status=200)
    yield put({type: types.UPDATE_SITE_EXTRA_PARAMETER_SUCCESS, payload: responseObject});
    // else
    //   yield put({type: types.SEND_REQUEST_USER_LOGIN_FAILURE, payload: userObject.message});
  } catch (err) {
    // //console.log('appSettings',err);
    yield put({type: types.UPDATE_SITE_EXTRA_PARAMETER_FAILURE, payload: err});
    // //console.log(err);
  }
}
function* deleteExtraParameter({payload, error}) {
  try {
    //console.log('addSiteExtraParam',payload);
    const responseObject = yield call(deleteExtraParameterAPI,payload);   
    //console.log('responseObject',responseObject); 
    // if(userObject.status=200)
    yield put({type: types.DELETE_SITE_EXTRA_PARAMETER_SUCCESS, payload: responseObject});
    // else
    //   yield put({type: types.SEND_REQUEST_USER_LOGIN_FAILURE, payload: userObject.message});
  } catch (err) {
    // //console.log('appSettings',err);
    yield put({type: types.DELETE_SITE_EXTRA_PARAMETER_FAILURE, payload: err});
    // //console.log(err);
  }
}
function* getSiteExtraParam({payload, error}) {
  try {
    //console.log('getSiteExtraParam',payload);
    const responseObject = yield call(getSiteExtraParameterAPI,payload);   
    //console.log('responseObject',responseObject); 
    // if(userObject.status=200)
    yield put({type: types.GET_SITE_EXTRA_PARAMETER_SUCCESS, payload: responseObject});
    // else
    //   yield put({type: types.SEND_REQUEST_USER_LOGIN_FAILURE, payload: userObject.message});
  } catch (err) {
    // console.log('appSettings',err);
    yield put({type: types.GET_SITE_EXTRA_PARAMETER_FAILURE, payload: err});
    // console.log(err);
  }
}


export default function* siteAdminSaga() {
  yield all([
    yield takeLatest(types.ADD_SITE_REQUEST, addSite),
    yield takeLatest(types.EDIT_SITE_REQUEST, updateSite),
    yield takeLatest(types.DELETE_SITE_REQUEST, deleteSite), 
    yield takeLatest(types.LIST_SITE_REQUEST, getSiteList),
    yield takeLatest(types.SITE_EXTRA_PARAMETER_REQUEST, addSiteExtraParam),
    yield takeLatest(types.UPDATE_SITE_EXTRA_PARAMETER_REQUEST, updateExtraParameters),
    yield takeLatest(types.DELETE_SITE_EXTRA_PARAMETER_REQUEST, deleteExtraParameter),    
    yield takeLatest(types.GET_SITE_EXTRA_PARAMETER_REQUEST, getSiteExtraParam),    
    // LOAD_LOGGEDIN_USER(),
  ])
}
