import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="py-3 mt-auto">
      <div className="container-fluid d-flex flex-wrap justify-content-center align-items-center">
        <p className="mb-0">
          Copyright © 2022-Present. Powered by Aqualogix. All Rights Reserved.
        </p>
        {/* <ul className="nav">
          <li className="nav-item">
            <Link className="nav-link px-2" to={"/"}>
              Privacy Policy
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link px-2" to={"/"}>
              Terms and Condition
            </Link>
          </li>
        </ul> */}
      </div>
    </footer>
  );
};

export default Footer;
