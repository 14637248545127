import axios from "axios";
import * as SERVERPATH from './serverConfig';
// import { useNavigate } from "react-router-dom";
import {CONSTANTVALUE,getData} from '../utils'

export const apiCallWithPromise=async (apiName,post)=> {
    return new Promise((resolve, reject) => {
        const URL = SERVERPATH.serverPath + apiName;
        //console.log("APICALLP-URL=",URL);
        //console.log("APICALLP-post=",post);
        axios({
            method: "POST",
            url: URL,
            data: post,
            Accept: 'application/json',
            headers: { "Content-Type": "application/json"},
        })
        .then((response) => {
            // console.log('APICALLP-response',response);
            resolve(response.data)
            // if (response.status === 200) {
            //     resolve(response.data)
            //     // return response.data;
            // } else {
            //     reject(response.data.message)
            //     // return response.data.message;
            // }
        })
        .catch(err => {
            console.log('APICALLP-err',err);
            // var strError = handleError(err)
            //console.log('APICALL-err',err)
            reject(err)
        });
    });
}
export const apiCallWithPromiseToken=async (apiName,post)=> {    
    const userToken=await getData(CONSTANTVALUE.USERTOKEN);
    return new Promise((resolve, reject) => {
        // const navigate = useNavigate();
        const URL = SERVERPATH.serverPath + apiName;
        // console.log("APICALLP-URL=",URL);
        // console.log("APICALLP-post=",post);
        // console.log("APICALLP-userToken=",userToken);
        axios({
            method: "POST",
            url: URL,
            data: post,
            Accept: 'application/json',
            headers: {authorization:userToken},
            // headers: {"Content-Type":"multipart/form-data; charset=utf-8;","x_rest_username":"AutoBay@restuser","x_rest_password":"AutoBay@Access","x_rest_version":"v2","device":"mobile","device_token":"123","language":"EN"},
            // headers: {"x-rest-username":"AutoBay@restuser","x-rest-password":"AutoBay@Access","x-rest-version":"v2"},
            // headers: {"x-rest-username":"AutoBay@restuser","x-rest-password":"AutoBay@Access","x-rest-version":"v2","device":"mobile","language":languageObject?languageObject.language_code:"en",headerVal},
        })
        .then((response) => {
            // console.log('APICALLP-response',response);
            resolve(response.data)
            // if (response.status === 200) {
            //     resolve(response.data)
            //     // return response.data;
            // } else {
            //     reject(response.data.message)
            //     // return response.data.message;
            // }
        })
        .catch(err => {
            // var strError = handleError(err)
            // console.log('APICALL-err',err)    
            resolve(err.response.data)        
            // reject(err)
        });
    });
}
export const apiCallWithPromiseTokenFormPosting=async (apiName,post)=> {    
    const userToken=await getData(CONSTANTVALUE.USERTOKEN);
    return new Promise((resolve, reject) => {
        // const navigate = useNavigate();
        const URL = SERVERPATH.serverPath + apiName;
        //console.log("APICALLP-URL=",URL);
        //console.log("APICALLP-post=",post);
        //console.log("APICALLP-userToken=",userToken);
        axios({
            method: "POST",
            url: URL,
            data: post,
            Accept: 'application/json',
            headers: {authorization:userToken, "Content-Type":"multipart/form-data; charset=utf-8;"},
            // headers: {"Content-Type":"multipart/form-data; charset=utf-8;","x_rest_username":"AutoBay@restuser","x_rest_password":"AutoBay@Access","x_rest_version":"v2","device":"mobile","device_token":"123","language":"EN"},
            // headers: {"x-rest-username":"AutoBay@restuser","x-rest-password":"AutoBay@Access","x-rest-version":"v2"},
            // headers: {"x-rest-username":"AutoBay@restuser","x-rest-password":"AutoBay@Access","x-rest-version":"v2","device":"mobile","language":languageObject?languageObject.language_code:"en",headerVal},
        })
        .then((response) => {
            //console.log('APICALLP-response',response);
            resolve(response.data)
            // if (response.status === 200) {
            //     resolve(response.data)
            //     // return response.data;
            // } else {
            //     reject(response.data.message)
            //     // return response.data.message;
            // }
        })
        .catch(err => {
            // var strError = handleError(err)
            //console.log('APICALL-err',err)            
            reject(err)
        });
    });
}
export const apiCallWithPromiseTokenPUT=async (apiName,post)=> {    
    const userToken=await getData(CONSTANTVALUE.USERTOKEN);
    return new Promise((resolve, reject) => {
        // const navigate = useNavigate();
        const URL = SERVERPATH.serverPath + apiName+"/"+post.id;
        //console.log("APICALLP-URL=",URL);
        //console.log("APICALLP-post=",post);
        //console.log("APICALLP-userToken=",userToken);
        axios({
            method: "PUT",
            url: URL,
            data: post,
            Accept: 'application/json',
            headers: {authorization:userToken},
            // headers: {"Content-Type":"multipart/form-data; charset=utf-8;","x_rest_username":"AutoBay@restuser","x_rest_password":"AutoBay@Access","x_rest_version":"v2","device":"mobile","device_token":"123","language":"EN"},
            // headers: {"x-rest-username":"AutoBay@restuser","x-rest-password":"AutoBay@Access","x-rest-version":"v2"},
            // headers: {"x-rest-username":"AutoBay@restuser","x-rest-password":"AutoBay@Access","x-rest-version":"v2","device":"mobile","language":languageObject?languageObject.language_code:"en",headerVal},
        })
        .then((response) => {
            //console.log('APICALLP-response',response);
            resolve(response.data)
            // if (response.status === 200) {
            //     resolve(response.data)
            //     // return response.data;
            // } else {
            //     reject(response.data.message)
            //     // return response.data.message;
            // }
        })
        .catch(err => {
            // var strError = handleError(err)
            //console.log('APICALL-err',err)            
            reject(err)
        });
    });
}
export const apiGetCallWithPromise=async (apiName,post)=> {
    const userToken=await getData(CONSTANTVALUE.USERTOKEN);
    return new Promise((resolve, reject) => {
        let URL = SERVERPATH.serverPath + apiName;
        if(post)
            URL = SERVERPATH.serverPath + apiName+"?"+post;
        // console.log("APICALLP-URL=",URL);
        // console.log("APICALLP-post=",post); 
        // console.log("APICALLP-userToken=",userToken);  
        // let headerToken="";
        // if(userToken)   
        //     headerToken={"Authorization":userToken};
        axios({
            method: "GET",
            url: URL,
            Accept: 'application/json',
            headers: {authorization:userToken},
            // headers: {"Content-Type":"multipart/form-data; charset=utf-8;","x_rest_username":"AutoBay@restuser","x_rest_password":"AutoBay@Access","x_rest_version":"v2","device":"mobile","device_token":"123","language":"EN"},
            // headers: {"x-rest-username":"AutoBay@restuser","x-rest-password":"AutoBay@Access","x-rest-version":"v2"},
            // headers: {"x-rest-username":"AutoBay@restuser","x-rest-password":"AutoBay@Access","x-rest-version":"v2","device":"mobile","language":languageObject?languageObject.language_code:"en",headerVal},
        })
        .then((response) => {
            // console.log('APICALLP-response',response);
            // resolve(response)
            if (response.status === 201 || response.status === 200) {
                resolve(response.data)
                // return response.data;
            } else {
                reject(response.data)
                // return response.data.message;
            }
        })
        .catch(err => {
            // if(err.response.status==400)
            // console.log('APICALLP-err',err);
            // var strError = handleError(err)
            // console.log('APICALL-err',err)
            // console.log('APICALL-err.response',err.response)
            // console.log('APICALL-err.response',err.response.status)
            reject(err.response)
        });
    });
}
export const apiGetRecordDetailsCallWithPromise=async (apiName,post)=> {
    const userToken=await getData(CONSTANTVALUE.USERTOKEN);
    return new Promise((resolve, reject) => {
        let URL = SERVERPATH.serverPath + apiName;
        if(post)
            URL = SERVERPATH.serverPath + apiName+"/"+post;
        //console.log("APICALLP-URL=",URL);
        //console.log("APICALLP-post=",post); 
        //console.log("APICALLP-userToken=",userToken);  
        // let headerToken="";
        // if(userToken)   
        //     headerToken={"Authorization":userToken};
        axios({
            method: "GET",
            url: URL,
            Accept: 'application/json',
            headers: {authorization:userToken},
            // headers: {"Content-Type":"multipart/form-data; charset=utf-8;","x_rest_username":"AutoBay@restuser","x_rest_password":"AutoBay@Access","x_rest_version":"v2","device":"mobile","device_token":"123","language":"EN"},
            // headers: {"x-rest-username":"AutoBay@restuser","x-rest-password":"AutoBay@Access","x-rest-version":"v2"},
            // headers: {"x-rest-username":"AutoBay@restuser","x-rest-password":"AutoBay@Access","x-rest-version":"v2","device":"mobile","language":languageObject?languageObject.language_code:"en",headerVal},
        })
        .then((response) => {
            //console.log('APICALLP-response',response);
            // resolve(response)
            if (response.status === 201 || response.status === 200) {
                resolve(response.data)
                // return response.data;
            } else {
                reject(response.data)
                // return response.data.message;
            }
        })
        .catch(err => {
            // var strError = handleError(err)
            //console.log('APICALL-err',err)
            // console.log('APICALL-err.response',err.response)
            reject(err.response)
        });
    });
}
export const apiDeleteCallWithPromise=async (apiName,post)=> {
    const userToken=await getData(CONSTANTVALUE.USERTOKEN);
    return new Promise((resolve, reject) => {
        const URL = SERVERPATH.serverPath + apiName+"/"+post;
        //console.log("APICALLP-URL=",URL);
        //console.log("APICALLP-post=",post);
        //console.log("APICALLP-userToken=",userToken);
        // let header='Content-Type:application/json';
        // let header='';
        // if(userToken)
        //     header="headers:{Authorization:"+userToken+"}";
        // else
        // const header=',"Authorization"':userToken
        axios({
            method: "DELETE",
            url: URL,
            data: post,
            Accept: 'application/json',
            headers:{"Authorization":userToken}
            // headers: {header},
            // headers: { "Content-Type": "application/json","Authorization":"Bearer "+ token},
            // headers: {"Content-Type":"multipart/form-data; charset=utf-8;","x_rest_username":"AutoBay@restuser","x_rest_password":"AutoBay@Access","x_rest_version":"v2","device":"mobile","device_token":"123","language":"EN"},
            // headers: {"x-rest-username":"AutoBay@restuser","x-rest-password":"AutoBay@Access","x-rest-version":"v2"},
            // headers: {"x-rest-username":"AutoBay@restuser","x-rest-password":"AutoBay@Access","x-rest-version":"v2","device":"mobile","language":languageObject?languageObject.language_code:"en",headerVal},
        })
        .then((response) => {
            //console.log('APICALLP-response',response);
            resolve(response.data)
            // if (response.status === 200) {
            //     resolve(response.data)
            //     // return response.data;
            // } else {
            //     reject(response.data.message)
            //     // return response.data.message;
            // }
        })
        .catch(err => {
            // var strError = handleError(err)
            //console.log('APICALL-err',err)
            reject(err)
        });
    });
}
export const apiDeletePostCallWithPromise=async (apiName,post)=> {
    const userToken=await getData(CONSTANTVALUE.USERTOKEN);
    return new Promise((resolve, reject) => {
        const URL = SERVERPATH.serverPath + apiName;
        //console.log("APICALLP-URL=",URL);
        //console.log("APICALLP-post=",post);
        //console.log("APICALLP-userToken=",userToken);
        // let header='Content-Type:application/json';
        // let header='';
        // if(userToken)
        //     header="headers:{Authorization:"+userToken+"}";
        // else
        // const header=',"Authorization"':userToken
        axios({
            method: "DELETE",
            url: URL,
            data: post,
            Accept: 'application/json',
            headers:{"Authorization":userToken}
            // headers: {header},
            // headers: { "Content-Type": "application/json","Authorization":"Bearer "+ token},
            // headers: {"Content-Type":"multipart/form-data; charset=utf-8;","x_rest_username":"AutoBay@restuser","x_rest_password":"AutoBay@Access","x_rest_version":"v2","device":"mobile","device_token":"123","language":"EN"},
            // headers: {"x-rest-username":"AutoBay@restuser","x-rest-password":"AutoBay@Access","x-rest-version":"v2"},
            // headers: {"x-rest-username":"AutoBay@restuser","x-rest-password":"AutoBay@Access","x-rest-version":"v2","device":"mobile","language":languageObject?languageObject.language_code:"en",headerVal},
        })
        .then((response) => {
            //console.log('APICALLP-response',response);
            resolve(response.data)
            // if (response.status === 200) {
            //     resolve(response.data)
            //     // return response.data;
            // } else {
            //     reject(response.data.message)
            //     // return response.data.message;
            // }
        })
        .catch(err => {
            // var strError = handleError(err)
            //console.log('APICALL-err',err)
            reject(err)
        });
    });
}
export const apiPUTCallWithPromise=async (apiName,post)=> {
    const userToken=await getData(CONSTANTVALUE.USERTOKEN);
    return new Promise((resolve, reject) => {
        const URL = SERVERPATH.serverPath + apiName+"/"+post.id;
        //console.log("APICALLP-URL=",URL);
        //console.log("APICALLP-post=",post);
        //console.log("APICALLP-userToken=",userToken);
        // let header='Content-Type:application/json';
        // let header='';
        // if(userToken)
        //     header="headers:{Authorization:"+userToken+"}";
        // else
        // const header=',"Authorization"':userToken
        axios({
            method: "PUT",
            url: URL,
            data: post.request,
            Accept: 'application/json',
            headers:{"Authorization":userToken}
            // headers: {header},
            // headers: { "Content-Type": "application/json","Authorization":"Bearer "+ token},
            // headers: {"Content-Type":"multipart/form-data; charset=utf-8;","x_rest_username":"AutoBay@restuser","x_rest_password":"AutoBay@Access","x_rest_version":"v2","device":"mobile","device_token":"123","language":"EN"},
            // headers: {"x-rest-username":"AutoBay@restuser","x-rest-password":"AutoBay@Access","x-rest-version":"v2"},
            // headers: {"x-rest-username":"AutoBay@restuser","x-rest-password":"AutoBay@Access","x-rest-version":"v2","device":"mobile","language":languageObject?languageObject.language_code:"en",headerVal},
        })
        .then((response) => {
            //console.log('APICALLP-response',response);
            resolve(response.data)
            // if (response.status === 200) {
            //     resolve(response.data)
            //     // return response.data;
            // } else {
            //     reject(response.data.message)
            //     // return response.data.message;
            // }
        })
        .catch(err => {
            // var strError = handleError(err)
            //console.log('APICALL-err',err)
            reject(err)
        });
    });
}

