import {types} from './types';

export function addSite(payload) {
  return {
    type: types.ADD_SITE_REQUEST,
    payload,
  };
}
export function updateSite(payload) {
  return {
    type: types.EDIT_SITE_REQUEST,
    payload,
  };
}
export function deleteSite(payload) {
  return {
    type: types.DELETE_SITE_REQUEST,
    payload,
  };
}
export function siteList(payload) {
  return {
    type: types.LIST_SITE_REQUEST,
    payload,
  };
}

export function siteExtraParameter(payload) {
  return {
    type: types.SITE_EXTRA_PARAMETER_REQUEST,
    payload,
  };
}
export function updateSiteExtraParameter(payload) {
  return {
    type: types.UPDATE_SITE_EXTRA_PARAMETER_REQUEST,
    payload,
  };
}
export function deleteSiteExtraParameter(payload) {
  return {
    type: types.DELETE_SITE_EXTRA_PARAMETER_REQUEST,
    payload,
  };
}

export function getSiteExtraParameter(payload) {
  return {
    type: types.GET_SITE_EXTRA_PARAMETER_REQUEST,
    payload,
  };
}

export function clearSiteReducer() {
  return {
    type: types.CLEAR_SITE_DATA,
  };
}

