import React, { useEffect, useState } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import PropTypes from 'prop-types';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Filler // Enables gradients
);

let data = null;

const BarChartLPCD = ({ dataList, xLabel, yLabel }) => {
  const [graphStatus, setGraphStatus] = useState(false);

  useEffect(() => {
    let labels = [];
    let countArray = [];

    dataList.map((item) => {
      labels.push(item.x);
      countArray.push(item.y);
    });

    data = {
      labels,
      datasets: [
        {
          label: "LPCD",
          data: countArray.map((count) => parseFloat(count.toFixed(5))),
          backgroundColor: 'rgba(75, 192, 192, 0.6)', // Cool turquoise shade with transparency
          borderColor: 'rgba(75, 192, 192, 1)',
          borderWidth: 2,
          borderRadius: 8, // Rounded corners
          hoverBackgroundColor: 'rgba(75, 192, 192, 0.8)', // Brighter on hover
        }
      ],
    };

    if (labels.length > 0) setGraphStatus(true);
  }, [dataList]);

  const options = {
    scales: {
      x: {
        title: {
          display: true,
          text: xLabel,
          font: {
            size: 16,
            weight: "bold",
            family: 'Arial, sans-serif',
          },
          color: '#666', // Modern text color
        },
        ticks: {
          color: '#333', // Darker tick labels
        }
      },
      y: {
        title: {
          display: true,
          text: yLabel,
          font: {
            size: 16,
            weight: "bold",
            family: 'Arial, sans-serif',
          },
          color: '#666',
        },
        ticks: {
          color: '#333',
        },
        beginAtZero: true, // Ensures chart starts at 0
      },
    },
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
        labels: {
          color: '#333', // Darker legend text
          font: {
            family: 'Arial, sans-serif',
            size: 14,
          },
        },
      },
      tooltip: {
        backgroundColor: 'rgba(0,0,0,0.8)', // Darker tooltip background
        titleColor: '#fff',
        bodyColor: '#fff',
        borderWidth: 1,
        borderColor: '#ccc',
        titleFont: {
          family: 'Arial, sans-serif',
          size: 14,
        },
        bodyFont: {
          family: 'Arial, sans-serif',
          size: 12,
        },
      },
    },
  };

    if (graphStatus) {
      
      return (
        <div style={{ position: 'relative', height : '270px', width: '100%' }}>
          <Bar options={options} data={data} />
        </div>
      );
    }
    

  return null; // In case no data is available, return nothing
};

BarChartLPCD.propTypes = {
  dataList: PropTypes.array,
  xLabel: PropTypes.string,
  yLabel: PropTypes.string,
};

export default BarChartLPCD;
