import React, { useEffect, useState } from 'react';
import { useNavigate,Link,useLocation } from "react-router-dom";
import {useDispatch, useSelector} from 'react-redux';
import PropTypes from "prop-types";
import {getCompany} from '../../../redux/actions/companyAction'
import NavBarAdmin from "../../../Components/NavBarAdmin";
import AdminMenu from "../../../Components/AdminMenu";
import {getCountryAPI,deleteCountryAPI} from '../../../store/apis';
import {isLoggedInUser, CONSTANTVALUEEXTRAPARAM,checkPermission} from '../../../utils'
import CountryList from "./countryList";
import { useToast } from '@chakra-ui/react';

const Country = ({moduleType}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(20);
  const [countryList,setCountryList] = useState([]);

  /////////////////////////////////////////////////////////////////
  /////Parameters for getting permission value/////////////////////
  /////////////////////////////////////////////////////////////////
  const [isCreate, setIsCreate] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [isView, setIsView] = useState(false); 
  const [addBtnLbl, setAddBtnLbl] = useState(null); 
  const toast = useToast()

  // const [selectedId, setSelectedId] = useState(0);
  // const [isLoading, setIsLoading] = useState(false);
  const companyReducer = useSelector(state => state.companyReducer);
  const siteAdminReducer = useSelector(state => state.siteAdminReducer);
  useEffect(()=>{
    // setIsLoading(true);
    //console.log('LogIn-useEffect');    
    init();
  },[])
  const init=()=>{
    chkLogin(); 
    setPermission();
    if(moduleType==CONSTANTVALUEEXTRAPARAM.PUMP)
      setAddBtnLbl("Add Pump");
  }
  useEffect(()=>{
    // setIsLoading(true);
    console.log('isView-useEffect',isView);    
    if(isView)
      fetchCountryList();  
    // fetchExtraParams(CONSTANTVALUEEXTRAPARAM.PUMP);
      // fetchCompanyList(); 
  },[isView])
  
  const chkLogin=async()=>{
    const isLoggedIn=await isLoggedInUser();
    // console.log("isLoggedInUser()",isLoggedIn);
    if(!isLoggedIn){
      navigate('/Admin');
    }
  }
  const setPermission=async()=>{
    setIsCreate(await checkPermission('Country','create'));
    setIsEdit(await checkPermission('Country','edit'));
    setIsDelete(await checkPermission('Country','delete'));
    setIsView(await checkPermission('Country','view'));
    // setIsCreate(true);
    // setIsEdit(true);
    // setIsDelete(true);
    // setIsView(true);
    // console.log('Company-User Permissions-isCreate='+isCreate+'-isEdit-'+isEdit+'-isDelete-'+isDelete+'-isView-'+isView);    
  }
  const fetchCountryList=async()=>{
    getCountryAPI().then((response)=>{
      // console.log('fetchCountryList-response',response);
      if(response)
        setCountryList(response.data);
        // setSelCountry(response.data[0]._id);
    })
  }
  const handlePumpEdit=(item,type)=>{
    navigate('/Admin/AddCountry',{state: {
      item: item
    }});
  }
  const handlePumpDelete=(item,type)=>{
    if (window.confirm("Are you sure you want to delete?")) {
      //console.log('User',item);
      // const request={
      //   "id": item._id,
      //   "type": type
      // }    
      deleteCountryAPI(item._id).then((response)=>{
        // console.log('fetchCountryList-response',response);
        if(response)
          fetchCountryList()
          // setSelCountry(response.data[0]._id);
      })
      
      //console.log("You pressed OK!");
    } else {
      //console.log("You pressed Cancel!");
    }    
  }
  const noViewPermission=()=>{
    return(<p class="text-center">You don't have permission to view the data. Please contact to administrator for more details</p>)
  }
  return (
    <div className="page">
      <NavBarAdmin />
      <AdminMenu selMenu="Company" />
      {/* <Header moduleType={moduleType} /> */}      
      <div className="project-page-head" style={{width: '90%', borderRight : '8px solid black', borderRadius: '20px', marginLeft: "5px", marginTop : '10px'}}>
        <div className="row m-0">
          <div className="col-12 py-4">
            <div className="sub-head">Country</div>
            <h3 className="project-title fw-bold">Country Listing</h3>
          </div>
        </div>
      </div>
      <section>      
        <div className="container">        
          <div className="row d-flex justify-content-center h-100 pt-5">
            {/* <div className="col-md-8 col-lg-8 col-xl-6"> */}
              <div className="card text-black" style={{ borderRadius: "10px" }}>
                  <div className="d-flex justify-content-end mx-2 mb-2 mb-lg-2">
                    {isCreate?<Link className="btn btn-primary mt-3" to={"/Admin/AddCountry"}>Add Country</Link>:null}
                  </div>
                <div className="card-body">
                  {isView?<CountryList data={countryList} handleEditEvent={(item)=>handlePumpEdit(item,CONSTANTVALUEEXTRAPARAM.PUMP)} handleDeleteEvent={(item)=>handlePumpDelete(item,CONSTANTVALUEEXTRAPARAM.PUMP)} isDelete={isDelete} isEdit={isEdit} />:noViewPermission()}                  
                </div>
              </div>              
            {/* </div> */}
          </div>
        </div>
      </section>
    </div>
  );
};
Country.propTypes = {
  moduleType: PropTypes.string,
};
Country.defaultProp = {
  moduleType: CONSTANTVALUEEXTRAPARAM.PUMP,
};
export default Country;
