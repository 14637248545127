import React, { useEffect, useState } from 'react';
import { useNavigate, Link } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { siteList, deleteSite } from '../../redux/actions/siteAdminAction'
import { types } from '../../redux/actions/types';
import NavBarAdmin from "../../Components/NavBarAdmin";
import AdminMenu from "../../Components/AdminMenu";
import { isEmpty, isLoggedInUser, checkPermission, getData, CONSTANTVALUE } from '../../utils'
import { apiGetCallWithPromise } from '../../store/axiosApicall';
import { Workbook } from 'exceljs';
import moment from 'moment';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Text,
  useDisclosure,
  Input,
  Code,
  Flex,
  InputGroup,
  InputLeftElement,
  Select,
} from '@chakra-ui/react'
import * as XLSX from 'xlsx';

import { apiName } from '../../store/serverConfig';
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Button,
  Box,
  Divider,
  AbsoluteCenter,
  Heading,
  useToast,
} from '@chakra-ui/react';
import { AiFillFileExcel, AiFillPrinter, AiOutlineSearch } from 'react-icons/ai';
import { useRef } from 'react';
import Loader from '../../styles/Loader';
import { doLogin } from '../../redux/actions/userAction';
import { doLoginAPI } from '../../store/apis';

// import { Alert } from 'react-bootstrap';
// import { Link } from "react-router-dom";
// import mainLogo from "../../assets/images/aqualogix-logo-b.png";
// import harGharJalLogo from "../../assets/images/hargharjal-logo-b.png";
// import ButtonWithSpinner from "../../Components/ButtonWithSpinner";

const Sites = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [listSite, setListSite] = useState([]);
  const [assignedSiteNames, setAssignedSiteNames] = useState('');
  const [page, setPage] = useState(1);
  const [selectedProject, setSelectedProject] = useState('');
  const uniqueProjects = [...new Set(listSite.map((site) => site.projectName))];
  const [mobileNumber, setMobileNumber] = useState('')
  const [password, setPassword] = useState('')
  const [limit, setLimit] = useState(20);
  const [userId, setUserId] = useState('');
  const [deleteButtonAllow, setDeleteButtonAllow] = useState(false);
  const toast = useToast()
  const [searchValue, setSearchValue] = useState('')
  const tableRef = useRef(null);



  /////////////////////////////////////////////////////////////////
  /////Parameters for getting permission value/////////////////////
  /////////////////////////////////////////////////////////////////
  const [isCreate, setIsCreate] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [isView, setIsView] = useState(false);
  const [currentSite, setCurrentSite] = useState('hello there')
  const [currentSiteId, setCurrentSiteId] = useState('')
  const [isModalOpen, setModalOpen] = useState(false);
  // const [selectedId, setSelectedId] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const siteAdminReducer = useSelector(state => state.siteAdminReducer);
  useEffect(() => {
    // setIsLoading(true);
    // console.log('LogIn-useEffect');    
    chkLogin();
    fetchAssignedSites();
    setPermission();
  }, [])
  const fontFamily = 'Work Sans'


  const handlePrint = () => {
    if (tableRef.current) {
      const tableClone = tableRef.current.cloneNode(true);

      const rows = tableClone.rows;
      for (let row of rows) {
        if (row.cells.length > 0) {
          row.deleteCell(-1);
        }
      }

      for (let row of rows) {
        if (row.cells.length > 1) {
          const cell = row.cells[row.cells.length - 2];
          cell.classList.add('align-right');
        }
      }

      const printWindow = window.open('SiteList', '_blank');
      printWindow.document.open();
      printWindow.document.write(`
        <html>
          <head>
            <title>SiteList</title>
            <style>
              /* Add your custom styles here */
              table {
                border-collapse: collapse;
                width: 100%;
              }
              th, td {
                border: 1px solid #ccc;
                padding: 8px;
                text-align: left;
              }
              th {
                background-color: #f2f2f2;
              }  
               td.align-right {
                text-align: right;
            }
            </style>
          </head>
          <body>
            <table style="font-family: ${fontFamily}">
              ${tableClone.innerHTML}
            </table>
            <script>
            document.querySelectorAll('td').forEach(cell => {
                if (!isNaN(cell.innerText) && cell.innerText.trim() !== '') {
                    cell.classList.add('align-right');
                }
            });
            </script>
          </body>
        </html>
      `);
      printWindow.document.close();
      printWindow.print();
      printWindow.close();
    }
  };

  useEffect(() => {
    // setIsLoading(true);
    // console.log('LogIn-useEffect');    
    if (isView)
      fetchSiteList();
  }, [isView])

  useEffect(() => {
    console.log("xxx v1 userId", userId)
    if (userId.length != 0) {
      fetchSite();
    }
  }, [userId])

  useEffect(() => {
    //console.log('Sites-siteAdminReducer-useEffect',siteAdminReducer);    
    if (siteAdminReducer.action) {
      if (siteAdminReducer.action === types.LIST_SITE_REQUEST_SUCCESS) {
        //console.log('Sites-siteAdminReducer-useEffect',siteAdminReducer)      
        if (siteAdminReducer.status === "success") {
          setListSite(siteAdminReducer.data.list);
          // storeData(CONSTANTVALUE.USEROBJECT,userReducer.data);
          // storeData(CONSTANTVALUE.USERTOKEN,userReducer.token);
          // navigate('/dashboard');
        } else {
          toast({
            title: siteAdminReducer.message,
            status: 'warning',
            duration: 2000,
            isClosable: true,
            position: "top-right",
            positionOptions: {
              right: "0",
              top: "0",
            },
          });
        }
      }
      else if (siteAdminReducer.action === types.DELETE_SITE_REQUEST_SUCCESS) {
        //console.log('Sites-siteAdminReducer-useEffect',siteAdminReducer)      
        if (siteAdminReducer.status === "success") {
          fetchSiteList();
          // setListSite(siteAdminReducer.data.list);
          // storeData(CONSTANTVALUE.USEROBJECT,userReducer.data);
          // storeData(CONSTANTVALUE.USERTOKEN,userReducer.token);
          // navigate('/dashboard');
        } else {
          toast({
            title: siteAdminReducer.message,
            status: 'warning',
            duration: 2000,
            isClosable: true,
            position: "top-right",
            positionOptions: {
              right: "0",
              top: "0",
            },
          });
        }
      }
    }
  }, [siteAdminReducer.action])


  const fetchAssignedSites = async () => {
    try {
      const data = await getData(CONSTANTVALUE.USEROBJECT);
      console.log("xxx v1 data", data)
      if (data && data.MobileNo) setMobileNumber(data.MobileNo)
      if (data && data.id) setUserId(data.id)
    }
    catch (error) {
      toast({
        title: `Error Occured : ${error.message}`,
        status: 'error',
        duration: 2000,
        isClosable: true,
        position: "top-right",
        positionOptions: {
          right: "0",
          top: "0",
        },
      });
    } finally {
      setIsLoading(false);
    }
  }
  const fetchSite = async () => {
    const payload = `userId=${userId}`
    try {
      const data = await apiGetCallWithPromise(apiName.getAssignedSitesByCompanyIdAPI, payload);
      // if(data && data.assignedSiteNames ) setAssignedSiteNames(data.assignedSiteNames);
      console.log("xxx v1 main data", data)
      setAssignedSiteNames(data.data)
    } catch (error) {
      toast({
        title: `Error Occured : ${error.message}`,
        status: 'error',
        duration: 2000,
        isClosable: true,
        position: "top-right",
        positionOptions: {
          right: "0",
          top: "0",
        },
      });
    } finally {

    }
  }
  const chkLogin = async () => {
    const isLoggedIn = await isLoggedInUser();
    // console.log("isLoggedInUser()",isLoggedIn);
    if (!isLoggedIn) {
      navigate('/Admin');
    }
  }
  const setPermission = async () => {
    setIsCreate(await checkPermission('Sites', 'create'));
    setIsEdit(await checkPermission('Sites', 'edit'));
    setIsDelete(await checkPermission('Sites', 'delete'));
    setIsView(await checkPermission('Sites', 'view'));

    // console.log('Sites-setPermission-Site='+await checkPermission('Sites','view')); 
    // console.log('Sites-setPermission-isCreate='+isCreate+'-isEdit-'+isEdit+'-isDelete-'+isDelete+'-isView-'+isView); 

    // if(isView)
    //   fetchSiteList();
  }
  const fetchSiteList = async () => {
    //console.log("fetchSiteList");
    dispatch(siteList("page=" + page + "&limit=" + limit + ""));
    // const isLoggedIn=await isLoggedInUser();
    // console.log("isLoggedInUser()",isLoggedIn);
    // if(isLoggedIn){
    //   navigate('/Admin/SiteListing');
    // }
  }
  const handleDeleteEvent = (item) => {
    // Alert('Are you sure you want to remove?');
    // window.confirm("What Is Your Age?");
    // setSelectedId(item._id)
    //console.log("adsf!=item",item);
    // if (window.confirm("Are you sure you want to delete?")) {
    // setTimeout(() => {
    // deleteSiteData(item._id);
    // }, 1000);
    //console.log("You pressed OK!");
    // } else {
    //console.log("You pressed Cancel!");
    // }
    setModalOpen(true);
    console.log('xxx item', item);
    setCurrentSite(item.pumpName);
    setCurrentSiteId(item.id);

    // setSelectedId(item._id);
    // const request={id:item._id};
    // dispatch(deleteSite(item._id));
  }
  const handleEditEvent = (item) => {
    //console.log('site',item);
    navigate('/Admin/AddSite', {
      state: {
        siteId: item._id,
        assignedSiteNames: item.pumpName,
      }
    });
    // navigate('/Admin/AddSite');
  }



  const exportToExcel = () => {
    if (tableRef.current) {
      const table = tableRef.current;

      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet('MQTT Data');

      // Get table rows and headers
      const rows = table.querySelectorAll('tr');
      const headerRow = Array.from(rows[0].children).slice(0, -1).map(cell => cell.textContent);
      worksheet.addRow(headerRow);

      // Add data rows
      rows.forEach((row, rowIndex) => {
        if (rowIndex > 0) {
          const rowData = Array.from(row.children).slice(0, -1).map(cell => {
            let cellValue = cell.textContent;

            if (cell.cellIndex === 0) {
              cellValue = cellValue.replace(/HP/i, '').trim();
              const numericValue = parseFloat(cellValue);
              cellValue = !isNaN(numericValue) ? numericValue : cellValue;
            }

            return cellValue;
          });

          worksheet.addRow(rowData);
        }
      });


      [4, 5, 6].forEach(colIndex => {
        worksheet.getColumn(colIndex).alignment = { horizontal: 'right' };
      });


      workbook.xlsx.writeBuffer().then(buffer => {
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `Sites_List_${moment().format('DD-MM-YYYY')}.xlsx`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      });
    }
  };
  const handleAddEvent = () => {
    //console.log('site',item);
    navigate('/Admin/AddSite', {
      state: {
        siteId: 0,
        assignedSiteNames: ''
      }
    });
    // navigate('/Admin/AddSite');
  }
  // const deleteItemClick=(item)=>{
  //   setNewsObject(item);
  //   //console.log(item);
  // }

  const changeHandler = (e) => {
    if (e.target.value.trim() == currentSite.trim()) setDeleteButtonAllow(true);
    else setDeleteButtonAllow(false);
    console.log("xxx input data", e.target.value, currentSite)
  }

  const checkAuth = async () => {

    const data = await doLoginAPI({ mobile: mobileNumber, password: password });
    console.log('THIS IS MY RESULT', data);
    if (data.status == 'success') {
      deleteSiteData();
    } else {
      toast({
        title: `Invalid Credentials`,
        status: 'error',
        duration: 2000,
        isClosable: true,
        position: "top-right",
        positionOptions: {
          right: "0",
          top: "0",
        },
      });
    }
  }
  const deleteSiteData = async () => {
    //console.log("deleteSite-selectedId",selectedId); 

    dispatch(deleteSite(currentSiteId));

    toast({
      title: `${currentSite} Has Been Deleted !!`,
      status: 'info',
      duration: 2000,
      isClosable: true,
      position: "top-right",
      positionOptions: {
        right: "0",
        top: "0",
      },
    });
    console.log('xxx deleted', currentSiteId)
    setModalOpen(false);
    setCurrentSite('');
    setCurrentSiteId('');


    // const interval = setInterval(() => {
    //   dispatch(deleteSite(selectedId));
    // }, 1000);
    // return () => clearInterval(interval);
    // console.log("deleteSite-selectedId",selectedId); 
    // dispatch(deleteSite(selectedId));
    // console.log('site',item);
  }

  const { isOpen, onOpen, onClose } = useDisclosure()
  const bindData = () => {
    return (
      <div class="table-responsive">
        {console.log("xxx checking for the loader", isLoading)}
        {isLoading ? <Loader /> :
          <TableContainer>
            <Table variant='striped' colorScheme='gray' ref={tableRef}>
              <Thead>
                <Tr>
                  <Th fontSize={'md'}>Site Name</Th>
                  <Th fontSize={'md'}>Project Name</Th>
                  <Th fontSize={'md'}>Site Type</Th>
                  <Th fontSize={'md'}>Latitude</Th>
                  <Th fontSize={'md'}>Longitude</Th>
                  <Th fontSize={'md'}>Rating</Th>
                  <Th fontSize={'md'}>IMEI</Th>
                  <Th fontSize={'md'}>Action</Th>
                </Tr>
              </Thead>
              <Tbody>
                {listSite.length > 0 ? (
                  listSite
                  // Filter based on pumpName and projectName
                  .filter((item) =>
                    item.pumpName.toString().toLowerCase().includes(searchValue.toLowerCase()) &&
                    (selectedProject ? item.projectName === selectedProject : true)
                  ).map((item, index) =>
                    assignedSiteNames.includes(item.pumpName) ? (
                      <Tr key={index}>
                        <Td>{item.pumpName ? item.pumpName.toString() : ''}</Td>
                        <Td>{item.projectName ? item.projectName.toString() : ''}</Td>
                        <Td>{item.pumpType ? item.pumpType.toString() : ''}</Td>
                        <Td>{item.lat ? item.lat.toString() : ''}</Td>
                        <Td>{item.lng ? item.lng.toString() : ''}</Td>
                        <Td>{item.rating ? item.rating.toString() : ''}</Td>
                        {console.log(item.rating)}
                        <Td>{item.IMEI ? item.IMEI : ''}</Td>
                        <Td>
                          <Button
                            colorScheme='blue'
                            onClick={() => handleEditEvent(item)}
                            size={'sm'}
                          >
                            {isEdit ? 'Update' : 'View'}
                          </Button>{' '}
                          {isDelete ? (
                            <Button
                              colorScheme='red'
                              size={'sm'}
                              onClick={() => handleDeleteEvent(item)}
                            >
                              Delete
                            </Button>
                          ) : null}
                        </Td>
                      </Tr>
                    ) : null
                  )
                ) : (
                  <Tr>
                    <Td>No Record Found</Td>
                  </Tr>
                )}
              </Tbody>
            </Table>
          </TableContainer>
        }

      </div>
    )
  }
  const noViewPermission = () => {
    return (<p class="text-center">You don't have permission to view the data. Please contact to administrator for more details</p>)
  }
  return (
    <div className="page">
      {/* function BasicUsage() {
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <>
      <Button onClick={onOpen}>Open Modal</Button> */}

      <Modal blockScrollOnMount={false} isOpen={isModalOpen} onClose={() => { setModalOpen(false) }}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            Delete Action Required !
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text fontWeight='bold' mb='1rem'>
            </Text>
            Site name :
            <Code mb='15px' ml={'4px'}>{currentSite}</Code>
            <Input placeholder='Enter your login password to delete site' onChange={(e) => { setPassword(e.target.value) }} />
            <Button mt='10px' colorScheme='red' onClick={() => checkAuth()}>Confirm</Button>
          </ModalBody>
          <ModalFooter>
          </ModalFooter>
        </ModalContent>
      </Modal>
      {console.log("xxx main sites", assignedSiteNames)}
      <NavBarAdmin />
      <AdminMenu selMenu="Site" />
      <div className="project-page-head" style={{ width: '90%', borderRight: '8px solid black', borderRadius: '20px', marginLeft: "5px", marginTop: '10px' }}>
        <div className="row m-0">
          <div className="col-12 py-4">
            <div className="sub-head">Sites</div>
            <h3 className="project-title fw-bold">Site Listing</h3>
          </div>
        </div>
      </div>
      <section>
        <div className="container">
          <Flex alignItems={'flex-end'} justifyContent={'end'} gap={'3'}>
            <Box>
              <InputGroup>
                <InputLeftElement pointerEvents='none'>
                  <AiOutlineSearch color='gray.300' />
                </InputLeftElement>
                <Input size={'md'} placeholder='Enter site name' colorScheme='blue' variant='filled' onChange={(e) => setSearchValue(e.target.value)} />
              </InputGroup>
            </Box>
          </Flex>
          <div className="row d-flex justify-content-center h-100 pt-5">
            {/* <div className="col-md-8 col-lg-8 col-xl-6"> */}
            <div className="card text-black" style={{ borderRadius: "10px" }}>
              <div style={{display : 'flex', alignItems : 'center', justifyContent : 'flex-end', gap : '20px', marginTop: "20px"}}>
              <Select
                placeholder="Select a project"
                onChange={(e) => setSelectedProject(e.target.value)}
                value={selectedProject}
                size="md"
                width="200px"
              >
                {uniqueProjects.map((project, index) => (
                  <option key={index} value={project}>
                    {project}
                  </option>
                ))}
              </Select>
                {isCreate ? <Link className="btn btn-primary " to={"/Admin/AddSite"}>Add Site</Link> : null}
              </div>
              <div className="card-body">
                {isView ? bindData() : noViewPermission()}
              </div>
            </div>
            {/* </div> */}
          </div>
          <div style={{ display: 'flex', gap: '10px', justifyContent: 'flex-end' }}>
            <Button mt='15px' colorScheme='blue' style={{ display: 'flex', gap: '5px' }} onClick={handlePrint}>
              Print
              <AiFillPrinter />
            </Button>
            <Button
              mt='15px'
              colorScheme='blue'
              style={{ display: 'flex', gap: '5px' }}
              onClick={exportToExcel}
            >
              Export to Excel
              <AiFillFileExcel />
            </Button>
          </div>
        </div>
      </section>

    </div>
  );
};

export default Sites;