import React, { useEffect, useState } from 'react';
// import { Link } from "react-router-dom";
// import PropTypes from "prop-types";
import { useNavigate,useLocation, Link } from "react-router-dom";
import {useDispatch, useSelector} from 'react-redux';
import Button from 'react-bootstrap/Button';
import NavBarAdmin from "../../../Components/NavBarAdmin";
import AdminMenu from "../../../Components/AdminMenu";
import {types} from '../../../redux/actions/types';
import {isEmpty, isLoggedInUser, CONSTANTVALUEEXTRAPARAM, sortArrayData, checkPermission} from '../../../utils'
import {addSite,updateSite,siteExtraParameter,updateSiteExtraParameter,clearSiteReducer} from '../../../redux/actions/siteAdminAction'
import { useToast } from '@chakra-ui/react';

const AddMultifunctionMeter = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const siteAdminReducer = useSelector(state => state.siteAdminReducer);
  const [multiFuncMeterMake,setMultiFuncMeterMake] = useState('');
  const [multiFuncMeterModel,setMultiFuncMeterModel] = useState('');
  const [multiFuncMeterSerialNo,setMultiFuncMeterSerialNo] = useState('');
  const [localMultiFuncMeterId, setLocalMultiFuncMeterId] = useState(null);
  const toast = useToast()

  useEffect(()=>{
    if(location){
      if(location.state){
        if(location.state.item){
          //console.log('Pressure Meter-item',location.state.item);
          setMultiFuncMeterMake(location.state.item.multiFuncMeterMake);
          setMultiFuncMeterModel(location.state.item.multiFuncMeterModel);
          setLocalMultiFuncMeterId(location.state.item._id)
        }
      }
    }    
  },[])

  useEffect(()=>{
    // console.log('AddSite-userReducer-useEffect',siteAdminReducer);    
    if(siteAdminReducer.action){
      if(siteAdminReducer.action===types.SITE_EXTRA_PARAMETER_SUCCESS){
        if(siteAdminReducer.status==="success"){          
          dispatch(clearSiteReducer());
          navigate('/Admin/Multifunctionmeter', {
            moduleType: CONSTANTVALUEEXTRAPARAM.MULTIFUNCTIONMETER
          });
        }else {
          toast({
        title: siteAdminReducer.message,
        status: 'warning',
        duration: 2000,
        isClosable: true,
        position: "top-right",
        positionOptions: {
          right: "0",
          top: "0",
        },
    });
        }
      }
      if(siteAdminReducer.action===types.UPDATE_SITE_EXTRA_PARAMETER_SUCCESS){
        if(siteAdminReducer.status==="success"){          
          //navigate('/Admin/Pump');          
          dispatch(clearSiteReducer());   
          navigate('/Admin/Multifunctionmeter', {
            moduleType: CONSTANTVALUEEXTRAPARAM.MULTIFUNCTIONMETER
          });
        }else {
          toast({
        title: siteAdminReducer.message,
        status: 'warning',
        duration: 2000,
        isClosable: true,
        position: "top-right",
        positionOptions: {
          right: "0",
          top: "0",
        },
    });
        }
      }
    }
  },[siteAdminReducer.action])

  const handleSiteInput=(e)=>{
    //////////////// Multi Function Meter Popup ////////////////////////////////////
    if(e.target.name==="txtMultiFuncMeterMake"){
      setMultiFuncMeterMake(e.target.value);
    }
    if(e.target.name==="txtMultiFuncMeterModel"){
      setMultiFuncMeterModel(e.target.value);
    }
    if(e.target.name==="txtMultiFuncMeterSerialNo"){
      setMultiFuncMeterSerialNo(e.target.value);
    }
    ////////////////////////////////////////////////////////////////
  }

  const handleMultiFuncMeterSubmit=()=>{
    if(isEmpty(multiFuncMeterMake)){
      toast({
        title: 'Please select make',
        status: 'warning',
        duration: 2000,
        isClosable: true,
        position: "top-right",
        positionOptions: {
          right: "0",
          top: "0",
        },
    })
      return;
    }else if(isEmpty(multiFuncMeterModel)){
      toast({
        title: 'Please select model',
        status: 'warning',
        duration: 2000,
        isClosable: true,
        position: "top-right",
        positionOptions: {
          right: "0",
          top: "0",
        },
    })
      return;
    }
    else{
      if(localMultiFuncMeterId){
        const request={
          "id":localMultiFuncMeterId,
          "type": CONSTANTVALUEEXTRAPARAM.MULTIFUNCTIONMETER,
          "multiFuncMeterMake":multiFuncMeterMake,
          "multiFuncMeterModel":multiFuncMeterModel,
        };
        // setCurrentField(CONSTANTVALUEEXTRAPARAM.MULTIFUNCTIONMETER);
        dispatch(updateSiteExtraParameter(request));
        // handleMultiFuncMeterShow();
      }
      else{
        const request={
          "type": CONSTANTVALUEEXTRAPARAM.MULTIFUNCTIONMETER,
          "multiFuncMeterMake":multiFuncMeterMake,
          "multiFuncMeterModel":multiFuncMeterModel,
        };
        // setCurrentField(CONSTANTVALUEEXTRAPARAM.MULTIFUNCTIONMETER);
        dispatch(siteExtraParameter(request));
        // handleMultiFuncMeterShow();
      }      
    }    
  } 
    return (
      <div className="page">
      <NavBarAdmin />
      <AdminMenu selMenu="AddMultifunctionMeter" />
      {/* <Header moduleType={moduleType} /> */}
      <section>     
        <div className="container"> 
          <div className="row d-flex justify-content-center pt-4 h-100">
            <div className="card text-black" style={{ borderRadius: "25px" }}>
              <div className="card-body pt-5">
                <div className="row justify-content-center">                  
                  <div className="col-12 order-2 order-lg-1"> 
                    <p className="h1 fw-bold mb-4 ">{localMultiFuncMeterId?"Update Multi Function Meter":"Add Multi Function Meter"}</p>
                    <div className="d-flex flex-row mb-3">
                      <div className="form-outline flex-fill mb-0">
                        <label for="multiFuncMeterMake" className="col-sm-4 col-form-label">Make</label>
                        <input type="text" name="txtMultiFuncMeterMake" id="txtMultiFuncMeterMake" value={multiFuncMeterMake} onChange={(event) => handleSiteInput(event)} className="form-control" />
                      </div>
                    </div>
                    <div className="d-flex flex-row mb-3">
                      <div className="form-outline flex-fill mb-0">
                        <label for="multiFuncMeterModel" className="col-sm-4 col-form-label">Model</label>
                        <input type="text" name="txtMultiFuncMeterModel" id="txtMultiFuncMeterModel" value={multiFuncMeterModel} onChange={(event) => handleSiteInput(event)} className="form-control" />
                      </div>
                    </div>
                    <div className="d-flex justify-content-center mb-3">
                      <Button variant="primary" onClick={()=>handleMultiFuncMeterSubmit()}>Submit</Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    )
};
export default AddMultifunctionMeter;
