// import { createSlice } from "@reduxjs/toolkit";
import {types} from '../actions/types';

const initialState = {
  loading: false,
  status:null,
  data:null,
  message:null,
  action:null,
};

const siteAdminReducer=(state = initialState, {type, payload})=>{
  switch (type) {
    case types.LIST_SITE_REQUEST:
      // console.log('LOGIN_REQUEST_APPSETTINGS');
      return {
        ...state,
        loading: true,
        // isLogin:false,
        action:types.LIST_SITE_REQUEST
      };
    case types.LIST_SITE_REQUEST_SUCCESS:
      // console.log('LOGIN_REQUEST_APPSETTINGS_SUCCESS');
      // console.log('LOGIN_REQUEST_APPSETTINGS_SUCCESS-payload',payload);
      return {
        ...state,
        status: payload.status?payload.status:null,
        data: payload.data?payload.data:null,
        message: payload.message?payload.message:null,
        loading: false,
        action:types.LIST_SITE_REQUEST_SUCCESS
      };
    case types.LIST_SITE_REQUEST_FAILURE:
      // console.log('LOGIN_REQUEST_APPSETTINGS_FAILURE');
      // console.log('LOGIN_REQUEST_APPSETTINGS_FAILURE-payload',payload);
      return {
        ...state,
        status: payload.status?payload.status:null,
        data: payload.data?payload.data:null,
        message: payload.message?payload.message:null,    
        token:null,    
        loading: false,
        action:types.LIST_SITE_REQUEST_FAILURE
      };
    case types.ADD_SITE_REQUEST:
      // console.log('LOGIN_REQUEST_APPSETTINGS');
      return {
        ...state,
        loading: true,
        // isLogin:false,
        action:types.ADD_SITE_REQUEST
      };
    case types.ADD_SITE_REQUEST_SUCCESS:
      // console.log('LOGIN_REQUEST_APPSETTINGS_SUCCESS');
      // console.log('LOGIN_REQUEST_APPSETTINGS_SUCCESS-payload',payload);
      return {
        ...state,
        status: payload.status?payload.status:null,
        data: payload.data?payload.data:null,
        message: payload.message?payload.message:null,
        loading: false,
        action:types.ADD_SITE_REQUEST_SUCCESS
      };
    case types.ADD_SITE_REQUEST_FAILURE:
      // console.log('LOGIN_REQUEST_APPSETTINGS_FAILURE');
      // console.log('LOGIN_REQUEST_APPSETTINGS_FAILURE-payload',payload);
      return {
        ...state,
        status: payload.status?payload.status:null,
        data: payload.data?payload.data:null,
        message: payload.message?payload.message:null,    
        token:null,    
        loading: false,
        action:types.ADD_SITE_REQUEST_FAILURE
      };
    case types.DELETE_SITE_REQUEST:
      // console.log('LOGIN_REQUEST_APPSETTINGS');
      return {
        ...state,
        loading: true,
        // isLogin:false,
        action:types.DELETE_SITE_REQUEST
      };
    case types.DELETE_SITE_REQUEST_SUCCESS:
      // console.log('LOGIN_REQUEST_APPSETTINGS_SUCCESS');
      // console.log('LOGIN_REQUEST_APPSETTINGS_SUCCESS-payload',payload);
      return {
        ...state,
        status: payload.status?payload.status:null,
        data: payload.data?payload.data:null,
        message: payload.message?payload.message:null,
        loading: false,
        action:types.DELETE_SITE_REQUEST_SUCCESS
      };
    case types.DELETE_SITE_REQUEST_FAILURE:
      // console.log('LOGIN_REQUEST_APPSETTINGS_FAILURE');
      // console.log('LOGIN_REQUEST_APPSETTINGS_FAILURE-payload',payload);
      return {
        ...state,
        status: payload.status?payload.status:null,
        data: payload.data?payload.data:null,
        message: payload.message?payload.message:null,    
        token:null,    
        loading: false,
        action:types.DELETE_SITE_REQUEST_FAILURE
      };
    case types.EDIT_SITE_REQUEST:
      // console.log('LOGIN_REQUEST_APPSETTINGS');
      return {
        ...state,
        loading: true,
        // isLogin:false,
        action:types.EDIT_SITE_REQUEST
      };
    case types.EDIT_SITE_REQUEST_SUCCESS:
      // console.log('LOGIN_REQUEST_APPSETTINGS_SUCCESS');
      // console.log('LOGIN_REQUEST_APPSETTINGS_SUCCESS-payload',payload);
      return {
        ...state,
        status: payload.status?payload.status:null,
        data: payload.data?payload.data:null,
        message: payload.message?payload.message:null,
        loading: false,
        action:types.EDIT_SITE_REQUEST_SUCCESS
      };
    case types.EDIT_SITE_REQUEST_FAILURE:
      // console.log('LOGIN_REQUEST_APPSETTINGS_FAILURE');
      // console.log('LOGIN_REQUEST_APPSETTINGS_FAILURE-payload',payload);
      return {
        ...state,
        status: payload.status?payload.status:null,
        data: payload.data?payload.data:null,
        message: payload.message?payload.message:null,    
        token:null,    
        loading: false,
        action:types.EDIT_SITE_REQUEST_FAILURE
      };
    case types.SITE_EXTRA_PARAMETER_REQUEST:
      // console.log('LOGIN_REQUEST_APPSETTINGS');
      return {
        ...state,
        loading: true,
        // isLogin:false,
        action:types.SITE_EXTRA_PARAMETER_REQUEST
      };
    case types.SITE_EXTRA_PARAMETER_SUCCESS:
      // console.log('LOGIN_REQUEST_APPSETTINGS_SUCCESS');
      // console.log('LOGIN_REQUEST_APPSETTINGS_SUCCESS-payload',payload);
      return {
        ...state,
        status: payload.status?payload.status:null,
        data: payload.data?payload.data:null,
        message: payload.message?payload.message:null,
        loading: false,
        action:types.SITE_EXTRA_PARAMETER_SUCCESS
      };
    case types.SITE_EXTRA_PARAMETER_FAILURE:
      // console.log('LOGIN_REQUEST_APPSETTINGS_FAILURE');
      // console.log('LOGIN_REQUEST_APPSETTINGS_FAILURE-payload',payload);
      return {
        ...state,
        status: payload.status?payload.status:null,
        data: payload.data?payload.data:null,
        message: payload.message?payload.message:null,    
        token:null,    
        loading: false,
        action:types.SITE_EXTRA_PARAMETER_FAILURE
      };
    case types.GET_SITE_EXTRA_PARAMETER_REQUEST:
      // console.log('LOGIN_REQUEST_APPSETTINGS');
      return {
        ...state,
        loading: true,
        // isLogin:false,
        action:types.GET_SITE_EXTRA_PARAMETER_REQUEST
      };
    case types.GET_SITE_EXTRA_PARAMETER_SUCCESS:
      // console.log('LOGIN_REQUEST_APPSETTINGS_SUCCESS');
      // console.log('LOGIN_REQUEST_APPSETTINGS_SUCCESS-payload',payload);
      return {
        ...state,
        status: payload.status?payload.status:null,
        data: payload.data?payload.data:null,
        message: payload.message?payload.message:null,
        loading: false,
        action:types.GET_SITE_EXTRA_PARAMETER_SUCCESS
      };
    case types.GET_SITE_EXTRA_PARAMETER_FAILURE:
      // console.log('LOGIN_REQUEST_APPSETTINGS_FAILURE');
      // console.log('LOGIN_REQUEST_APPSETTINGS_FAILURE-payload',payload);
      return {
        ...state,
        status: payload.status?payload.status:null,
        data: payload.data?payload.data:null,
        message: payload.message?payload.message:null,    
        token:null,    
        loading: false,
        action:types.GET_SITE_EXTRA_PARAMETER_FAILURE
      };
    case types.UPDATE_SITE_EXTRA_PARAMETER_REQUEST:
      // console.log('LOGIN_REQUEST_APPSETTINGS');
      return {
        ...state,
        loading: true,
        // isLogin:false,
        action:types.UPDATE_SITE_EXTRA_PARAMETER_REQUEST
      };
    case types.UPDATE_SITE_EXTRA_PARAMETER_SUCCESS:
      // console.log('LOGIN_REQUEST_APPSETTINGS_SUCCESS');
      // console.log('LOGIN_REQUEST_APPSETTINGS_SUCCESS-payload',payload);
      return {
        ...state,
        status: payload.status?payload.status:null,
        data: payload.data?payload.data:null,
        message: payload.message?payload.message:null,
        loading: false,
        action:types.UPDATE_SITE_EXTRA_PARAMETER_SUCCESS
      };
    case types.UPDATE_SITE_EXTRA_PARAMETER_FAILURE:
      // console.log('LOGIN_REQUEST_APPSETTINGS_FAILURE');
      // console.log('LOGIN_REQUEST_APPSETTINGS_FAILURE-payload',payload);
      return {
        ...state,
        status: payload.status?payload.status:null,
        data: payload.data?payload.data:null,
        message: payload.message?payload.message:null,    
        token:null,    
        loading: false,
        action:types.UPDATE_SITE_EXTRA_PARAMETER_FAILURE
      };
    case types.DELETE_SITE_EXTRA_PARAMETER_REQUEST:
      // console.log('LOGIN_REQUEST_APPSETTINGS');
      return {
        ...state,
        loading: true,
        // isLogin:false,
        action:types.DELETE_SITE_EXTRA_PARAMETER_REQUEST
      };
    case types.DELETE_SITE_EXTRA_PARAMETER_SUCCESS:
      // console.log('LOGIN_REQUEST_APPSETTINGS_SUCCESS');
      // console.log('LOGIN_REQUEST_APPSETTINGS_SUCCESS-payload',payload);
      return {
        ...state,
        status: payload.status?payload.status:null,
        data: payload.data?payload.data:null,
        message: payload.message?payload.message:null,
        loading: false,
        action:types.DELETE_SITE_EXTRA_PARAMETER_SUCCESS
      };
    case types.DELETE_SITE_EXTRA_PARAMETER_FAILURE:
      // console.log('LOGIN_REQUEST_APPSETTINGS_FAILURE');
      // console.log('LOGIN_REQUEST_APPSETTINGS_FAILURE-payload',payload);
      return {
        ...state,
        status: payload.status?payload.status:null,
        data: payload.data?payload.data:null,
        message: payload.message?payload.message:null,    
        token:null,    
        loading: false,
        action:types.DELETE_SITE_EXTRA_PARAMETER_FAILURE
      };  
    case types.CLEAR_SITE_DATA:
      // console.log('LOGIN_REQUEST_APPSETTINGS');
      return {
        loading: false,
        status:null,
        data:null,
        message:null,
        action:null,
      };
    default:
      return state;
  }
}

export default siteAdminReducer;