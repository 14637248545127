import React, { useState } from 'react';
import NavBarAdmin from './NavBarAdmin';
import AdminMenu from './AdminMenu';
import { useEffect } from 'react';
import Popup from './Card/Popup';
import { useToast, Table, Thead, Tbody, Tr, Th, Td, Button, Tooltip } from '@chakra-ui/react';
import { getAssignedSiteListAPI, getCompleteAssignedSitesByUserIdAPI, getSiteListWithProjectsAPI, getUserSitesReportsAPI } from '../store/apis';
import { Box, Flex, FormControl, FormLabel, Select } from "@chakra-ui/react";
import { AiFillCloseCircle, AiFillCloseSquare, AiFillDelete, AiFillFileExcel, AiFillFilter, AiFillFormatPainter, AiFillPrinter, AiFillRest } from 'react-icons/ai';
import { MdClear, MdClose, MdConstruction, MdResetTv, MdVerifiedUser } from 'react-icons/md';
import { CONSTANTVALUE, getData } from '../utils';
import * as XLSX from 'xlsx';
import { Pagination } from 'antd';
import { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { apiGetCallWithPromise } from '../store/axiosApicall';
import { apiName } from '../store/serverConfig';
import { useDispatch } from 'react-redux';
import { getAssignedSiteAction } from '../redux/actions/userAction';
 
const UserAndSiteReports = ({ noHeaders }) => {
    let filter = false;
    const [projects, setProjects] = useState();
 
    const [selectedSite, setSelectedSite] = useState('ALL');
    const [selectedEmploymentType, setSelectedEmploymentType] = useState('ALL');
    const [selectedSites, setSelectedSites] = useState();
    const [selectedLogItem, setSelectedLogItem] = useState(null);
    const [data, setData] = useState([]);
    const [pageSize, setPageSize] = useState(10);
    const tableRef = useRef(null);
    const [assignedSites, setAssignedSites] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [isLoadingMore, setIsLoadingMore] = useState(false);
    const [popupMessage, setPopupMessage] = useState(false);
    const [limit, setLimit] = useState(50);
    const [dataExhausted, setDataExhausted] = useState(false);
    const rate = 50;
    const [assignedProjectList, setAssignedProjectList] = useState([]);
    const [filterSiteName, setFilterSiteName] = useState('');
    const [selectedProject, setSelectedProject] = useState();
    const [filterProjectName, setFilterProjectName] = useState('');
    useEffect(() => {
        const p = localStorage.getItem('selectedProjectName');
        setTimeout(() => {
            if (p) {
                setFilterProjectName(p)
                setSelectedProject(p)
                console.log("HIIIH", p);
            }
        }, 2000)
    }, [])
    const [filterOperatorName, setFilterOperatorName] = useState('');
    const [filterDesignation, setFilterDesignation] = useState('');
    const [filterEmployment, setFilterEmployment] = useState('');
    const [uniqueSiteNames, setUniqueSiteNames] = useState([]);
    const [uniqueOperatorNames, setUniqueOperatorNames] = useState([]);
    const [uniqueProjectNames, setUniqueProjectNames] = useState([]);
    const [uniqueDesignation, setUniquqDesignation] = useState([]);
    const [uniqueEmploymentType, setUniqueEmploymentType] = useState([]);
    const dispatch = useDispatch();
 
    useEffect(() => {
        fetchUniqueSiteNamesAndOperatorNames();
    }, [data]);
 
 
    const fetchUniqueSiteNamesAndOperatorNames = () => {
        const siteNamesSet = new Set(data.map((row) => row.siteName));
        const operatorNamesSet = new Set(data.map((row) => row.OperatorName));
        const projectNameSet = new Set(data.map((row) => row.projectName));
        const designationSet = new Set(data.map((item) => item.Designation))
        const employmentTypeSet = new Set(data.map((item) => item.EmploymentType))
 
 
        const siteNames = Array.from(siteNamesSet);
        const operatorNames = Array.from(operatorNamesSet);
        const projectNames = Array.from(projectNameSet)
        const desginations = Array.from(designationSet);
        const employmentType = Array.from(employmentTypeSet);
        // console.log("employmentType",projectNameSet)
 
        setUniqueSiteNames(siteNames);
        setUniqueOperatorNames(operatorNames)
        setUniqueProjectNames(projectNames)
        setUniquqDesignation(desginations)
        setUniqueEmploymentType(employmentType)
    };
 
 
    useEffect(() => {
        fetchUserSiteReports();
        fetch()
    }, [])
 
    useEffect(() => {
        handleUpdate();
    }, [limit])
 
    useEffect(() => {
        if (popupMessage) {
            const timer = setTimeout(() => {
                setPopupMessage(false);
            }, 1000);
            return () => clearTimeout(timer);
        }
    }, [popupMessage]);
 
    const toast = useToast();
    const navigate = useNavigate()
 
    const fetchUserSiteReports = async () => {
        try {
            const user = await getData(CONSTANTVALUE.USEROBJECT);
            const userData = await getUserSitesReportsAPI(`userId=${user.id}`);
 
            // let projectDat = assignedProject.map((item) => {
            //     return {
            //         _id: item.result._id,
            //         siteId: item._id,
            //         projectName: item.result.ProjectName
            //     };
            // });
 
            // console.log("This are my assigned project", projectDat)
            // setAssignedProjectList(projectDat)
            // console.log('xxx data', userData)
            if (userData && userData.data) setData(userData.data);
        } catch (err) {
            console.log(err.message)
        }
    }
    const exportToExcel = () => {
        if (tableRef.current) {
 
            const tableClone = tableRef.current.cloneNode(true);
 
            const rows = tableClone.querySelectorAll('tr');
            rows.forEach(row => {
                if (row.children.length > 0) {
                    row.removeChild(row.lastElementChild);
                }
            });
 
            const worksheet = XLSX.utils.table_to_sheet(tableClone);
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, 'MQTT Data');
 
            rows.forEach(row => {
                const cell = row.insertCell(-1);
                cell.innerHTML = row.lastElementChild.dataset.originalContent || '';
            });
 
            XLSX.writeFile(workbook, 'User_Site_Report.xlsx');
        }
    };
 
 
    const fontFamily = 'Work Sans'
 
    const handlePrint = () => {
        if (tableRef.current) {
            // Deep clone the table to avoid modifying the original table
            const tableClone = tableRef.current.cloneNode(true);
 
            // Remove the last column from each row in the cloned table
            const rows = tableClone.rows;
            for (let row of rows) {
                if (row.cells.length > 0) {
                    row.deleteCell(-1); // Delete last cell
                }
            }
 
            const printWindow = window.open('UserSiteReport', '_blank');
            printWindow.document.open();
            printWindow.document.write(`
      <html>
        <head>
          <title>UserSiteReport</title>
          <style>
            /* Add your custom styles here */
            table {
              border-collapse: collapse;
              width: 100%;
            }
            th, td {
              border: 1px solid #ccc;
              padding: 8px;
              text-align: left;
            }
            th {
              background-color: #f2f2f2;
            }
          </style>
        </head>
        <body>
          <table style="font-family: ${fontFamily}">
            ${tableClone.innerHTML}
          </table>
        </body>
      </html>
    `);
            printWindow.document.close();
            printWindow.print();
            printWindow.close();
        }
    };
 
    const fetch = async () => {
 
        const data = await getData(CONSTANTVALUE.USEROBJECT);
        const info = await getSiteListWithProjectsAPI(data.id)
        const filteredProjects = info.message.filter((item) => item.project && item.sites.length > 0);
        setProjects(filteredProjects);
        const sites = await getCompleteAssignedSitesByUserIdAPI(data.id);
        setAssignedSites(sites.message.map((item) => item.result));
        // console.log("JIJIJIJ",sites.message.map((item) => item.result))
    }
 
 
 
    useEffect(() => {
 
        if (selectedProject !== 'ALL') {
            const selectedProjectData = projects?.find((item) => item.project.ProjectName === selectedProject);
            setSelectedSites(selectedProjectData ? selectedProjectData.sites : []);
            console.log("JIJIJIJ2", selectedSites)
            handlePageChange(1)
        } else {
 
            setSelectedSites([]);
        }
    }, [selectedProject]);
 
    // useEffect(() => {
 
    //     console.log("JIJIJIJ",filterProjectName)
    //     if (selectedProject !== 'ALL') {
    //         const selectedProjectData = projects?.find((item) => item.project.ProjectName === selectedProject);
    //         setSelectedSites(selectedProjectData ? selectedProjectData.sites : []);
    //     } else {
 
    //         setSelectedSites([]);
    //     }
    // }, []);
 
    const handleUpdate = async (row) => {
        console.log("Row", row);
        try {
            const userData = await getUserSitesReportsAPI();
            if (userData.data.length == 0 || userData.data.length == data.length) setDataExhausted(true);
        } catch (error) {
            console.log("err", error.message);
        }
    };
 
 
    const handlePageSizeChange = (event) => {
        setPageSize(Number(event.target.value));
        setCurrentPage(1);
    };
 
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };
 
    const handleLoadMore = () => {
        setLimit(limit + rate);
    };
 
    // const filteredData = data.filter((row) => {
 
    //     const siteNameMatch = filterSiteName ? row.siteName.toLowerCase().includes(filterSiteName.toLowerCase()) : true;
    //     const projectNameMatch = filterProjectName ? row.projectName.toLowerCase().includes(filterProjectName.toLowerCase()) : true;
    //     const operatorNameMatch = filterOperatorName ? row.OperatorName.toLowerCase().includes(filterOperatorName.toLowerCase()) : true;
    //     const designationMatch = filterDesignation ? row.Designation.toLowerCase().includes(filterDesignation.toLowerCase()) : true;
    //     const employmentTypeMatch = filterEmployment ? row.EmploymentType.toLowerCase().includes(filterEmployment.toLowerCase()) : true;
    //     return siteNameMatch && operatorNameMatch && projectNameMatch && employmentTypeMatch;
    // });
    const filteredData = data.filter((row) => {
        const projectNameMatch = filterProjectName !== 'ALL' ? row.projectName?.toLowerCase() === selectedProject?.toLowerCase() : true;
        const siteNameMatch = selectedSite !== 'ALL' ? row.siteName.toLowerCase() === selectedSite.toLowerCase() : true;
        const employmentTypeMatch = selectedEmploymentType !== 'ALL' ? row.EmploymentType.toLowerCase() === selectedEmploymentType.toLowerCase() : true;
        return projectNameMatch && siteNameMatch && employmentTypeMatch;
    });
 
 
 
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    const currentRecords = filteredData.slice(startIndex, endIndex);
    console.log(selectedSite)
    const paginationSize = currentRecords.length;
    console.log("JOJIOIPJOIJOI", paginationSize);
    const renderTableRows = () => {
        return currentRecords.map((row, index) => (
            <Tr key={index}>
                <Td>{row.OperatorName}</Td>
                <Td>{row.projectName}</Td>
                <Td>{row.siteName}</Td>
                <Td>{row.Designation}</Td>
                <Td>{row.EmploymentType}</Td>
                <Td><Button colorScheme={'blue'} onClick={() => goToEditSites(row)}>Update</Button></Td>
            </Tr>
        ));
    };
 
    const goToEditSites = (info) => {
        navigate('/Admin/AddUser', { state: { userId: info.userId, dontShow: true } })
    }
 
    const renderPagination = () => {
        const totalPages = Math.ceil(filteredData.length / pageSize);
        if (totalPages <= 1) return null;
 
        return (
            <Pagination
                current={currentPage}
                total={filteredData.length}
                pageSize={pageSize}
                onChange={handlePageChange}
                showSizeChanger={false}
               
            />
        );
    };
    // const renderPagination = () => {
    //     const totalPages = Math.ceil(data.length / pageSize);
 
    //     if (totalPages <= 1) return null; 
 
    //     const pagesToShow = 5;
    //     const startPages = 2;
    //     const endPages = 2;
    //     const aroundCurrent = 2;
 
    //     const generatePageNumbers = () => {
    //         let pageNumbers = [];
 
    //         for (let i = 1; i <= Math.min(startPages, totalPages); i++) {
    //             pageNumbers.push(i);
    //         }
 
    //         if (currentPage > startPages + aroundCurrent + 1) {
    //             pageNumbers.push("...");
    //         }
 
    //         const start = Math.max(currentPage - aroundCurrent, startPages + 1);
    //         const end = Math.min(currentPage + aroundCurrent, totalPages - endPages);
    //         for (let i = start; i <= end; i++) {
    //             pageNumbers.push(i);
    //         }
 
    //         if (currentPage < totalPages - endPages - aroundCurrent) {
    //             pageNumbers.push("...");
    //         }
 
    //         for (let i = Math.max(totalPages - endPages + 1, startPages + 1); i <= totalPages; i++) {
    //             pageNumbers.push(i);
    //         }
 
    //         return pageNumbers;
    //     };
 
    //     const pageNumbers = generatePageNumbers();
 
    //     return (
    //         <Pagination
    //             current={currentPage}
    //             total={data.length}
    //             pageSize={pageSize}
    //             onChange={handlePageChange}
    //             showSizeChanger={false}
    //         />
    //     );
    // };
    const handleReset = () => {
        setPageSize(10);
        setSelectedSite("ALL")
        setFilterSiteName('ALL');
        setFilterOperatorName('');
        setFilterProjectName('ALL');
    };
 
    return (
        <div className={noHeaders ? null : "page"}>
            {!noHeaders && <NavBarAdmin />}
 
            {!noHeaders && <AdminMenu selMenu="UserSiteReports" />}
            <div className="project-page-head" style={{ width: '90%', borderRight: '8px solid black', borderRadius: '20px', marginLeft: "5px", marginTop: '10px' }}>
                <div className="row m-0">
                    <div className="col-12 py-4">
                        <div className="sub-head" style={{ display: "flex", alignItems: 'center' }}>User Site Reports <MdConstruction color='#2b6cb0' style={{ marginLeft: '7px' }} /> </div>
                        <h3 className="project-title fw-bold">User Site Reports</h3>
                    </div>
                </div>
            </div>
 
 
            <section>
                <div className="container">
                    <div className="row d-flex justify-content-center h-100 pt-5">
                        <div className="card text-black" style={{ borderRadius: '10px' }}>
                            <div className="card-body">
                                <Box mb={5}>
                                    <Box>
                                        <Flex justifyContent={'space-between'}>
                                            <Box ml={1}>
                                                Page Size :
                                            </Box>
                                            <Box>
                                                Page : {currentPage}
                                            </Box>
                                        </Flex>
                                    </Box>
                                    <Box mb={5} mt={3}>
                                        <Flex flexWrap="wrap" justifyContent="end" alignItems="center">
                                            {/* Page Size Input */}
                                            <Box width={["100%", "40%"]} pr={[0, 3]} mb={[3, 0]}>
                                                <FormControl>
                                                    <Select
                                                        id="pageSizeSelect"
                                                        className="form-select"
                                                        value={pageSize}
                                                        onChange={handlePageSizeChange}
                                                    >
                                                        <option value={10}>10</option>
                                                        <option value={20}>20</option>
                                                        <option value={30}>30</option>
                                                    </Select>
                                                </FormControl>
                                            </Box>
                                            <Box width={["100%", "18%"]} pr={[0, 3]} mb={[3, 0]}>
                                                <FormControl>
                                                    <Select
                                                        id="projectSelect"
                                                        value={filterProjectName}
                                                        variant={'filled'}
                                                        onChange={(e) => {
                                                            setSelectedProject(e.target.value)
                                                            setFilterProjectName(e.target.value)
                                                            setSelectedSite("ALL")
                                                        }
                                                        }
                                                        color="black"
                                                    > <option value={'ALL'}>Select Project</option>
                                                        {projects?.sort((a, b) => a.project.ProjectName.localeCompare(b.project.ProjectName)).map((item) => (
                                                            <option key={item.project._id} value={item.project.ProjectName}>
                                                                {item.project.ProjectName}
                                                            </option>
                                                        ))}
                                                    </Select>
 
                                                </FormControl>
                                            </Box>
 
                                            <Box width={["100%", "18%"]} pr={[0, 3]} mb={[3, 0]}>
                                                <FormControl>
                                                    {selectedProject !== 'ALL' && (
                                                        <Select
                                                            variant={'filled'}
                                                            ml={2}
                                                            // value={formData.siteName}
                                                            onChange={(e) => {
                                                                const selectedOption = e.target.selectedOptions[0];
                                                                const selectedText = selectedOption.text;
                                                                const selectedValue = selectedOption.value;
 
                                                                console.log('Selected Option Text:', selectedText);
                                                                console.log('Selected Option Value:', selectedValue);
                                                                // Select Site
                                                                // ALL 
                                                                if (selectedText === "Select Site") {
                                                                    setSelectedSite(selectedValue);
 
                                                                } else {
 
                                                                    setSelectedSite(selectedText);
                                                                }
                                                            }
                                                            }
                                                            color="black"
                                                        >
                                                            <option value={'ALL'}>Select Site</option>
                                                            {selectedSites?.sort((a, b) => a.pumpName.localeCompare(b.pumpName)).map((site) => (
                                                                <option key={site._id} value={site._id} imei={site.IMEI}>
                                                                    {site.pumpName}
                                                                </option>
                                                            ))}
                                                        </Select>
                                                    )}
 
                                                </FormControl>
                                            </Box>
 
 
                                            <Box width={["100%", "18%"]} pr={[0, 3]} mb={[3, 0]}>
                                                <FormControl>
                                                    <Select
                                                        id="employmentTypeSelect"
                                                        value={selectedEmploymentType}
                                                        variant={'filled'}
                                                        onChange={(e) => setSelectedEmploymentType(e.target.value)}
                                                        color="black"
                                                        ml={2}
                                                    >
                                                        <option value={'ALL'}>Employment Type</option>
                                                        {['Operator', 'Admin', 'Contractor', 'Desire'].map((type) => (
                                                            <option key={type} value={type}>
                                                                {type}
                                                            </option>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </Box>
                                            <Box width={["100%", "6%"]} pr={[0, 3]} mb={[3, 0]}>
                                                <Tooltip label="Reset">
                                                    <Button onClick={handleReset} colorScheme='blue' size='md'>
                                                        <MdClose />
                                                    </Button>
                                                </Tooltip>
                                            </Box>
                                        </Flex>
                                    </Box>
                                </Box>
                                {/* <div className="table-responsive"> */}
                                <Table variant='striped' colorScheme='gray' size='md' ref={tableRef} >
                                    <Thead>
                                        <Tr>
                                            <Th>User</Th>
                                            <Th>Project</Th>
                                            <Th>Site</Th>
                                            <Th>Designation</Th>
                                            <Th>Employment Type</Th>
                                            <Th>Options</Th>
                                        </Tr>
                                    </Thead>
                                    <Tbody>{renderTableRows()}</Tbody>
                                </Table>
                                {/* </div> */}
                                {/* <div className="d-flex justify-content-center mt-3">
                                    {isLoadingMore ? (
                                        <button className="btn btn-secondary" disabled>
                                            Loading...
                                        </button>
                                    ) : (
                                        <button className="btn btn-primary" onClick={handleLoadMore} disabled={dataExhausted}>
                                            {dataExhausted ? "No More Data" : "Load More"}
                                        </button>
                                    )}
                                </div> */}
 
                            </div>
                        </div>
                    </div>
                    <div className=" d-flex justify-content-center mt-3">
                        {renderPagination()}
                    </div>
                    <div style={{ display: 'flex', gap: '10px', justifyContent: 'flex-end' }}>
                        <Button mt='15px' colorScheme='blue' style={{ display: 'flex', gap: '5px' }} onClick={handlePrint}>
                            Print
                            <AiFillPrinter />
                        </Button>
                        <Button
                            mt='15px'
                            colorScheme='blue'
                            style={{ display: 'flex', gap: '5px' }}
                            onClick={exportToExcel}
                        >
                            Export to Excel
                            <AiFillFileExcel />
                        </Button>
                    </div>
                </div>
            </section>
        </div>
    )
}
 
export default UserAndSiteReports