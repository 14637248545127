import {put, takeLatest, all} from 'redux-saga/effects';
import {types} from '../actions/types';
// import {newsListAPI} from '../../store/apis';

function* setIMEINumber({payload, error}) {
  try {
    // yield put({type: types.SEND_REQUEST_USER_LOGIN});
    // console.log('getSiteList',payload);
    // const newsArray = yield call(newsListAPI,payload);   
    console.log('settingSaga-setIMEINumber-payload',payload); 
    const response={
      loading: false,
      status:"success",
      data:null,
      message:"IMEI number saved successfully",
      action:null,
      IMEINumber:payload,
    };
    // if(userObject.status=200)
    yield put({type: types.SET_IMEI_NUMBER_SUCCESS, payload: response});
    // else
    //   yield put({type: types.SEND_REQUEST_USER_LOGIN_FAILURE, payload: userObject.message});
  } catch (err) {
    // console.log('appSettings',err);
    yield put({type: types.SET_IMEI_NUMBER_FAILURE, payload: err});
    // console.log(err);
  }
}
export default function* settingSaga() {
  yield all([
    yield takeLatest(types.SET_IMEI_NUMBER, setIMEINumber),
    // LOAD_LOGGEDIN_USER(),
  ])
}
