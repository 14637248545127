import React, { useState, useEffect } from 'react';

const Popup = ({ message, setOff }) => {
  const [isVisible, setIsVisible] = useState(true);



  const popupStyle = {
    position: 'fixed',
    left: '50%',
    bottom: isVisible ? '50%' : '-100px',
    transform: isVisible ? 'translate(-50%, 0)' : 'translate(-50%, 100%)',
    backgroundColor: 'white',
    color: 'black',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    height: '100px',
    width: '200px',
    padding: '10px 20px',
    border: '1px solid black',
    zIndex: '999',
    borderRadius: '4px',
    opacity: isVisible ? 1 : 0,
    visibility: isVisible ? 'visible' : 'hidden',
    transition: 'opacity 0.3s ease-in-out, transform 0.3s ease-in-out, bottom 0.3s ease-in-out',
    transitionProperty: 'opacity, transform, bottom', // Added transitionProperty
  };
  

  const contentStyle = {
    margin: 0
  };

  return (
    <div style={popupStyle}>
      <p style={contentStyle}>{message}</p>
    </div>
  );
};

export default Popup;
