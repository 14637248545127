import React, { useEffect, useState } from 'react';
// import { Link } from "react-router-dom";
// import PropTypes from "prop-types";
import { useNavigate,useLocation, Link } from "react-router-dom";
import {useDispatch, useSelector} from 'react-redux';
import Button from 'react-bootstrap/Button';
import NavBarAdmin from "../../../Components/NavBarAdmin";
import AdminMenu from "../../../Components/AdminMenu";
import {types} from '../../../redux/actions/types';
import {isEmpty, isLoggedInUser, CONSTANTVALUEEXTRAPARAM, sortArrayData, checkPermission} from '../../../utils'
import {addSite,updateSite,siteExtraParameter,updateSiteExtraParameter,clearSiteReducer} from '../../../redux/actions/siteAdminAction'
import { useToast } from '@chakra-ui/react';

const AddChlorineSensor = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const siteAdminReducer = useSelector(state => state.siteAdminReducer);
  const [clrnSensorMake, setClrnSensorMake] = useState('');
  const [clrnSensorModel, setClrnSensorModel] = useState('');
  const [clrnSensorMaxVal, setClrnSensorMaxVal] = useState('');
  const [localChlorineSensorId, setLocalChlorineSensorId] = useState(null); 
  const [currentField, setCurrentField] = useState('sitedetails');

  const toast = useToast();
  
  useEffect(()=>{
    if(location){
      if(location.state){
        if(location.state.item){
          //console.log('Level Sensor ',location.state.item);
          setClrnSensorMake(location.state.item.chlorineSensorMake);
          setClrnSensorModel(location.state.item.chlorineSensorModel);
          setClrnSensorMaxVal(location.state.item.chlorineSensorMaxValue);
          setLocalChlorineSensorId(location.state.item._id)
        }
      }
    }    
  },[])  
  useEffect(()=>{
    // console.log('AddSite-userReducer-useEffect',siteAdminReducer);    
    if(siteAdminReducer.action){
      if(siteAdminReducer.action===types.SITE_EXTRA_PARAMETER_SUCCESS){
        if(siteAdminReducer.status==="success"){          
          dispatch(clearSiteReducer());
          navigate('/Admin/Chlorinesensor', {
            moduleType: CONSTANTVALUEEXTRAPARAM.CHLORINESENSOR
          });
        }else {
          toast({
        title: siteAdminReducer.message,
        status: 'warning',
        duration: 2000,
        isClosable: true,
        position: "top-right",
        positionOptions: {
          right: "0",
          top: "0",
        },
    });
        }
      }
      if(siteAdminReducer.action===types.UPDATE_SITE_EXTRA_PARAMETER_SUCCESS){
        if(siteAdminReducer.status==="success"){          
          //navigate('/Admin/Pump');          
          dispatch(clearSiteReducer());   
          navigate('/Admin/Chlorinesensor', {
            moduleType: CONSTANTVALUEEXTRAPARAM.CHLORINESENSOR
          });
        }else {
          toast({
        title: siteAdminReducer.message,
        status: 'warning',
        duration: 2000,
        isClosable: true,
        position: "top-right",
        positionOptions: {
          right: "0",
          top: "0",
        },
    });
        }
      }
    }
  },[siteAdminReducer.action])

  const handleSiteInput=(e)=>{
    //////////////// Chlorine Sensor ////////////////////////////////////
    if(e.target.name==="txtClrnSensorMake"){
      setClrnSensorMake(e.target.value);
    }
    if(e.target.name==="txtClrnSensorModel"){
      setClrnSensorModel(e.target.value);
    }
    if(e.target.name==="txtClrnSensorMaxVal"){
      setClrnSensorMaxVal(e.target.value);
    }
    ////////////////////////////////////////////////////////////////
  }

  const handleClrnSensorSubmit=()=>{
    if(isEmpty(clrnSensorMake)){
      toast({
        title: 'Please select make',
        status: 'warning',
        duration: 2000,
        isClosable: true,
        position: "top-right",
        positionOptions: {
          right: "0",
          top: "0",
        },
    })
      return;
    }else if(isEmpty(clrnSensorModel)){
      toast({
        title: 'Please select model',
        status: 'warning',
        duration: 2000,
        isClosable: true,
        position: "top-right",
        positionOptions: {
          right: "0",
          top: "0",
        },
    })
      
      return;
    }else if(isEmpty(clrnSensorMaxVal)){
      toast({
        title: 'Please select max value',
        status: 'warning',
        duration: 2000,
        isClosable: true,
        position: "top-right",
        positionOptions: {
          right: "0",
          top: "0",
        },
    })
      return;
    }
    else{
      if(localChlorineSensorId){
        const request={
          "id":localChlorineSensorId,
          "type":CONSTANTVALUEEXTRAPARAM.CHLORINESENSOR,
          "chlorineSensorMake":clrnSensorMake,
          "chlorineSensorModel":clrnSensorModel,
          "chlorineSensorMaxValue":clrnSensorMaxVal,
        };
        setCurrentField(CONSTANTVALUEEXTRAPARAM.CHLORINESENSOR);
        dispatch(updateSiteExtraParameter(request));
        // handleClrnSensorShow();
      }
      else{
        const request={
          "type":CONSTANTVALUEEXTRAPARAM.CHLORINESENSOR,
          "chlorineSensorMake":clrnSensorMake,
          "chlorineSensorModel":clrnSensorModel,
          "chlorineSensorMaxValue":clrnSensorMaxVal,
        };
        setCurrentField(CONSTANTVALUEEXTRAPARAM.CHLORINESENSOR);
        dispatch(siteExtraParameter(request));
        // handleClrnSensorShow();
      }      
    }    
  }
    return (
      <div className="page">
      <NavBarAdmin />
      <AdminMenu selMenu="AddChlorineSensor" />
      {/* <Header moduleType={moduleType} /> */}
      <section>     
        <div className="container"> 
          <div className="row d-flex justify-content-center pt-4 h-100">
            <div className="card text-black" style={{ borderRadius: "25px" }}>
              <div className="card-body pt-5">
                <div className="row justify-content-center">                  
                  <div className="col-12 order-2 order-lg-1"> 
                    <p className="h1 fw-bold mb-4 ">{localChlorineSensorId?"Update Chlorine Sensor":"Add Chlorine Sensor"}</p>
                    <div className="d-flex flex-row mb-3">
                      <div className="form-outline flex-fill mb-0">
                        <label for="clrnSensorMake" className="col-sm-4 col-form-label">Make</label>
                        <input type="text" name="txtClrnSensorMake" id="txtClrnSensorMake" value={clrnSensorMake} onChange={(event) => handleSiteInput(event)} className="form-control" />
                      </div>
                    </div>
                    <div className="d-flex flex-row mb-3">
                      <div className="form-outline flex-fill mb-0">
                        <label for="clrnSensorModel" className="col-sm-4 col-form-label">Model</label>
                        <input type="text" name="txtClrnSensorModel" id="txtClrnSensorModel" value={clrnSensorModel} onChange={(event) => handleSiteInput(event)} className="form-control" />
                      </div>
                    </div>
                    <div className="d-flex flex-row mb-3">
                      <div className="form-outline flex-fill mb-0">
                        <label for="clrnSensorMaxVal" className="col-sm-4 col-form-label">Max Value</label>
                        <input type="text" name="txtClrnSensorMaxVal" id="txtClrnSensorMaxVal" value={clrnSensorMaxVal} onChange={(event) => handleSiteInput(event)} className="form-control" />
                      </div>
                    </div>
                    <div className="d-flex justify-content-center mb-3">
                      <Button variant="primary" onClick={()=>handleClrnSensorSubmit()}>Submit</Button>
                    </div>                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    )
};
export default AddChlorineSensor;
