import React, { useEffect, useState } from 'react';
// import { Link } from "react-router-dom";
// import PropTypes from "prop-types";
import { useNavigate,useLocation, Link } from "react-router-dom";
import {useDispatch, useSelector} from 'react-redux';
import Button from 'react-bootstrap/Button';
import NavBarAdmin from "../../../Components/NavBarAdmin";
import AdminMenu from "../../../Components/AdminMenu";
import {types} from '../../../redux/actions/types';
import {isEmpty, isLoggedInUser, CONSTANTVALUEEXTRAPARAM, sortArrayData, checkPermission} from '../../../utils'
import {addSite,updateSite,siteExtraParameter,updateSiteExtraParameter,clearSiteReducer} from '../../../redux/actions/siteAdminAction'
import { useToast } from '@chakra-ui/react';

const AddLevelSensor = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const siteAdminReducer = useSelector(state => state.siteAdminReducer);
  const [lvlSensorMake, setLvlSensorMake] = useState('');
  const [lvlSensorModel, setLvlSensorModel] = useState('');
  const [localLevelSensorId, setLocalLevelSensorId] = useState(null); 
  const [currentField, setCurrentField] = useState('sitedetails');
  const toast = useToast()

  useEffect(()=>{
    if(location){
      if(location.state){
        if(location.state.item){
          //console.log('Level Sensor ',location.state.item);
          setLvlSensorMake(location.state.item.levelSensorMake);
          setLvlSensorModel(location.state.item.levelSensorModel);
          setLocalLevelSensorId(location.state.item._id)
        }
      }
    }    
  },[])  
  useEffect(()=>{
    // console.log('AddSite-userReducer-useEffect',siteAdminReducer);    
    if(siteAdminReducer.action){
      if(siteAdminReducer.action===types.SITE_EXTRA_PARAMETER_SUCCESS){
        if(siteAdminReducer.status==="success"){          
          dispatch(clearSiteReducer());
          navigate('/Admin/Levelsensor', {
            moduleType: CONSTANTVALUEEXTRAPARAM.LEVELSENSOR
          });
        }else {
          toast({
        title: siteAdminReducer.message,
        status: 'warning',
        duration: 2000,
        isClosable: true,
        position: "top-right",
        positionOptions: {
          right: "0",
          top: "0",
        },
    });
        }
      }
      if(siteAdminReducer.action===types.UPDATE_SITE_EXTRA_PARAMETER_SUCCESS){
        if(siteAdminReducer.status==="success"){          
          //navigate('/Admin/Pump');          
          dispatch(clearSiteReducer());   
          navigate('/Admin/Levelsensor', {
            moduleType: CONSTANTVALUEEXTRAPARAM.LEVELSENSOR
          });
        }else {
          toast({
        title: siteAdminReducer.message,
        status: 'warning',
        duration: 2000,
        isClosable: true,
        position: "top-right",
        positionOptions: {
          right: "0",
          top: "0",
        },
    });
        }
      }
    }
  },[siteAdminReducer.action])

  const handleSiteInput=(e)=>{
    //////////////// Level Sensor ////////////////////////////////////
    if(e.target.name==="txtLvlSensorMake"){
      setLvlSensorMake(e.target.value);
    }
    if(e.target.name==="txtLvlSensorModel"){
      setLvlSensorModel(e.target.value);
    }
    ////////////////////////////////////////////////////////////////
  }

  const handleLvlSensorSubmit=()=>{
    if(isEmpty(lvlSensorMake)){
      toast({
        title: 'Please select make',
        status: 'warning',
        duration: 2000,
        isClosable: true,
        position: "top-right",
        positionOptions: {
          right: "0",
          top: "0",
        },
    })
      return;
    }else if(isEmpty(lvlSensorModel)){
    toast({
        title: 'Please select model',
        status: 'warning',
        duration: 2000,
        isClosable: true,
        position: "top-right",
        positionOptions: {
          right: "0",
          top: "0",
        },
    })
      return;
    }
    else{
      if(localLevelSensorId){
        const request={
          "id": localLevelSensorId,
          "type": CONSTANTVALUEEXTRAPARAM.LEVELSENSOR,
          "levelSensorMake":lvlSensorMake,
          "levelSensorModel":lvlSensorModel,
        };
        setCurrentField(CONSTANTVALUEEXTRAPARAM.LEVELSENSOR);
        dispatch(updateSiteExtraParameter(request));        
      }
      else{
        const request={
          "type": CONSTANTVALUEEXTRAPARAM.LEVELSENSOR,
          "levelSensorMake":lvlSensorMake,
          "levelSensorModel":lvlSensorModel,
        };
        setCurrentField(CONSTANTVALUEEXTRAPARAM.LEVELSENSOR);
        dispatch(siteExtraParameter(request));
        // handleLvlSensorShow();
      }
    }    
  } 
    return (
      <div className="page">
      <NavBarAdmin />
      <AdminMenu selMenu="AddLevelSensor" />
      {/* <Header moduleType={moduleType} /> */}
      <section>     
        <div className="container"> 
          <div className="row d-flex justify-content-center pt-4 h-100">
            <div className="card text-black" style={{ borderRadius: "25px" }}>
              <div className="card-body pt-5">
                <div className="row justify-content-center">                  
                  <div className="col-12 order-2 order-lg-1"> 
                    <p className="h1 fw-bold mb-4 ">{localLevelSensorId?"Update Level Sensor":"Add Level Sensor"}</p>
                    <div className="d-flex flex-row mb-3">
                      <div className="form-outline flex-fill mb-0">
                        <label for="lvlSensorMake" className="col-sm-4 col-form-label">Make</label>
                        <input type="text" name="txtLvlSensorMake" id="txtLvlSensorMake" value={lvlSensorMake} onChange={(event) => handleSiteInput(event)} className="form-control" />
                      </div>
                    </div>
                    <div className="d-flex flex-row mb-3">
                      <div className="form-outline flex-fill mb-0">
                        <label for="lvlSensorModel" className="col-sm-4 col-form-label">Model</label>
                        <input type="text" name="txtLvlSensorModel" id="txtLvlSensorModel" value={lvlSensorModel} onChange={(event) => handleSiteInput(event)} className="form-control" />
                      </div>
                    </div>
                    <div className="d-flex justify-content-center mb-3">
                      <Button variant="primary" onClick={()=>handleLvlSensorSubmit()}>Submit</Button>
                    </div>                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    )
};
export default AddLevelSensor;
