import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from 'react-redux';
import mainLogo from "../assets/images/aqualogix-logo.png";
import secondaryLogo from "../assets/images/hargharjal-logo.png";
import noimage from "../assets/images/noimage.png";
import { clearData } from '../redux/actions/userAction';
import { types } from '../redux/actions/types';
import { CONSTANTVALUE, getData } from '../utils';
import { MdAdminPanelSettings, MdDashboard, MdInfo, MdLogout, MdReport } from 'react-icons/md';
import "../index.css";

const NavBar = ({ page, projectLogo, isUserIcon }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [userObject, setUserObject] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const userReducer = useSelector(state => state.userReducer);

  const doLogout = () => {
    dispatch(clearData());
  };

  useEffect(() => {
    const fetchUserObject = async () => {
      const userObj = await getData(CONSTANTVALUE.USEROBJECT);
      setUserObject(userObj);
      setIsAdmin(userObj.EmploymentType === "Admin");
    };

    fetchUserObject();
  }, []);

  useEffect(() => {
    if (userReducer.action === types.CLEAR_LOGIN_REQUEST_APPSETTINGS_SUCCESS) {
      navigate('/');
    }
  }, [userReducer.action]);

  return (
    <nav className="navbar navbar-expand-lg navbar-light fixed-top bg-light shadow-sm">
      <div className="container-fluid">
        <Link to="/" className="navbar-brand">
          <img src={mainLogo} alt="Aqualogix Logo" className="d-inline-block align-top" style={{ height: '55px' }} />
        </Link>
        <Link to="/" className="navbar-brand d-none d-md-block">
          <img src={secondaryLogo} alt="Har Ghar Jal Logo" className="d-inline-block align-top" style={{ height: '55px' }} />
        </Link>

        <div className="dropdown user-dropdown">
          <Link
            className="dropdown-toggle"
            id="navbarDropdown"
            role="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            to="#"
          >
            {/* {isUserIcon && (
              <img
                src={projectLogo || (userObject ? userObject.companyDetails.Logo : noimage)}
                alt="User icon"
                className="d-inline-block align-top"
                style={{ height: "40px", borderRadius: '50%' }}
                onError={(e) => { e.target.src = noimage }}
              />
            )} */}
            <i className="fa fa-bars" style={{ color: '#1989c6', fontSize: '28px', marginLeft: '10px' }}></i>
          </Link>

          <div
            className="dropdown-menu user-dropdown-menu"
            aria-labelledby="navbarDropdown">
            <span className="dropdown-arrow"></span>
            <ul className="user-dropdown-menu">
              <li className="user-info">
                {/* <img
                  src={userIcon}
                  alt="User icon"
                  style={{ height: "31px", width: "80px" }}
                  className="d-inline-block align-top"
                /> */}
                <Link className="user-name" to={"#"}>{userObject ? userObject.OperatorName : ""}</Link></li>
              {isAdmin === true ? <li><Link style={{ display: 'flex', alignItems: 'center', gap: '8px', color: 'white' }} to={"/Admin"}><MdAdminPanelSettings /> Admin</Link></li> : null}
              {page != 'Dashboard' ? <li><Link to={"/dashboard"} style={{ display: 'flex', alignItems: 'center', gap: '8px', color: 'white' }}><MdDashboard />Dashboard</Link></li> : null}
              {/*<li><button className="dropdown-item" onClick={()=>defaultSiteClick()}>Site Details(Scheme-PIPRASI)</button></li>
              <li><Link className="dropdown-item" to={"/mqttdata"}>MQTT Data</Link></li>*/}
              <li><Link to={"/AllReports"}><div style={{ display: 'flex', alignItems: 'center', gap: '8px', color: 'white' }}> <MdReport style={{ marginRight: '4px' }} /> Reports </div></Link></li>
              <li><Link to={"/ParameterThresholdLogs"}><div style={{ display: 'flex', alignItems: 'center', gap: '8px', color: 'white' }}> <MdReport style={{ marginRight: '4px' }} /> Threshold Logs </div></Link></li>

              <li>
                <Link
                  className="more-info"
                  data-bs-toggle="modal"
                  style={{ display: 'flex', alignItems: 'center', gap: '8px', color: 'white' }}
                  data-bs-target="#staticBackdrop">
                  <MdInfo />
                  More Info
                </Link>
              </li>
              <li><Link onClick={doLogout}><div style={{ display: 'flex', alignItems: 'center', gap: '8px', color: 'white' }}> <MdLogout style={{ marginRight: '4px' }} />LOGOUT</div></Link></li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  );
};

NavBar.propTypes = {
  page: PropTypes.string,
  projectLogo: PropTypes.string,
  isUserIcon: PropTypes.bool,
};

NavBar.defaultProps = {
  page: "",
  projectLogo: null,
  isUserIcon: true,
};

export default NavBar;
