import React, { useEffect, useState } from 'react';
import iconMap from "../../assets/images/icon-map.png";
import PropTypes from 'prop-types';
import AreaChartPressure from "../Graph/AreaChartPressure";
import { getPressureGraphData } from '../../redux/actions/graphAction';
import { useDispatch, useSelector } from 'react-redux';
import { types } from '../../redux/actions/types';
import { useToast } from '@chakra-ui/react';

const CardPressure = ({ title, graphFor, siteId, type }) => {
  const dispatch = useDispatch();
  const graphReducer = useSelector(state => state.graphReducer);
  const [graphData, setGraphData] = useState([]);
  const toast = useToast();

  useEffect(() => {
    fetchPressureGraphData();
  }, [siteId]);

  useEffect(() => {
    if (graphReducer.action) {
      if (graphReducer.action === types.GET_GRAPH_PRESSURE_REQUEST_SUCCESS) {
        if (graphReducer.status === "success" && graphReducer.data) {
          setGraphData(graphReducer.data);
        } else {
          displayToast(graphReducer.message, 'warning');
        }
      } else if (graphReducer.action === types.GET_GRAPH_PRESSURE_REQUEST_FAILURE) {
        displayToast(graphReducer.message, 'warning');
      }
    }
  }, [graphReducer.action]);

  const fetchPressureGraphData = () => {
    const request = { siteId, graphFor };
    dispatch(getPressureGraphData(request));
  };

  const displayToast = (message, status) => {
    toast({
      title: message,
      status: status,
      duration: 2000,
      isClosable: true,
      position: "top-right",
    });
  };

  return (
    <div className="card">
      <div className="card-header">
        <h4 className="card-header-title mb-2 mb-sm-0">
          <i className="icon-map">
            <img src={iconMap} alt="map icon" />
          </i>{" "}
          {title}
        </h4>
      </div>
      <div className="card-body">
        <AreaChartPressure
          dataList={graphData}
          yLabel="Pressure (meter)"
          xLabel="Hour"
        />
      </div>
    </div>
  );
};

CardPressure.propTypes = {
  title: PropTypes.string.isRequired,
  type: PropTypes.string,
  graphFor: PropTypes.string.isRequired,
  siteId: PropTypes.string.isRequired,
};

export default CardPressure;
