import React, { useEffect, useState } from 'react';
import { useNavigate,Link } from "react-router-dom";
import {useDispatch, useSelector} from 'react-redux';
import {getRoles,deleteUser} from '../../redux/actions/userAdminAction'
import {types} from '../../redux/actions/types';
import NavBarAdmin from "../../Components/NavBarAdmin";
import AdminMenu from "../../Components/AdminMenu";
import {isEmpty, isLoggedInUser,checkPermission} from '../../utils'
import { useToast } from '@chakra-ui/react';
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Button,
  Link as ChakraLink,
  TableCaption,
  TableContainer,
} from '@chakra-ui/react';


const Roles = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [listRole, setListRole] = useState([]);
   /////////////////////////////////////////////////////////////////
  /////Parameters for getting permission value/////////////////////
  /////////////////////////////////////////////////////////////////
  const [isCreate, setIsCreate] = useState(true);
  const [isEdit, setIsEdit] = useState(true);
  const [isDelete, setIsDelete] = useState(true);
  const [isView, setIsView] = useState(true);
  const toast = useToast()

  const userAdminReducer = useSelector(state => state.userAdminReducer);
  useEffect(()=>{
    chkLogin(); 
    setPermission();    
  },[])
  useEffect(()=>{
    // setIsLoading(true);
    // console.log('LogIn-useEffect');    
    if(isView)
      fetchRoleList();
  },[isView])
  useEffect(()=>{
    // console.log('User-userAdminReducer-useEffect',userAdminReducer);    
    if(userAdminReducer.action){
      if(userAdminReducer.action===types.GET_ROLES_SUCCESS){
        console.log('User-userAdminReducer-useEffect',userAdminReducer)      
        if(userAdminReducer.status==="success"){
          setListRole(userAdminReducer.data);
        }else {
          toast({
        title: userAdminReducer.message,
        status: 'warning',
        duration: 2000,
        isClosable: true,
        position: "top-right",
        positionOptions: {
          right: "0",
          top: "0",
        },
    });
        }
      }
      else if(userAdminReducer.action===types.DELETE_USER_REQUEST_SUCCESS){
        console.log('User-userAdminReducer-useEffect',userAdminReducer)      
        if(userAdminReducer.status==="success"){
          fetchRoleList();
          // setListSite(UserReducer.data.list);
          // storeData(CONSTANTVALUE.USEROBJECT,userReducer.data);
          // storeData(CONSTANTVALUE.USERTOKEN,userReducer.token);
          // navigate('/dashboard');
        }else {
          toast({
        title: userAdminReducer.message,
        status: 'warning',
        duration: 2000,
        isClosable: true,
        position: "top-right",
        positionOptions: {
          right: "0",
          top: "0",
        },
    });
        }
      }
    }
  },[userAdminReducer.action])

  const chkLogin=async()=>{
    const isLoggedIn=await isLoggedInUser();
    // console.log("isLoggedInUser()",isLoggedIn);
    if(!isLoggedIn){
      navigate('/Admin');
    }
  }  
  const setPermission=async()=>{
    // setIsCreate(await checkPermission('Users','create'));
    // setIsEdit(await checkPermission('Users','edit'));
    // setIsDelete(await checkPermission('Users','delete'));
    // setIsView(await checkPermission('Users','view'));
    // console.log('Projects-User Permissions-isCreate='+isCreate+'-isEdit-'+isEdit+'-isDelete-'+isDelete+'-isView-'+isView);
  }
  const fetchRoleList=async()=>{
    dispatch(getRoles());
  } 
  const handleDeleteEvent=(item)=>{
    // Alert('Are you sure you want to remove?');
    // window.confirm("What Is Your Age?");
    // setSelectedId(item._id)
    console.log("adsf!=item",item);
    if (window.confirm("Are you sure you want to delete?")) {
      // setTimeout(() => {
        deleteSelUser(item._id);
      // }, 1000);
      console.log("You pressed OK!");
    } else {
      console.log("You pressed Cancel!");
    }
    // setSelectedId(item._id);
    // const request={id:item._id};
    // dispatch(deleteSite(item._id));
  }
  const handleEditEvent=(item)=>{
    console.log('User',item);
    navigate('/Admin/AddRole',{state: {
      roleId: item._id,
      roleName: item.role,
    }});
    // navigate('/Admin/AddSite');
  }
  // const deleteItemClick=(item)=>{
  //   setNewsObject(item);
  //   console.log(item);
  // }
  const deleteSelUser=async(selectedId)=>{   
    console.log("deleteSelUser-selectedId",selectedId); 
    dispatch(deleteUser(selectedId));
    // const interval = setInterval(() => {
    //   dispatch(deleteSite(selectedId));
    // }, 1000);
    // return () => clearInterval(interval);
    // console.log("deleteSite-selectedId",selectedId); 
    // dispatch(deleteSite(selectedId));
    // console.log('site',item);
  }
  const bindData=()=>{
    return (
      <Table variant='striped' colorScheme='gray'>
      <Thead>
        <Tr>
          <Th>Role</Th>
          <Th>Details</Th>
          <Th>Action</Th>
        </Tr>
      </Thead>
      <Tbody>
        {listRole.length > 0 ? (
          listRole.map((item, index) => (
            <Tr key={index}>
              <Td>{item.role ? item.role.toString() : ''}</Td>
              <Td>{item.details ? item.details.toString() : ''}</Td>
              <Td>
                <Button
                  colorScheme='blue'
                  onClick={() => handleEditEvent(item)}
                  size='sm'
                >
                  {isEdit ? 'Update' : 'View'}
                </Button>
                {' '}
                {isDelete ? (
                  <Button
                    colorScheme='red'
                    size = 'sm'
                    onClick={() => handleDeleteEvent(item)}
                  >
                    Delete
                  </Button>
                ) : null}
              </Td>
            </Tr>
          ))
        ) : (
          <Tr>
            <Td>No Record Found</Td>
          </Tr>
        )}
      </Tbody>
    </Table>
    )
  }
  const noViewPermission=()=>{
    return(<p class="text-center">You don't have permission to view the data. Please contact to administrator for more details</p>)
  }
  return (
    <div className="page">
      <NavBarAdmin />
      <AdminMenu selMenu="Roles" />
      <div className="project-page-head" style={{width: '90%', borderRight : '8px solid black', borderRadius: '20px', marginLeft: "5px", marginTop : '10px'}}>
        <div className="row m-0">
          <div className="col-12 py-4">
            <div className="sub-head">Roles</div>
            <h3 className="project-title fw-bold">Role Listing</h3>
          </div>
        </div>
      </div>
      <section>      
        <div className="container">        
          <div className="row d-flex justify-content-center h-100 pt-5">
            {/* <div className="col-md-8 col-lg-8 col-xl-6"> */}
              <div className="card text-black" style={{ borderRadius: "10px" }}>
                <div className="card-body">
                  {isView?bindData():noViewPermission()}                  
                  <div className="d-flex justify-content-center mx-2 mb-2 mb-lg-2">
                    {isCreate?<Link className="btn btn-primary mt-3" to={"/Admin/AddRole"}>Add Role</Link>:null}
                  </div>
                </div>
              </div>              
            {/* </div> */}
          </div>
        </div>
      </section>
    </div>
  );
};

export default Roles;
