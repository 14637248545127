import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import mainLogo from "../assets/images/aqualogix-logo.png";
import secondaryLogo from "../assets/images/hargharjal-logo.png";
import blueUser from '../Pages/userIconBlue.png';
import { clearData } from '../redux/actions/userAction';
import { types } from '../redux/actions/types';
import { Avatar, Tag, TagLabel } from '@chakra-ui/react';
import { MdAdminPanelSettings, MdDashboard, MdDehaze, MdLogout, MdReport, MdSettings } from 'react-icons/md';
import "../index.css";
import { CONSTANTVALUE, getData } from '../utils';

const NavBarAdmin = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userReducer = useSelector(state => state.userReducer);
  const [userData, setUserData] = useState(null);

  const doLogout = () => {
    dispatch(clearData());
  };

  useEffect(() => {
    if (userReducer.action === types.CLEAR_LOGIN_REQUEST_APPSETTINGS_SUCCESS) {
      navigate('/Admin');
    }
  }, [userReducer.action]);

  useEffect(() => {
    fetchUserData();
  }, []);

  const fetchUserData = async () => {
    const data = await getData(CONSTANTVALUE.USEROBJECT);
    if (data) setUserData(data);
  };

  return (
    <nav className="navbar navbar-expand-lg navbar-light fixed-top bg-light shadow">
      <div className="container-fluid">
        <Link className="navbar-brand" to="/">
          <img src={mainLogo} alt="Aqualogix Logo" className="d-inline-block align-top" style={{ height: '55px' }} />
        </Link>
        <Link className="navbar-brand d-none d-md-block" to="/">
          <img src={secondaryLogo} alt="Har Ghar Jal Logo" className="d-inline-block align-top" style={{ height: '55px' }} />
        </Link>

        <div className="dropdown user-dropdown">
          <Link
            className="dropdown-toggle"
            id="navbarDropdown"
            role="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            to="#"
          >
            <Tag size='lg' colorScheme='blue' borderRadius='full' style={{ display: 'flex', alignItems: 'center' }}>
              <Avatar
                src={blueUser}
                size='xs'
                name={userData?.OperatorName || 'User'}
                ml={-1}
                mr={2}
              />
              <TagLabel>{userData?.OperatorName || 'User'}</TagLabel>
              <MdDehaze style={{ marginTop: '4px' }} />
            </Tag>
          </Link>
          <div
            className="dropdown-menu user-dropdown-menu"
            aria-labelledby="navbarDropdown">
            <span className="dropdown-arrow"></span>
            <ul className="user-dropdown-menu">
              <li className="user-info">
                {/* <img
                  src={userIcon}
                  alt="User icon"
                  style={{ height: "48px", width: "48px" }}
                  className="d-inline-block align-top"
                /> */}
                <Link className="user-name" to={"#"}>Admin</Link></li>
              <li><Link to={"/dashboard"} ><div style={{display : 'flex', alignItems: 'center', gap : '8px', color : 'white'}}> <MdDashboard style={{marginRight: '4px'}}/> Dashboard</div></Link></li>
                <li><Link to={"/Admin/SiteListing"} ><div style={{display : 'flex', alignItems: 'center', gap : '8px', color : 'white'}}> <MdSettings style={{marginRight: '4px'}}/> Admin</div></Link></li>
              <li><Link to={"/AllReports"}><div style={{display : 'flex', alignItems: 'center', gap : '8px', color : 'white'}}> <MdReport style={{marginRight: '4px'}}/> Reports </div></Link></li>
              <li><Link to={"/ParameterThresholdLogs"}><div style={{display : 'flex', alignItems: 'center', gap : '8px', color : 'white'}}> <MdReport style={{marginRight: '4px'}}/> Parameter Threshold Logs </div></Link></li>
             
              <li><Link onClick={doLogout}><div style={{display : 'flex', alignItems: 'center', gap : '8px', color : 'white'}}> <MdLogout style={{marginRight: '4px'}}/>LOGOUT</div></Link></li>
            </ul>
          </div>     
        </div>
      </div>
    </nav>
  );
};

export default NavBarAdmin;
