import React, { useEffect, useState } from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";
import PropTypes from 'prop-types';
import {types} from '../../redux/actions/types';
import { useSelector, useDispatch } from "react-redux";
import {getHomeWatSup} from '../../redux/actions/siteAction'
import { useToast } from '@chakra-ui/react';

ChartJS.register(ArcElement, Tooltip, Legend);
let data=null;
let options=null;
const HomeWATSUPPieChart = ({isUpdate}) => {
  const dispatch = useDispatch();
  const [watsupData, setWatsupData] = useState([]);
  const siteReducer = useSelector(state => state.siteReducer);
  const [graphStatus, setGraphStatus] = useState(false);
  const toast = useToast()
  useEffect(()=>{
    dispatch(getHomeWatSup('watersupplied'));
  },[isUpdate])
  useEffect(()=>{
    if(siteReducer.action===types.GET_DASHBOARD_WATSUP_GRAPH_REQUEST_SUCCESS){
        if(siteReducer.status==="Get Water Supplied Graph Data Successfully"){
          if(siteReducer.data){
            setWatsupData(siteReducer.data);
          }
        }else {
          toast({
        title: siteReducer.message,
        status: 'warning',
        duration: 2000,
        isClosable: true,
        position: "top-right",
        positionOptions: {
          right: "0",
          top: "0",
        },
    });
        }
    }
  },[siteReducer.action])
  useEffect(()=>{
      let labels=[];
      let chartData=[];
      let backgroundColor=[];
      let borderColor=[];
      watsupData.map((item,index)=>{
        labels.push(item.siteName);
        chartData.push(item.watersupplied);
        const red=0+(index*10);
        const green=32+(index*20);
        const blue=96+(index*20);
        const backgroundColorString="rgba("+red+","+green+","+blue+",0.8)";
        const borderColorString="rgba("+red+","+green+","+blue+",1)";
        backgroundColor.push(backgroundColorString);
        borderColor.push(borderColorString);
      })
      //########################################################################## //
      //######## Note: For handling the zero values on the site. ######## ######## //
      //########################################################################## //
      if(chartData.filter(item=>item>0).length==0){
        labels.push('');
        chartData.push(100);
        backgroundColor.push("rgba(255,255,255,0.8)");
        borderColor.push("rgba(58,92,143,1)");
      }

      data = {
        labels: labels,
        datasets: [
          {
            label: "Water Supplied",
            data: chartData,
            backgroundColor: backgroundColor,
            borderColor: borderColor,
            borderWidth: 1,
          },
        ],
      };
      setGraphStatus(true);
      options= {
        plugins: {
          tooltip: {
            callbacks: {
              title: function (context) {
                return context[0].label;
              },
              label: function(context) {return context.label.length>0?`${context.formattedValue}`:`No data available`;}
            }
          },
          legend: { display: false },          
        },
      };    
  },[watsupData])
  if(graphStatus){
    return (<Pie data={data} options={options} />);
  }
};

HomeWATSUPPieChart.propTypes = {
  isUpdate: PropTypes.bool,
};
export default HomeWATSUPPieChart;
