import React, { useEffect, useState } from 'react';
import NavBar from "../Components/NavBar";
import MapComponent from "../Components/Map/MapComponent";
import OffCanvasRight from "../Components/offCanvas/OffCanvasRight";
import OffCanvasLeft from "../Components/offCanvas/OffCanvasLeft";
import OffCanvasTop from "../Components/offCanvas/OffCanvasTop";
import { getSiteList, clearSiteData } from '../redux/actions/siteAction'
import * as moment from 'moment';
import { types } from '../redux/actions/types';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, Link } from "react-router-dom";

import { clearData } from "../utils"
import { useToast, Spinner } from '@chakra-ui/react';
import LoadingSpinner from '../Components/LoadingSpinner';
import Loader from '../styles/Loader';
import { getAssignedSiteAction } from '../redux/actions/userAction';
import { siteList } from '../redux/actions/siteAdminAction';

const Dashboard = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAssignedSiteAction())
  }, [])
  const navigate = useNavigate();
  const siteReducer = useSelector(state => state.siteReducer);
  const [centerPoint, setCenterPoint] = useState(null);
  const [locations, setLocations] = useState([]);
  const siteAdminReducer = useSelector(state => state.siteAdminReducer);

  const [newsObject, setNewsObject] = useState(null);
  const [initialMapOptions, setInitialMapOptions] = useState({ center: null, zoom: null });

  const [loading, setLoading] = useState(true);
  const toast = useToast()
  useEffect(() => {
    fetchSiteLists();
    fetchSiteList();
  }, [])

  const fetchSiteLists = async () => {
    dispatch(siteList("page=1&limit=10000"));
  }
  useEffect(() => {
    //console.log('Sites-siteAdminReducer-useEffect',siteAdminReducer);    
    if (siteAdminReducer.action) {
      if (siteAdminReducer.action === types.LIST_SITE_REQUEST_SUCCESS) {
        //console.log('Sites-siteAdminReducer-useEffect',siteAdminReducer)      
        if (siteAdminReducer.status === "success") {

        } else {
          toast({
            title: siteAdminReducer.message,
            status: 'warning',
            duration: 2000,
            isClosable: true,
            position: "top-right",
            positionOptions: {
              right: "0",
              top: "0",
            },
          });
        }
      }
    }
  }, [siteAdminReducer.action])
  useEffect(() => {
    if (siteReducer.action) {
      if (siteReducer.action === types.GET_SITELIST_REQUEST_APPSETTINGS_SUCCESS) {
        setLoading(false);
        if (siteReducer.status === "success") {
          console.log("PIKACHHU", siteReducer)
          if (siteReducer.data.list) {
            if (siteReducer.data.list.length > 0) {
              const cal = calculateCentroid(siteReducer.data.list);
              setCenterPoint({ lat: parseFloat(cal.lat), lng: parseFloat(cal.lng) });
              // console.log("xxx new",cal)
              setLocations(siteReducer.data.list);
            }
            else {
              setCenterPoint({ lat: parseFloat(23.0363778), lng: parseFloat(72.5416146) });
            }
          }
          else {
            setCenterPoint({ lat: parseFloat(23.0363778), lng: parseFloat(72.5416146) });
          }
        } else {
          toast({
            title: siteReducer.message,
            status: 'warning',
            duration: 2000,
            isClosable: true,
            position: "top-right",
            positionOptions: {
              right: "0",
              top: "0",
            },
          });
        }
      } else if (siteReducer.action === types.GET_SITELIST_REQUEST_APPSETTINGS_FAILURE) {
        setLoading(false);
        console.log('Dashboard-siteReducer-useEffect', siteReducer);
        if (siteReducer.status == 401) {
          dispatch(clearSiteData());
          clearData();
          navigate("/signin");
        }
        else {
          dispatch(clearData());
        }
      }
    }
  }, [siteReducer.action])

  const fetchSiteList = () => {
    dispatch(getSiteList());
  }

  const calculateCentroid = (points) => {
    console.log("xxx cal", points)
    let latitude = 0;
    let longitude = 0;
    let n = points.length;

    for (let i = 0; i < n; i++) {
      latitude += parseFloat(points[i].lat);
      longitude += parseFloat(points[i].lng);
    }
    const ans = { lat: latitude / n, lng: longitude / n }
    console.log("xxx old", ans)
    return ans
  }

  const bindSiteDataonMap = () => {
    if (centerPoint) {
      const zoomLevel = calculateZoomLevel();
      console.log("xxx zooooom", zoomLevel)
      return (<MapComponent center={centerPoint} locations={locations} onInfoItemClick={onInfoItemClick} zoomLevel={zoomLevel} mapHeight="800px" />)
    }
  }

  const calculateZoomLevel = () => {
    const WORLD_WIDTH = 256; // Width of the world map at zoom level 0
    const PADDING_FACTOR = 3.5; // Factor to add padding around the sites

    if (locations.length === 0) {
      return 0; // Return default zoom level if there are no locations
    }
    if (locations.length === 1) {
      return 10; // Return default zoom level for one location
    }

    let minLat = Number.MAX_VALUE;
    let maxLat = Number.MIN_VALUE;
    let minLng = Number.MAX_VALUE;
    let maxLng = Number.MIN_VALUE;

    for (let i = 0; i < locations.length; i++) {
      const site = locations[i];
      minLat = Math.min(minLat, parseFloat(site.lat));
      maxLat = Math.max(maxLat, parseFloat(site.lat));
      minLng = Math.min(minLng, parseFloat(site.lng));
      maxLng = Math.max(maxLng, parseFloat(site.lng));
    }

    const latDiff = maxLat - minLat;
    const lngDiff = maxLng - minLng;
    const zoomLat = Math.log2((WORLD_WIDTH * PADDING_FACTOR) / lngDiff);
    const zoomLng = Math.log2((WORLD_WIDTH * PADDING_FACTOR) / latDiff);
    const zoom = Math.floor(Math.min(zoomLat, zoomLng));

    return zoom;
  };


  const onInfoItemClick = (site) => {
    console.log('site im site ims', site.aqualogixId, site.pumpName);
    navigate('/site-dashboard', {
      state: {
        siteId: site.aquaLogixID,
        siteName: site.pumpName,
      }
    });
  }
  const onProjectItemClick = (site) => {
    console.log('KCKSCKNCK', site.projectID, site.projectName);
    navigate('/project', {
      state: {
        projectId: site.projectID,
        projectName: site.projectName,
      }
    });
  }
  const newsItemClick = (item) => {
    setNewsObject(item);
    console.log(item);
  }
  return (
    <div>
      <NavBar />
      <OffCanvasLeft />
      {loading &&
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '100px 0' }}>
          <Loader />
        </div>}
      <OffCanvasRight onItemClick={newsItemClick} newsObject={setNewsObject} />
      {/* <OffCanvasTop /> */}
      <div className="map-area">
        {bindSiteDataonMap()}
        <div className="container-fluid" />
      </div>
      {/* modal */}
      <div
        className="modal fade"
        id="newsDtls"
        // tabindex="-1"
        aria-labelledby="newsDtlsLabel"
        aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl">
          <div className="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">{newsObject ? newsObject.newsTitle : ""}</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body"><div>{newsObject ? moment(newsObject.created_at).format('DD/MM/YYYY') : ""}</div>{newsObject ? newsObject.newsDetails : ""}</div>
          </div>
        </div>
      </div>
      {/* modal */}
      <div
        className="modal fade"
        id="staticBackdrop"
        // tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl">
          <div className="modal-content">
            <div className="modal-body">
              <div className="table-responsive">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th scope="col">Location Name</th>
                      {/* <th scope="col">Status</th> */}
                      {/* <th scope="col">Pump Type</th> */}
                      <th scope="col">Cumulative Energy (KWH)</th>
                      <th scope="col">Cumulative Water (KL)</th>
                      <th scope="col">Pump energy source</th>
                      <th scope="col">Today's Production (L)</th>
                      <th scope="col">LPCD (population average)</th>
                      <th scope="col">FHTC (No. of connections)</th>
                      {/* <th scope="col">Level (percent)</th> */}
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {locations.length > 0 ?
                      locations.map((item, index) => {
                        return (
                          <tr>
                            <td><a href='/project' onClick={() => onProjectItemClick(item)} className="text-blue">{item.projectName}</a></td>
                            {/* <td>On</td> */}
                            {/* <td><img src={pumpTypeOneImage} alt="pump" /></td> */}
                            <td>{item.kwhDiff ? (item.kwhDiff / 10).toFixed(2) : "0"}</td>
                            <td>{item.totalFlow ? (item.totalFlow / 1000).toFixed(2) : "0"}</td>
                            <td>{item.sourceType}</td>
                            <td>{item.todayProduction}</td>
                            <td>{item.LPCD ? item.LPCD.toFixed(2) : 0}</td>
                            <td>{item.FHTC ? item.FHTC : 0}</td>
                            {/* <td>{item.Level?item.Level.toFixed(2):0}</td> */}
                            <td></td>
                          </tr>
                        )
                      }) : null}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
