import {put, call, takeLatest, all} from 'redux-saga/effects';
import {types} from '../actions/types';
import {siteListAPI,siteDetailsAPI,projectDetailsAPI,mqttDataAPI,pushMqttDataAPI,
        getSitesByProjectIdAPI,reportDataAPI,reportHourWiseAPI,getDashboardGraphAPI} from '../../store/apis';

function* getSiteList({payload, error}) {
  try {
    // yield put({type: types.SEND_REQUEST_USER_LOGIN});
    // console.log('getSiteList',payload);
    const siteListObject = yield call(siteListAPI,payload);   
    //console.log('siteListObject',siteListObject); 
    if(siteListObject.status==="success")
      yield put({type: types.GET_SITELIST_REQUEST_APPSETTINGS_SUCCESS, payload: siteListObject});
    else
      yield put({type: types.GET_SITELIST_REQUEST_APPSETTINGS_FAILURE, payload: siteListObject});
  } catch (err) {
    // console.log('siteListObject',err);
    yield put({type: types.GET_SITELIST_REQUEST_APPSETTINGS_FAILURE, payload: err});
    // console.log(err);
  }
}
function* getSiteDetails({payload, error}) {
  try {
    // yield put({type: types.SEND_REQUEST_USER_LOGIN});
    //console.log('getSiteDetails-Request',payload);
    const siteDetailsObject = yield call(siteDetailsAPI,payload);   
    //console.log('getSiteDetails-Response',siteDetailsObject); 
    // if(userObject.status=200)
    yield put({type: types.GET_SITEDETAILS_REQUEST_APPSETTINGS_SUCCESS, payload: siteDetailsObject});
    // else
    //   yield put({type: types.SEND_REQUEST_USER_LOGIN_FAILURE, payload: userObject.message});
  } catch (err) {
    // console.log('appSettings',err);
    yield put({type: types.GET_SITEDETAILS_REQUEST_APPSETTINGS_FAILURE, payload: err});
    // console.log(err);
  }
}
function* getProjectDetails({payload, error}) {
  try {
    // yield put({type: types.SEND_REQUEST_USER_LOGIN});
    // console.log('getSiteDetails',payload);
    const projectDetailsObject = yield call(projectDetailsAPI,payload);   
    // console.log('siteDetailsObject',siteDetailsObject); 
    // if(userObject.status=200)
    yield put({type: types.GET_PROJECTDETAILS_REQUEST_APPSETTINGS_SUCCESS, payload: projectDetailsObject});
    // else
    //   yield put({type: types.SEND_REQUEST_USER_LOGIN_FAILURE, payload: userObject.message});
  } catch (err) {
    // console.log('appSettings',err);
    yield put({type: types.GET_PROJECTDETAILS_REQUEST_APPSETTINGS_FAILURE, payload: err});
    // console.log(err);
  }
}
function* getMqttData({payload, error}) {
  try {
    // yield put({type: types.SEND_REQUEST_USER_LOGIN});
    // console.log('getSiteDetails',payload);
    const mqttDataObject = yield call(mqttDataAPI,payload);   
    // console.log('siteDetailsObject',siteDetailsObject); 
    // if(userObject.status=200)
    yield put({type: types.GET_MQTTDATA_REQUEST_SUCCESS, payload: mqttDataObject});
    // else
    //   yield put({type: types.SEND_REQUEST_USER_LOGIN_FAILURE, payload: userObject.message});
  } catch (err) {
    // console.log('appSettings',err);
    yield put({type: types.GET_MQTTDATA_REQUEST_FAILURE, payload: err});
    // console.log(err);
  }
}
function* getReportData({payload, error}) {
  try {
    // yield put({type: types.SEND_REQUEST_USER_LOGIN});
    // console.log('getSiteDetails',payload);
    const responseObject = yield call(reportDataAPI,payload);   
    // console.log('siteDetailsObject',siteDetailsObject); 
    // if(userObject.status=200)
    yield put({type: types.REPORT_REQUEST_SUCCESS, payload: responseObject});
    // else
    //   yield put({type: types.SEND_REQUEST_USER_LOGIN_FAILURE, payload: userObject.message});
  } catch (err) {
    // console.log('appSettings',err);
    yield put({type: types.REPORT_REQUEST_FAILURE, payload: err});
    // console.log(err);
  }
}
function* getReportHourData({payload, error}) {
  try {
    // yield put({type: types.SEND_REQUEST_USER_LOGIN});
    // console.log('getSiteDetails',payload);
    const responseObject = yield call(reportHourWiseAPI,payload);   
    // console.log('siteDetailsObject',siteDetailsObject); 
    // if(userObject.status=200)
    yield put({type: types.REPORT_HOURLY_REQUEST_SUCCESS, payload: responseObject});
    // else
    //   yield put({type: types.SEND_REQUEST_USER_LOGIN_FAILURE, payload: userObject.message});
  } catch (err) {
    // console.log('appSettings',err);
    yield put({type: types.REPORT_HOURLY_REQUEST_FAILURE, payload: err});
    // console.log(err);
  }
}
function* pushMqttData({payload, error}) {
  try {
    // yield put({type: types.SEND_REQUEST_USER_LOGIN});
    // console.log('getSiteDetails',payload);
    const mqttDataObject = yield call(pushMqttDataAPI,payload);   
    // console.log('siteDetailsObject',siteDetailsObject); 
    // if(userObject.status=200)
    yield put({type: types.PUSH_MQTTDATA_REQUEST_SUCCESS, payload: mqttDataObject});
    // else
    //   yield put({type: types.SEND_REQUEST_USER_LOGIN_FAILURE, payload: userObject.message});
  } catch (err) {
    // console.log('appSettings',err);
    yield put({type: types.PUSH_MQTTDATA_REQUEST_FAILURE, payload: err});
    // console.log(err);
  }
}
function* getSiteListByProjectId({payload, error}) {
  try {
    // yield put({type: types.SEND_REQUEST_USER_LOGIN});
    // console.log('getSiteDetails',payload);
    const mqttDataObject = yield call(getSitesByProjectIdAPI,payload);   
    // console.log('siteDetailsObject',siteDetailsObject); 
    // if(userObject.status=200)
    yield put({type: types.GET_SITELIST_BY_PROJECT_SUCCESS, payload: mqttDataObject});
    // else
    //   yield put({type: types.SEND_REQUEST_USER_LOGIN_FAILURE, payload: userObject.message});
  } catch (err) {
    // console.log('appSettings',err);
    yield put({type: types.GET_SITELIST_BY_PROJECT_FAILURE, payload: err});
    // console.log(err);
  }
}
function* clearSiteData({payload, error}) {
  yield put({type: types.CLEAR_SITE_DATA_SUCCESS, payload: null});
}
function* getLPCDHomeGraphData({payload, error}) {
  try {
    // yield put({type: types.SEND_REQUEST_USER_LOGIN});
    // console.log('getSiteList',payload);
    const responseObject = yield call(getDashboardGraphAPI,payload);   
    // console.log('responseObject',responseObject);
    // console.log('payload',payload); 
    // var resObject = {...responseObject,...payload};
    console.log('getLPCDGraphData-resObject',responseObject); 
    if(responseObject.status==="Get LPCD Graph Data Successfully")
      yield put({type: types.GET_DASHBOARD_LPCD_GRAPH_REQUEST_SUCCESS, payload: responseObject});
    else
      yield put({type: types.GET_DASHBOARD_LPCD_GRAPH_REQUEST_FAILURE, payload: responseObject});
  } catch (err) {
    // console.log('responseObject',err);
    yield put({type: types.GET_DASHBOARD_LPCD_GRAPH_REQUEST_FAILURE, payload: err});
    // console.log(err);
  }
}
function* getLFHTCHomeGraphData({payload, error}) {
  try {
    // yield put({type: types.SEND_REQUEST_USER_LOGIN});
    // console.log('getSiteList',payload);
    const responseObject = yield call(getDashboardGraphAPI,payload);   
    // console.log('responseObject',responseObject);
    // console.log('payload',payload); 
    // var resObject = {...responseObject,...payload};
    //console.log('getLPCDGraphData-resObject',resObject); 
    if(responseObject.status==="Get FHTC Graph Data Successfully")
      yield put({type: types.GET_DASHBOARD_FHTC_GRAPH_REQUEST_SUCCESS, payload: responseObject});
    else
      yield put({type: types.GET_DASHBOARD_FHTC_GRAPH_REQUEST_FAILURE, payload: responseObject});
  } catch (err) {
    // console.log('responseObject',err);
    yield put({type: types.GET_DASHBOARD_FHTC_GRAPH_REQUEST_FAILURE, payload: err});
    // console.log(err);
  }
}
function* getWATSUPHomeGraphData({payload, error}) {
  try {
    // yield put({type: types.SEND_REQUEST_USER_LOGIN});
    // console.log('getSiteList',payload);
    const responseObject = yield call(getDashboardGraphAPI,payload);   
    // console.log('responseObject',responseObject);
    // console.log('payload',payload); 
    // var resObject = {...responseObject,...payload};
    //console.log('getLPCDGraphData-resObject',resObject); 
    if(responseObject.status==="Get Water Supplied Graph Data Successfully")
      yield put({type: types.GET_DASHBOARD_WATSUP_GRAPH_REQUEST_SUCCESS, payload: responseObject});
    else
      yield put({type: types.GET_DASHBOARD_WATSUP_GRAPH_REQUEST_FAILURE, payload: responseObject});
  } catch (err) {
    // console.log('responseObject',err);
    yield put({type: types.GET_DASHBOARD_WATSUP_GRAPH_REQUEST_FAILURE, payload: err});
    // console.log(err);
  }
}
export default function* siteSaga() {
  yield all([
    yield takeLatest(types.GET_SITELIST_REQUEST_APPSETTINGS, getSiteList),
    yield takeLatest(types.GET_SITEDETAILS_REQUEST_APPSETTINGS, getSiteDetails),
    yield takeLatest(types.GET_PROJECTDETAILS_REQUEST_APPSETTINGS, getProjectDetails), 
    yield takeLatest(types.GET_MQTTDATA_REQUEST, getMqttData),
    yield takeLatest(types.PUSH_MQTTDATA_REQUEST, pushMqttData),
    yield takeLatest(types.GET_SITELIST_BY_PROJECT, getSiteListByProjectId),
    yield takeLatest(types.REPORT_REQUEST, getReportData),
    yield takeLatest(types.REPORT_HOURLY_REQUEST, getReportHourData),
    yield takeLatest(types.GET_DASHBOARD_LPCD_GRAPH_REQUEST, getLPCDHomeGraphData),
    yield takeLatest(types.GET_DASHBOARD_FHTC_GRAPH_REQUEST, getLFHTCHomeGraphData),
    yield takeLatest(types.GET_DASHBOARD_WATSUP_GRAPH_REQUEST, getWATSUPHomeGraphData),
    yield takeLatest(types.CLEAR_SITE_DATA, clearSiteData), 
    // LOAD_LOGGEDIN_USER(),
  ])
}
