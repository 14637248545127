// import { createSlice } from "@reduxjs/toolkit";
import {types} from '../actions/types';

const initialState = {
  loading: false,
  status:null,
  data:null,
  message:null,
  action:null,
};

const reportReducer=(state = initialState, {type, payload})=>{
  switch (type) {
    case types.GET_ASSET_REPORT_REQUEST:
      // console.log('LOGIN_REQUEST_APPSETTINGS');
      return {
        ...state,
        loading: true,
        // isLogin:false,
        action:types.GET_ASSET_REPORT_REQUEST
      };
    case types.GET_ASSET_REPORT_SUCCESS:
      // console.log('LOGIN_REQUEST_APPSETTINGS_SUCCESS');
      // console.log('LOGIN_REQUEST_APPSETTINGS_SUCCESS-payload',payload);
      return {
        ...state,
        status: payload.status?payload.status:null,
        data: payload.data?payload.data:null,
        message: payload.message?payload.message:null,
        loading: false,
        action:types.GET_ASSET_REPORT_SUCCESS
      };
    case types.GET_ASSET_REPORT_FAILURE:
      // console.log('LOGIN_REQUEST_APPSETTINGS_FAILURE');
      // console.log('LOGIN_REQUEST_APPSETTINGS_FAILURE-payload',payload);
      return {
        ...state,
        status: payload.status?payload.status:null,
        data: payload.data?payload.data:null,
        message: payload.message?payload.message:null,    
        token:null,    
        loading: false,
        action:types.GET_ASSET_REPORT_FAILURE
      };
    case types.GET_SITE_PARAMETER_REPORT_REQUEST:
      // console.log('LOGIN_REQUEST_APPSETTINGS');
      return {
        ...state,
        loading: true,
        // isLogin:false,
        action:types.GET_SITE_PARAMETER_REPORT_REQUEST
      };
    case types.GET_SITE_PARAMETER_REPORT_SUCCESS:
      // console.log('LOGIN_REQUEST_APPSETTINGS_SUCCESS');
      // console.log('LOGIN_REQUEST_APPSETTINGS_SUCCESS-payload',payload);
      return {
        ...state,
        status: payload.status?payload.status:null,
        data: payload.data?payload.data:null,
        message: payload.message?payload.message:null,
        loading: false,
        action:types.GET_SITE_PARAMETER_REPORT_SUCCESS
      };
    case types.GET_SITE_PARAMETER_REPORT_FAILURE:
      // console.log('LOGIN_REQUEST_APPSETTINGS_FAILURE');
      // console.log('LOGIN_REQUEST_APPSETTINGS_FAILURE-payload',payload);
      return {
        ...state,
        status: payload.status?payload.status:null,
        data: payload.data?payload.data:null,
        message: payload.message?payload.message:null,    
        token:null,    
        loading: false,
        action:types.GET_SITE_PARAMETER_REPORT_FAILURE
      };
    case types.GET_PUMP_ACTIVITY_REQUEST:
      // console.log('LOGIN_REQUEST_APPSETTINGS');
      return {
        ...state,
        loading: true,
        // isLogin:false,
        action:types.GET_PUMP_ACTIVITY_REQUEST
      };
    case types.GET_PUMP_ACTIVITY_SUCCESS:
      // console.log('LOGIN_REQUEST_APPSETTINGS_SUCCESS');
      // console.log('LOGIN_REQUEST_APPSETTINGS_SUCCESS-payload',payload);
      return {
        ...state,
        status: payload.status?payload.status:null,
        data: payload.data?payload.data:null,
        message: payload.message?payload.message:null,
        loading: false,
        action:types.GET_PUMP_ACTIVITY_SUCCESS
      };
    case types.GET_PUMP_ACTIVITY_FAILURE:
      // console.log('LOGIN_REQUEST_APPSETTINGS_FAILURE');
      // console.log('GET_PUMP_ACTIVITY_FAILURE-payload',payload);
      return {
        ...state,
        status: payload.data.status?payload.data.status:null,
        data: payload.data?payload.data:null,
        message: payload.data.message?payload.data.message:null,    
        token:null,    
        loading: false,
        action:types.GET_PUMP_ACTIVITY_FAILURE
      };
    default:
      return state;
  }
}

export default reportReducer;