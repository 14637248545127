import React, { useEffect, useState } from 'react';
import iconMap from "../../assets/images/icon-map.png";
import PropTypes from 'prop-types';
import LineChartWS from "../Graph/LineChartWS";
import {getWSGraphData} from '../../redux/actions/graphAction'
import {useDispatch, useSelector} from 'react-redux';
import {types} from '../../redux/actions/types';
import { useToast } from '@chakra-ui/react';

const CardWatersupplied = ({ title, graphFor, siteId, type }) => {
  const dispatch = useDispatch();
  const graphReducer = useSelector(state => state.graphReducer);
  const [graphData, setGraphData] = useState([]);
  let timeOutGraphs=null;
  const toast = useToast()
 
  useEffect(()=> {
    fetchWSGraphData()
  }, [siteId])

  useEffect(()=>{ 
    if(graphReducer.action){
      if(graphReducer.action===types.GET_GRAPH_WS_REQUEST_SUCCESS){
        // console.log('CardWatersupplied-graphReducer-useEffect',graphReducer)        
        if(graphReducer.status==="success"){
          if(graphReducer.data){
            setGraphData(graphReducer.data);
          }
        }else {
          toast({
        title: graphReducer.message,
        status: 'warning',
        duration: 2000,
        isClosable: true,
        position: "top-right",
        positionOptions: {
          right: "0",
          top: "0",
        },
      });
        }
      }
      else if(graphReducer.action===types.GET_NEWS_REQUEST_FAILURE){
        toast({
        title: graphReducer.message,
        status: 'warning',
        duration: 2000,
        isClosable: true,
        position: "top-right",
        positionOptions: {
          right: "0",
          top: "0",
        },
      });
      }
    }
  },[graphReducer.action])
  const fetchWSGraphData=()=>{
    const request={siteId,graphFor}
    dispatch(getWSGraphData(request));   
  } 
  
  return (
    <div className="card">
      <div className="card-header">
        <h4 className="card-header-title mb-2 mb-sm-0">
          <i className="icon-map">
            <img src={iconMap} alt="map icon" />
          </i>{" "}
          {title}
        </h4>
      </div>
      <div className="card-body"><LineChartWS dataList={graphData} yLabel={'Supplied Water (Ltr)'} xLabel={'Date'} /></div>
    </div>
  );
};

CardWatersupplied.propTypes = {
  title: PropTypes.string,
  type: PropTypes.string,
  graphFor: PropTypes.string,
  siteId: PropTypes.string,
  // content: PropTypes.object,
};
export default CardWatersupplied;
