import React from 'react';

const NotFound = () => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
      }}
    >
      <div style={{ textAlign: 'center' }}>
        <h1 style={{ fontSize: '48px', marginBottom: '20px' }}>404 Not Found</h1>
        <p style={{ fontSize: '18px', marginBottom: '20px' }}>The page you requested could not be found.</p>
        <p style={{ fontSize: '16px', marginBottom: '10px' }}>Please check the URL for spelling or capitalization errors.</p>
      </div>
    </div>
  );
};

export default NotFound;
