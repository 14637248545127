import {put, call, takeEvery, all, takeLatest} from 'redux-saga/effects';
import {types} from '../actions/types';
import {getGraphAPI} from '../../store/apis';

function* getLPCDGraphData({payload, error}) {
  try {
    // yield put({type: types.SEND_REQUEST_USER_LOGIN});
    // console.log('getSiteList',payload);
    const responseObject = yield call(getGraphAPI,payload);   
    // console.log('responseObject',responseObject);
    // console.log('payload',payload); 
    var resObject = {...responseObject,...payload};
    //console.log('getLPCDGraphData-resObject',resObject); 
    if(responseObject.status=="success")
      yield put({type: types.GET_GRAPH_LPCD_REQUEST_SUCCESS, payload: resObject});
    else
      yield put({type: types.GET_GRAPH_LPCD_REQUEST_FAILURE, payload: resObject});
  } catch (err) {
    // console.log('responseObject',err);
    yield put({type: types.GET_GRAPH_LPCD_REQUEST_FAILURE, payload: err});
    // console.log(err);
  }
}
function* getChlorineGraphData({payload, error}) {
  try {
    // yield put({type: types.SEND_REQUEST_USER_LOGIN});
    // console.log('getSiteList',payload);
    const responseObject = yield call(getGraphAPI,payload);   
    // console.log('responseObject',responseObject);
    // console.log('payload',payload); 
    var resObject = {...responseObject,...payload};
    //console.log('getChlorineGraphData-resObject',resObject); 
    if(responseObject.status=="success")
      yield put({type: types.GET_GRAPH_CHLORINE_REQUEST_REQUEST_SUCCESS, payload: resObject});
    else
      yield put({type: types.GET_GRAPH_CHLORINE_REQUEST_REQUEST_FAILURE, payload: resObject});
  } catch (err) {
    // console.log('responseObject',err);
    yield put({type: types.GET_GRAPH_CHLORINE_REQUEST_REQUEST_FAILURE, payload: err});
    // console.log(err);
  }
}
function* getPressureGraphData({payload, error}) {
  try {
    // yield put({type: types.SEND_REQUEST_USER_LOGIN});
    // console.log('getSiteList',payload);
    const responseObject = yield call(getGraphAPI,payload);   
    // console.log('responseObject',responseObject);
    // console.log('payload',payload); 
    var resObject = {...responseObject,...payload};
    //console.log('getChlorineGraphData-resObject',resObject); 
    if(responseObject.status=="success")
      yield put({type: types.GET_GRAPH_PRESSURE_REQUEST_SUCCESS, payload: resObject});
    else
      yield put({type: types.GET_GRAPH_PRESSURE_REQUEST_FAILURE, payload: resObject});
  } catch (err) {
    // console.log('responseObject',err);
    yield put({type: types.GET_GRAPH_PRESSURE_REQUEST_FAILURE, payload: err});
    // console.log(err);
  }
}
function* getWSGraphData({payload, error}) {
  try {
    // yield put({type: types.SEND_REQUEST_USER_LOGIN});
    // console.log('getSiteList',payload);
    const responseObject = yield call(getGraphAPI,payload);   
    // console.log('responseObject',responseObject);
    // console.log('payload',payload); 
    var resObject = {...responseObject,...payload};
    // console.log('getChlorineGraphData-resObject',resObject); 
    if(responseObject.status=="success")
      yield put({type: types.GET_GRAPH_WS_REQUEST_SUCCESS, payload: resObject});
    else
      yield put({type: types.GET_GRAPH_WS_REQUEST_FAILURE, payload: resObject});
  } catch (err) {
    // console.log('responseObject',err);
    yield put({type: types.GET_GRAPH_WS_REQUEST_FAILURE, payload: err});
    // console.log(err);
  }
}
function* getTurbidityGraphData({payload, error}) {
  try {
    // yield put({type: types.SEND_REQUEST_USER_LOGIN});
    // console.log('getSiteList',payload);
    const responseObject = yield call(getGraphAPI,payload);   
    // console.log('responseObject',responseObject);
    // console.log('payload',payload); 
    var resObject = {...responseObject,...payload};
    // console.log('getChlorineGraphData-resObject',resObject); 
    if(responseObject.status=="success")
      yield put({type: types.GET_GRAPH_TURBIDITY_REQUEST_SUCCESS, payload: resObject});
    else
      yield put({type: types.GET_GRAPH_TURBIDITY_REQUEST_FAILURE, payload: resObject});
  } catch (err) {
    // console.log('responseObject',err);
    yield put({type: types.GET_GRAPH_TURBIDITY_REQUEST_FAILURE, payload: err});
    // console.log(err);
  }
}
function* getOHRSGraphData({payload, error}) {
  try {
    // yield put({type: types.SEND_REQUEST_USER_LOGIN});
    // console.log('getSiteList',payload);
    const responseObject = yield call(getGraphAPI,payload);   
    // console.log('responseObject',responseObject);
    // console.log('payload',payload); 
    var resObject = {...responseObject,...payload};
    // console.log('getChlorineGraphData-resObject',resObject); 
    if(responseObject.status=="success")
      yield put({type: types.GET_GRAPH_OHRS_REQUEST_SUCCESS, payload: resObject});
    else
      yield put({type: types.GET_GRAPH_OHRS_REQUEST_FAILURE, payload: resObject});
  } catch (err) {
    // console.log('responseObject',err);
    yield put({type: types.GET_GRAPH_OHRS_REQUEST_FAILURE, payload: err});
    // console.log(err);
  }
}
export default function* graphSaga() {
  yield all([
    yield takeLatest(types.GET_GRAPH_LPCD_REQUEST, getLPCDGraphData),
    yield takeLatest(types.GET_GRAPH_CHLORINE_REQUEST_REQUEST, getChlorineGraphData),
    yield takeLatest(types.GET_GRAPH_PRESSURE_REQUEST, getPressureGraphData),
    yield takeLatest(types.GET_GRAPH_WS_REQUEST, getWSGraphData), 
    yield takeLatest(types.GET_GRAPH_TURBIDITY_REQUEST, getTurbidityGraphData),
    yield takeLatest(types.GET_GRAPH_OHRS_REQUEST, getOHRSGraphData),    
    // LOAD_LOGGEDIN_USER(),
  ])
}
