import React, { useEffect, useState } from 'react';
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
// import errorIcon from "../../assets/images/error-icon.png";
// import pumpTypeOneImage from "../../assets/images/pupm-type1.svg";
// import pumpTypeTwoImage from "../../assets/images/pupm-type2.svg";

const SiteList = ({data,page,totalRecords,pageSize,handleSitePagination}) => {
  const navigate = useNavigate();
  const [paginationLbl, setPaginationLbl] = useState("");  
  const [totalPages, setTotalPages] = useState(1);
  const [curTime, setCurTime] = useState('');
  const [totalOnPumps, setTotalOnPumps] = useState(0);
  const [totalOffPumps, setTotalOffPumps] = useState(0);
  // console.log('SiteList-data',data);  
  useEffect(()=>{
    console.log('SiteList-useEffect-data',data);
    const onPumpCount = data.filter((item)=>item.liveDataLogsFeedBack?item.liveDataLogsFeedBack.F7==1:null);
    setTotalOnPumps(onPumpCount.length);
    setTotalOffPumps(data.length-onPumpCount.length);
    // console.log('SiteList-useEffect-onPumpCount',onPumpCount);
    // const offPumpCount = data.filter((item)=>item.liveDataLogsFeedBack.F7==1);
    // console.log('ProjectDashboard-useEffect-location.state.projectId',location.state.projectId);    
    Pagination();
    setInterval(() => {
      setCurTime(new Date().toLocaleString());
      // this.setState({
      //   curTime : new Date().toLocaleString()
      // })
    }, 1000)
  },[data])
  const siteDetails=(item)=>{
    navigate('/site-dashboard',{state: {
      siteId: item._id,
      siteName: item.pumpName,
    }});
  }
  const bindPrevNavigation=()=>{
    if(page<2)
      return <li class="page-item disabled">
              <a class="page-link">Prev</a>
            </li>
    else
      return <li class="page-item">
              <a class="page-link" onClick={()=>handlePagination('P')}>Prev</a>
            </li>
  }
  const bindNextNavigation=()=>{
    const totalPages=Math.ceil(totalRecords/pageSize);
    if(totalPages>page)
      return <li class="page-item">
              <a class="page-link" onClick={()=>handlePagination('N')}>Next</a>
            </li>
    else
      return <li class="page-item disabled">
              <a class="page-link">Next</a>
            </li>
  }
  const Pagination=()=>{
    const totalPages=Math.ceil(totalRecords/pageSize);
    setTotalPages(totalPages);    
    const firstRecord=((page-1)*pageSize)+1;
    const lastRecord=page*pageSize;
    setPaginationLbl("Showing "+firstRecord+" to "+(lastRecord<=totalRecords?lastRecord:totalRecords)+" of "+totalRecords+" entries")
  }
  const handlePagination=(pageEvent)=>{
    if(pageEvent=="N"){
      if(totalPages>=page){
        handleSitePagination(pageEvent);
      }
    }else if(pageEvent=="P"){
      if(page>1){
        handleSitePagination(pageEvent);
      }
    }      
  }
  const pumpStatus=(value)=>{
    let status="Off";
    if(value==0){
      status="Off";
    }else if(value==1){
      status="On";
    }else if(value==2){
      status="In Process";
    }else if(value==3){
      status="Error";
    }
    return status;
  }
  const outLetValveStatus=(value)=>{
    let status="Close";
    if(value==0){
      status="Close";
    }else if(value==1){
      status="Open";
    }else if(value==2){
      status="Close";
    }else if(value==3){
      status="Close";
    }
    return status;
  }
  return (
    <div className="card">
      <div className="card-header">
        <div className="card-header-top">
          <h4 className="card-header-title"></h4>
          <ul className="pump-detial">
            <li>
              Total Pumps <span className="bg-blue">{totalRecords}</span>
            </li>
            <li>
              Total ON Pumps <span className="bg-green">{totalOnPumps}</span>
            </li>
            <li>
              Total Off Pumps <span className="bg-red">{totalOffPumps}</span>
            </li>
          </ul>
          <span className="time-log d-flex align-items-center">
            <i class="icon-time flex-shrink-0"></i>{curTime}
          </span>
        </div>
        <div className="card-header-bottom">
          <div className="entry-limit">
            {/* Show
            <input
              type="number"
              class="form-control"
              name=""
              id=""
              placeholder="10"
            />
            entries */}
          </div>
          <div className="search-box">
            Search:
            <input
              type="text"
              class="form-control"
              name=""
              id=""
              placeholder=""
            />
          </div>
        </div>
      </div>
      <div className="card-body">
        <div class="table-responsive">
          <table class="table table-striped">
            <thead>
              <tr>
                <th scope="col">Scheme Name</th>
                <th scope="col">Pumps Status</th>
                <th scope="col">OUTLET VALVE</th>
                <th scope="col">Scheme Type</th>
                <th scope="col">Cumulative<br />Energy<br />(KWH)</th>
                <th scope="col">Cumulative<br /> Water<br />(KL)</th>
                <th scope="col">Water<br />source</th>
                <th scope="col">Today's Production<br />(L)</th>
                <th scope="col">LPCD<br />(population average)</th>
                <th scope="col">FHTC<br />(No. of connections)</th>
                <th scope="col">Level<br />(percent)</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              {data?data.map((item,index)=>
              {
                if(item){
                  return(<tr key={index}>
                    <td><a onClick={()=>siteDetails(item)} className="text-blue">{item.pumpName}</a></td>
                    <td>{item.liveDataLogsFeedBack?pumpStatus(item.liveDataLogsFeedBack.F7):"-N/A-"}</td>
                    <td>{item.liveDataLogsFeedBack?outLetValveStatus(item.liveDataLogsFeedBack.F4):"-N/A-"}</td>
                    <td>{item.pumpType?item.pumpType:""}</td>
                    <td>{item.kwhDiff?(item.kwhDiff/10).toFixed(2):"0"}</td>
                    <td>{item.liveMasterDataParameters?(item.liveMasterDataParameters.P13?(item.liveMasterDataParameters.P13/1000).toFixed(2):''):"-N/A-"}</td>
                    <td>{item.sourceType?item.sourceType:""}</td>
                    <td>{item.todayProduction?item.todayProduction:""}</td>
                    <td>{item.LPCD?item.LPCD.toFixed(2):0}</td>
                    <td>{item.FHTC?item.FHTC:""}</td>
                    <td>{item.liveMasterDataParameters?(item.liveMasterDataParameters.P2?item.liveMasterDataParameters.P2.toFixed(2)+"%":"0%"):"-N/A-"}</td>
                    <td></td>
                  </tr>)
                }
              })
              :null}
            </tbody>
          </table>
          <div className="d-flex justify-content-between align-items-center py-2 px-4">
            <div className="total-entry">{paginationLbl}</div>
            <nav aria-label="Page navigation">
              <ul class="pagination justify-content-end">
                {bindPrevNavigation()}
                {bindNextNavigation()}
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
};
SiteList.propTypes = {
  data: PropTypes.array,
  page: PropTypes.number,
  totalRecords: PropTypes.number,
  pageSize: PropTypes.number,
  handleSitePagination:PropTypes.func,
};
SiteList.defaultProp = {
  data: [],
  page: 1,
  totalRecords: 0,
  pageSize: 20,
};
export default SiteList;
