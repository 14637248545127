import {put, call, takeLatest, all} from 'redux-saga/effects';
import {types} from '../actions/types';
import {basicInfoAPI} from '../../store/apis';

function* getBasicInfo({payload, error}) {
  try {
    // yield put({type: types.SEND_REQUEST_USER_LOGIN});
    // console.log('getSiteList',payload);
    const basicInfoObject = yield call(basicInfoAPI,payload);   
    // console.log('basicInfoObject-getBasicInfo-',basicInfoObject); 
    if(basicInfoObject.status=="success")
      yield put({type: types.GET_BASIC_INFO_REQUEST_SUCCESS, payload: basicInfoObject});
    else
      yield put({type: types.GET_BASIC_INFO_REQUEST_FAILURE, payload: basicInfoObject});
  } catch (err) {
    // console.log('appSettings',err);
    yield put({type: types.GET_BASIC_INFO_REQUEST_FAILURE, payload: err});
    // console.log(err);
  }
}
export default function* basicInfoSaga() {
  yield all([
    yield takeLatest(types.GET_BASIC_INFO_REQUEST, getBasicInfo),
    // LOAD_LOGGEDIN_USER(),
  ])
}
