import React from "react";
import "../styles/spinner.css";
import LoadingSpinner from "./LoadingSpinner";

const ButtonWithSpinner=(props)=>{
  // console.log('isLoading',props.isLoading);
  // console.log('onClick',props.onClick);
  // console.log('label',props.label);
  return (
    <button type="button" className="btn btn-primary btn-lg" disabled={props.isLoading} onClick={props.onClick}>{props.isLoading?<LoadingSpinner />:props.label}</button>
  );
}
export default ButtonWithSpinner;