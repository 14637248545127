import {types} from './types';

export function getSiteList(payload) {
  return {
    type: types.GET_SITELIST_REQUEST_APPSETTINGS,
    payload,
  };
}

export function getSiteDetails(payload) {
  return {
    type: types.GET_SITEDETAILS_REQUEST_APPSETTINGS,
    payload,
  };
}

export function getProjectDetails(payload) {
  return {
    type: types.GET_PROJECTDETAILS_REQUEST_APPSETTINGS,
    payload,
  };
}

export function getMqttData(payload) {
  return {
    type: types.GET_MQTTDATA_REQUEST,
    payload,
  };
}

export function pushMqttData(payload) {
  return {
    type: types.PUSH_MQTTDATA_REQUEST,
    payload,
  };
}

export function getSiteListByProject(payload) {
  return {
    type: types.GET_SITELIST_BY_PROJECT,
    payload,
  };
}

export function getReport(payload) {
  return {
    type: types.REPORT_REQUEST,
    payload,
  };
}

export function getHourlyReport(payload) {
  return {
    type: types.REPORT_HOURLY_REQUEST,
    payload,
  };
}

export function getHomeLPCDGraph(payload) {
  return {
    type: types.GET_DASHBOARD_LPCD_GRAPH_REQUEST,
    payload,
  };
}

export function getHomeFHPCGraph(payload) {
  return {
    type: types.GET_DASHBOARD_FHTC_GRAPH_REQUEST,
    payload,
  };
}

export function getHomeWatSup(payload) {
  return {
    type: types.GET_DASHBOARD_WATSUP_GRAPH_REQUEST,
    payload,
  };
}

export function clearSiteData() {
  return {
    type: types.CLEAR_SITE_DATA,
    payload:null,
  };
}