/*
    Check If value is empty or not...
*/

export function isValidPhone(item) {
    return item.length == 10 || item.length == 13;
}
export function isEmpty(isFieldEmpty) {  
    console.log("PIKAA INN")
    // console.log('isFieldEmpty',(isFieldEmpty === ""));
    // console.log('isFieldEmpty',isFieldEmpty);
    // console.log('isFieldEmpty.startsWith',isFieldEmpty.toString().startsWith(" "));
    if(isFieldEmpty==undefined)
        return true
    else if(isFieldEmpty==null)
        return true
    else if (isFieldEmpty === "")
        return true;
    else if (isFieldEmpty.toString().startsWith(" "))
        return true;
    else
        return false;
}

/*
    Check password validation...
*/
export function isValidPassword(value) {
    console.log('value',value);
    let passwordRegex = /^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[^\w\d\s:])([^\s]){8,16}$/;

    if (passwordRegex.test(value) === false) {
        return false;
    }
    else {
        return true;
    }
}

/*
    Check If email address is valid or not...
*/
export function isValidEmailAddress(isvalidEmail) {
    let emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    if (emailRegex.test(isvalidEmail) === false) {
        return false;
    }
    else {
        return true;
    }
}

export function isEmptyObj(obj) {
    for(var key in obj) {
        if(obj.hasOwnProperty(key))
            return false;
    }
    return true;
}


/*
    Remove html tag from description
*/
export function removeHTML(value) {
    let removeHtmlRegex = /(<([^>]+)>)/ig;
    return value.replace(removeHtmlRegex, '');    
}