import React, { useEffect, useState } from 'react';
import PropTypes from "prop-types";
import {MdLabelImportant} from 'react-icons/md'
import {Box} from '@chakra-ui/react'


const SensorValue = ({data}) => {

  const getIf = (img) => {
    if(img == 'Fuel Level of DG')
      return  "%"
    return  ""
  }
  // console.log('data',data);
  if(data.length>0){
    return (    
      <div className="row">
        <div className="col mb-4">
          <div className="card">
            <div className="card-body p-0">
              <div className="row py-3 py-md-0">
                  <div className="col-12 col-md-12 col-xl-12">
                    <div className="row">
                      {data.map((item,index)=>(<div key={index} className="col-12 col-md-6 col-xl-2">
                        <div className="box-content border-start">
                          {/* <h3 className="box-title text-black"><img src={item.imgIc} className="icon-image" alt={item.value} />{item.label}</h3> */}
                          {console.log("CHECKING EACH", item.imgIc, item)}
                          <h4 class="card-header-title mb-2 mb-sm-0" style={{height: '65%'}}><i class="icon-map"><img src={item.imgIc} alt={item.value} style={{height: '40px', width: '40px'}}/></i>{item.label}</h4>
                          <p className="site-section-name " style={{height: "35%", color: '#3182CE'}}>{item.value +"" + getIf(item.label)}</p>
                        </div>
                      </div>))}                        
                    </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }  

};
SensorValue.propTypes = {
    data: PropTypes.array,
};
SensorValue.defaultProp = {
    data: [],
};
export default SensorValue;
