import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { doLogin } from '../redux/actions/userAction'
import { types } from '../redux/actions/types';
import mainLogo from "../assets/images/aqualogix-logo-b.png";
import harGharJalLogo from "../assets/images/hargharjal-logo-b.png";
import ButtonWithSpinner from "../Components/ButtonWithSpinner";
import { isEmpty, isLoggedInUser, CONSTANTVALUE, storeData, getData } from '../utils'
import { useToast } from '@chakra-ui/react';

const LogIn = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [isRemember, setIsRemember] = useState(false);
  // const [isLoading, setIsLoading] = useState(false);
  const toast = useToast()
  const userReducer = useSelector(state => state.userReducer);
  useEffect(() => {
    // setIsLoading(true);
    // console.log('LogIn-useEffect');    
    chkLogin();
  }, [])
  useEffect(() => {
    // console.log('LogIn-userReducer-useEffect',userReducer);    
    if (userReducer.action) {
      if (userReducer.action === types.LOGIN_REQUEST_APPSETTINGS_SUCCESS) {
        console.log('LoginScreen-isRemember', isRemember)
        if (userReducer.status === "success") {
          if (isRemember) {
            storeData(CONSTANTVALUE.USERNAME, userName);
            storeData(CONSTANTVALUE.PASSWORD, password);
          }
          navigate('/dashboard');
        } else {
              toast({
            title: userReducer.message,
            status: 'warning',
            duration: 2000,
            isClosable: true,
            position: "top-right",
            positionOptions: {
              right: "0",
              top: "0",
            },
          })
          console.log("Errorececewc", userReducer.message)
        }
      } else if (userReducer.action === types.LOGIN_REQUEST_APPSETTINGS_FAILURE) {
        // toast({
        //   title: userReducer.message,
        //   status: 'info',
        //   duration: 2000,
        //   isClosable: true,
        //   position: "top-right",
        //   positionOptions: {
        //     right: "0",
        //     top: "0",
        //   },
        // })
        console.log("Erroreeeeee",userReducer.message)
      }
    }
  }, [userReducer.action])

  const chkLogin = async () => {
    try {
      const username = await getData(CONSTANTVALUE.USERNAME);
      const password = await getData(CONSTANTVALUE.PASSWORD);

      setUserName(username);
      setPassword(password);

      const isLoggedIn = await isLoggedInUser();

      if (isLoggedIn) {
        navigate('/dashboard');
      }
    } catch (error) {
      console.error("Error in chkLogin:", error);
    }
  }

  const doSignin = () => {
    // console.log('userName',userName);
    // console.log('password',password);
    if (isEmpty(userName)) {
      toast({
        title: 'Please enter mobile',
        status: 'warning',
        duration: 2000,
        isClosable: true,
        position: "top-right",
        positionOptions: {
          right: "0",
          top: "0",
        },
      })
      // alert(string.strFNameValMsg);
      return;
    } else if (isEmpty(password)) {
      toast({
        title: 'Please enter password',
        status: 'warning',
        duration: 2000,
        isClosable: true,
        position: "top-right",
        positionOptions: {
          right: "0",
          top: "0",
        },
      })
      // alert(string.strLNameValMsg);
      return;
    }
    const loginRequest = { "mobile": userName, "password": password };
    // console.log('loginRequest',loginRequest);
    dispatch(doLogin(loginRequest));
    // navigate('/dashboard');
  }
  const handleUserInput = (e) => {
    // console.log('e.target.name',e.target.name);
    // console.log('e.target.value',e.target.value);
    if (e.target.name === "mobile") {
      setUserName(e.target.value);
    }
    if (e.target.name === "password") {
      setPassword(e.target.value);
    }
  }
  const handleChange = (e) => {
    if (e.target.checked)
      setIsRemember(true);
    else
      setIsRemember(false);
  }
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      doSignin();
    }
  };

  return (
    <section>
      <div className="container h-100">
        <div className="row d-flex justify-content-center align-items-center h-100">
          <div className="col-md-8 col-lg-8 col-xl-6 mt-5">
            <div className="card text-black" style={{ borderRadius: "25px" }}>
              <div className="card-body p-md-5">
                <div className="row justify-content-center">
                  <div className="col-12 order-2 order-lg-1">
                    <div className="col-md-12 col-lg-6 col-xl-7 d-flex justify-content-center w-100 mb-5">
                      <img src={mainLogo} className="img-fluid align-items-center w-75" alt="Sample image" />
                    </div>
                    {/* <p className="text-center h1 fw-bold mb-4 mx-1 mx-md-4 mt-3">Sign In</p> */}
                    <form className="mx-1 mx-md-2">
                      <div className="d-flex flex-row align-items-center mb-2">
                        {/* <i className="fas fa-envelope fa-lg me-3 fa-fw"></i> */}
                        <div className="form-outline flex-fill mb-0">
                          <label className="form-label" for="form3Example3c">Mobile Number</label>
                          <input type="mobile" name="mobile" id="mobile" value={userName} onChange={(event) => handleUserInput(event)} className="form-control" />
                        </div>
                      </div>

                      <div className="d-flex flex-row align-items-center mb-2">
                        {/* <i className="fas fa-lock fa-lg me-3 fa-fw"></i> */}
                        <div className="form-outline flex-fill mb-0">
                          <label className="form-label" for="form3Example4c">Password</label>
                          <input type="password" name="password" id="password" value={password} onChange={(event) => handleUserInput(event)} className="form-control" onKeyDown={handleKeyDown} />
                        </div>
                      </div>

                      <div className="form-check d-flex justify-content-center mb-4">
                        <input className="form-check-input me-2" type="checkbox" value="" id="chkRemMe" onChange={handleChange} />
                        <label className="form-check-label" for="form2Example3" id="chkRemMe">Remember me</label>
                      </div>
                      <div className="d-flex justify-content-center mx-2 mb-2 mb-lg-2">
                        {/* <button type="button" className="btn btn-primary btn-lg" disabled={isLoading} onClick={doSignin}>{userReducer.loading?<LoadingSpinner />:"Submit"}</button> */}
                        <ButtonWithSpinner onClick={doSignin} isLoading={userReducer.loading ? true : false} label={'Submit'} />
                      </div>
                    </form>
                    {/* <p className="forgot-password d-flex justify-content-center">
                      <a href="#">Forgot password?</a>
                    </p> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-10 col-lg-6 col-xl-7 d-flex justify-content-center w-100 mt-5 mb-5">
              <img src={harGharJalLogo} className="img-fluid w-75" alt="Sample image" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LogIn;
